import hostArr from "@/proxy";
import qs from "qs";

const mode: string = process.env.REACT_APP_ENV || "prod";

// 获取当前 URL
const currentUrl = window.location.search;

// 使用 qs 解析查询参数
const queryParameters = qs.parse(currentUrl.split("?")[1]);

let baseWsURL: any = hostArr[mode].ws;

if (!!queryParameters.test_ws) {
  baseWsURL = queryParameters.test_ws;
}

export default baseWsURL;
