import { useState } from "react";
import { Modal, Button, Form, Select, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { publishStyleList } from "../../publishStyleList";
import Styles from "./index.module.scss";

type IDialog = {
  isPublishDialog: boolean;
  setPublishDialog: (isPublishDialog: boolean) => void;
};

const { Option } = Select;
const PublishDialog = ({ isPublishDialog, setPublishDialog }: IDialog) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setPublishDialog(false);
  };

  const changeDialogData = (type: string, value: any) => {};

  const publishSubmit = () => {};

  return (
    <Modal
      title={t("publish.dialog.title")}
      open={isPublishDialog}
      onCancel={handleCancel}
      footer={
        <Button
          onClick={() => {
            publishSubmit();
          }}
          type="primary"
        >
          {t("common.submit")}
        </Button>
      }
    >
      <Form
        name="publish"
        className={Styles.form}
        initialValues={{ remember: true }}
      >
        <Form.Item
          label={t("publish.dialog.style")}
          name="tag"
          className={Styles.form_item}
          rules={[
            { required: true, message: t("publish.dialog.stylePlaceHolder") },
          ]}
        >
          <Select
            placeholder={t("publish.dialog.stylePlaceHolder")}
            mode="multiple"
            showSearch
          >
            {publishStyleList.map((item: any, index: number) => {
              return (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {/* <Form.Item
          label={t("publish.dialog.permission")}
          name="username"
          className={Styles.form_item}
        >
          <Radio.Group
            onChange={(value) => {
              changeDialogData("permission", value);
            }}
            defaultValue={1}
          >
            <Radio value={1}>{t("publish.dialog.toPlace")}</Radio>
            <Radio value={2}>{t("publish.dialog.toDashBoard")}</Radio>
          </Radio.Group>
        </Form.Item> */}

        <Form.Item
          label={t("publish.dialog.edit.permission")}
          name="open"
          className={Styles.form_item}
        >
          <Radio.Group
            onChange={(value) => {
              changeDialogData("permission", value);
            }}
            defaultValue={1}
          >
            <Radio value={1}>{t("publish.dialog.viewOnly")}</Radio>
            <Radio value={2}>{t("publish.dialog.editView")}</Radio>
          </Radio.Group>
        </Form.Item>

        <div>{t("publish.dialog.Agreement")}</div>
      </Form>
    </Modal>
  );
};

export default PublishDialog;
