import React from "react";
import useThemeHooks from "@/hooks/useThemeHooks";
const Save = ({ isSaveHover }: any) => {
  const theme = useThemeHooks();
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Paper">
        <path
          id="Paper_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.4382 12.6587H9.92665C10.2649 12.6587 10.5455 12.3753 10.5455 12.0337C10.5455 11.692 10.2649 11.417 9.92665 11.417H5.4382C5.09992 11.417 4.81939 11.692 4.81939 12.0337C4.81939 12.3753 5.09992 12.6587 5.4382 12.6587ZM8.22693 7.25H5.43815C5.09986 7.25 4.81934 7.53333 4.81934 7.875C4.81934 8.21667 5.09986 8.49167 5.43815 8.49167H8.22693C8.56521 8.49167 8.84574 8.21667 8.84574 7.875C8.84574 7.53333 8.56521 7.25 8.22693 7.25ZM14.1151 6.52167C14.3091 6.51943 14.5203 6.51699 14.7121 6.51699C14.9184 6.51699 15.0834 6.68366 15.0834 6.89199V13.592C15.0834 15.6587 13.425 17.3337 11.3788 17.3337H4.81114C2.66592 17.3337 0.916748 15.5753 0.916748 13.4087V4.42533C0.916748 2.35866 2.58341 0.666992 4.63787 0.666992H9.04381C9.25833 0.666992 9.42335 0.841992 9.42335 1.05033V3.73366C9.42335 5.25866 10.6692 6.50866 12.1791 6.51699C12.5318 6.51699 12.8427 6.51963 13.1148 6.52194C13.3265 6.52373 13.5147 6.52533 13.6808 6.52533C13.7982 6.52533 13.9505 6.52357 14.1151 6.52167ZM14.3427 5.30505C13.6645 5.30755 12.865 5.30505 12.2899 5.29921C11.3774 5.29921 10.6257 4.54005 10.6257 3.61838V1.42171C10.6257 1.06255 11.0573 0.884214 11.304 1.14338C11.7505 1.61231 12.3641 2.2569 12.9749 2.89854C13.5842 3.53855 14.1906 4.17563 14.6257 4.63255C14.8667 4.88505 14.6901 5.30421 14.3427 5.30505Z"
          fill={
            isSaveHover ? "#29CC6A" : theme === "dark" ? "#F7F7F7" : "#272B30"
          }
        />
      </g>
    </svg>
  );
};

export default Save;
