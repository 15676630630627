import React from "react";
import useThemeHooks from "@/hooks/useThemeHooks";
const Rollback = ({ isRollBackHover }: any) => {
  const theme = useThemeHooks();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M1.27783 6.14572L6.23874 1.72754V10.743L1.27783 6.14572ZM6.30965 17.1821C5.68707 17.1821 5.18238 16.6774 5.18238 16.0548C5.18238 15.4322 5.68707 14.9275 6.30965 14.9275H12.5042C14.6451 14.9275 16.3797 13.1266 16.3797 10.9039C16.3797 8.68118 14.6451 6.87936 12.5042 6.87936H4.68874V4.62481H12.5042C15.8433 4.62572 18.5506 7.43663 18.5506 10.9048C18.5506 14.3712 15.8433 17.1821 12.5042 17.1821H6.30965Z"
        fill={
          isRollBackHover ? "#29CC6A" : theme === "dark" ? "#F7F7F7" : "#272B30"
        }
      />
    </svg>
  );
};

export default Rollback;
