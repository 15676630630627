import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form, message } from "antd";
import Styles from "./index.module.scss";

import { useSelector } from "react-redux";
import { loginOption } from "@/store";
import store from "@/store";
import { Trans, useTranslation } from "react-i18next";
import {
  toCheckBindCode,
  toGetBindingCode,
  toUsersIslogin,
} from "@/apis/common";
import { updateUserInfo } from "@/store/reducer/userInfo";
import Cookies from "js-cookie";
import { createImageUrl } from "@/utils/common";

const LoadingIcon = createImageUrl("common/loading.png");
const bgPng = createImageUrl("loginBg.png");

const BindPhoneModal = (props: any) => {
  const { t } = useTranslation();

  const [showCode, setShowCode] = useState(false);
  const [phone, setPhone] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [pageLoading, setPageLoading] = useState(false);
  const hostname = window.location.hostname;

  function getTopLevelAndSecondLevelDomain(hostname: any) {
    // 分割主机名
    const parts = hostname.split(".");
    // 确保至少有三部分（例如 sub.example.com）
    if (parts.length > 2) {
      // 提取最后两部分作为顶级域名和二级域名
      return parts.slice(-2).join(".");
    }
    // 如果主机名部分不足，返回原始主机名
    return hostname;
  }
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  const setCodeBtnTime = () => {
    setIsDisabled(true);
    setSecondsLeft(60);

    const intervalId = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        const newSeconds = prevSeconds - 1;
        if (newSeconds === 0) {
          clearInterval(intervalId);
          setIsDisabled(false);
        }
        return newSeconds;
      });
    }, 1000);
  };

  const modalVisible =
    useSelector((state: any) => state?.loginConfig?.bindPhoneModalVisible) ||
    false;

  const hideModal = () => {
    setPageLoading(false);
    store.dispatch(loginOption.closeBindPhoneModal());
  };

  const handleCodeClick = (type: string, destination: string) => {
    if (!destination || !/^1[3456789]\d{9}$/.test(destination)) {
      message.error(t("请输入正确的手机号码"));
      return;
    }

    setPageLoading(true);
    setShowCode(true);
    toGetBindingCode({
      destination,
      code_type: type,
      login_type: "weixin",
      third_login_unique_id: props.third_login_unique_id,
    }).then((res: any) => {
      setPageLoading(false);
      if (res?.data?.status === 200) {
        message.success(t("login.getCodeSuccess"));
        setCodeBtnTime();
      } else {
        message.error(res?.data?.message || t("获取验证码失败，请稍后重试"));
      }
    });
  };

  const getUserInfo = () => {
    message.success("绑定成功！");
    toUsersIslogin().then((res: any) => {
      store.dispatch({
        ...updateUserInfo(res.data.data),
        isUserInfoLoading: false,
        token: localStorage.getItem("token"),
      });

      Cookies.set("token", localStorage.getItem("token") as string, {
        domain: `${topLevelAndSecondLevelDomain}`,
      });
      hideModal();

      const newURL = window.location.pathname;
      window.history.replaceState({}, "", newURL);
      sendRefreshSignal();
    });
  };

  const onFinish = (data: any) => {
    if (!data?.code || !data?.phone) {
      return;
    }

    setPageLoading(true);
    toCheckBindCode({
      code: data?.code,
      login_type: "weixin",
      destination: data?.phone,
      third_login_unique_id: props.third_login_unique_id,
    }).then((res: any) => {
      setPageLoading(false);
      if (res?.status === 200) {
        localStorage.setItem("token", res?.data?.data?.access);
        localStorage.removeItem("need_binding");
        localStorage.removeItem("third_login_unique_id");

        setTimeout(() => {
          getUserInfo();
        }, 0);
      } else {
        message.error(res?.response?.message || "绑定失败，请稍后重试");
      }
    });
  };
  // 发送信号到其他标签页或窗口
  const sendRefreshSignal = () => {
    localStorage.setItem("refreshSignal", Date.now().toString());
  };
  const onFinishFailed = () => {};

  return (
    <Modal
      title={null}
      open={modalVisible}
      className={Styles.modal}
      onCancel={() => {
        localStorage.removeItem("need_binding");
        localStorage.removeItem("third_login_unique_id");
        hideModal();
      }}
      centered
      footer={null}
      width={636}
      height={520}
    >
      <div className={Styles.content}>
        <img src={bgPng} width={"100%"} />
        <div className={Styles.infoWrap}>
          <Form
            name="loginFrom"
            className={Styles.form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className={Styles.title}>绑定手机号</div>
            <Form.Item
              label={null}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("login.inputPhone"),
                  pattern: /^1[3456789]\d{9}$/, // 目前只支持中国大陆手机号
                },
              ]}
              className={Styles.form_item}
            >
              <Input
                className={Styles.input}
                onChange={(e: any) => {
                  setPhone(e?.target?.value);
                }}
                placeholder={t("login.inputPhone")}
              />
            </Form.Item>

            <div
              style={{
                display: "flex",
                gap: 16,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 24,
                position: "relative",
              }}
            >
              <Form.Item
                label={null}
                name="code"
                rules={[{ required: showCode, message: t("login.inputCode") }]}
                className={Styles.form_item}
              >
                <Input
                  className={Styles.input}
                  placeholder={t("login.inputCode")}
                />
              </Form.Item>
              <Button
                type="text"
                disabled={isDisabled}
                onClick={() => handleCodeClick("sms", phone)}
                className={Styles.getCodeBtn}
              >
                <div>
                  {pageLoading ? (
                    <div className={Styles.gettingCodeWrap}>
                      <img src={LoadingIcon} className={Styles.icon} />
                      {t("获取中")}
                    </div>
                  ) : isDisabled ? (
                    <div className={Styles.gettingCodeWrap}>
                      <Trans
                        i18nKey="login.secondsLeft"
                        values={{ second: String(secondsLeft) }}
                      >
                        {String(secondsLeft)}
                      </Trans>
                    </div>
                  ) : (
                    t("login.getCode")
                  )}
                </div>
              </Button>
            </div>

            <Button
              type="primary"
              htmlType="submit"
              className={Styles.continue}
            >
              <div className={Styles.goLogin}>{t("确定")}</div>
            </Button>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default BindPhoneModal;
