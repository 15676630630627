import hostArr from "@/proxy";
import qs from "qs";

const mode: string = process.env.REACT_APP_ENV || "prod";

// 获取当前 URL
const currentUrl = window.location.search;

// 使用 qs 解析查询参数
const queryParameters = qs.parse(currentUrl.split("?")[1]);

let baseURL: any = hostArr[mode].blog_http;

if (!!queryParameters.test_blog_http) {
  baseURL = queryParameters.test_blog_http;
}

export default baseURL;
