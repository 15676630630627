import {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.scss";
import ProfessionMode from "./Setting/ProfessionalMode";
import NormalMode from "./Setting/NormalMode";
import { IAgentInfo } from "@/types/common";
import { toCreateAgent, toUpdateAgent } from "@/apis/common";
import { useSearchParams } from "react-router-dom";
import { setBrowserUrl } from "@/utils/url";
import QuestionPic from "@/assets/common/question.svg";
import _ from "lodash";
import classnames from "classnames";
import CardMode from "./CardSetting";

type Iprops = {
  createInfo: IAgentInfo;
  setCreateInfo: any;
  createId: any;
  changeCreateInfo: any;
  setLastUpdateTime: any;
  setSaveStatus: any;
  saveStatus: any;
  hasInit?: boolean;
  createInfoRef?: any;
};

const Create = forwardRef(
  (
    {
      createInfo,
      setCreateInfo,
      createId,
      changeCreateInfo,
      setLastUpdateTime,
      setSaveStatus,
      saveStatus,
      hasInit,
      createInfoRef,
    }: Iprops,
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const [params] = useSearchParams();
    let mode = params.getAll("mode")[0];
    const [preCreateInfo, setPreCreateInfo] = useState<IAgentInfo>();

    const [activeTab, setActiveTab] = useState(createInfo?.edit_mode || 1);

    useEffect(() => {
      setActiveTab(createInfo?.edit_mode || 1);
    }, [createInfo]);

    // 修改Agent工具
    const switchSkills = (type: string, value: boolean) => {
      let newCreateInfo: any = _.cloneDeep(createInfo);
      const isInclude: boolean =
        newCreateInfo.flow_config.skills.includes(type);
      if (value) {
        if (!isInclude) {
          newCreateInfo.flow_config.skills.push(type);
        }
      } else {
        if (isInclude) {
          newCreateInfo.flow_config.skills.splice(
            newCreateInfo.flow_config.skills.indexOf(type),
            1
          );
        }
      }
      changeCreateInfo("flow_config.skills", newCreateInfo.flow_config.skills);
    };

    useImperativeHandle(ref, () => ({
      validateAndUpdate,
    }));

    // useEffect(() => {
    //   let Id = Number(params.getAll("id")[0]);

    //   if (!createId.current && !Id && hasInit) {
    //     createAgent();
    //   }
    // }, [hasInit]);

    const NormalModeRef = useRef<any>(null);
    const ProfessionModeRef = useRef<any>(null);
    const CardModeRef = useRef<any>(null);

    const validateAndUpdate = (callBack?: any, error?: boolean) => {
      let validateStatus = false;

      if (mode === "card") {
        validateStatus = CardModeRef.current.validateFn((res: any) => {
          if (!res|| error) return;
          doCreateOrUpdate(callBack);
        }, createInfoRef.current);
      } else if (activeTab === 1) {
        validateStatus = NormalModeRef.current.validateFn((res: any) => {
          if (!res|| error) return;
          doCreateOrUpdate(callBack);
        }, createInfoRef.current);
      } else if (activeTab === 2) {
        validateStatus = ProfessionModeRef.current.validateFn((res: any) => {
          if (!res|| error) return;
          doCreateOrUpdate(callBack);
        }, createInfoRef.current);
      }
    };

    const doCreateOrUpdate = (callBack?: any) => {
      if (!hasInit) return;
      if (!!createId.current) {
        updateAgent(true, callBack);
      } else {
        createAgent(callBack);
      }
    };

    // 创建 Agent
    const createAgent = (callBack: any) => {
      setCreateInfo((createInfo: IAgentInfo) => {
        setSaveStatus("Loading");
        toCreateAgent(createInfo).then((res: any) => {
          if (res.status === 200) {
            if (res.data.data && res.data.data.id) {
              createId.current = res.data.data.id;
              setBrowserUrl({
                id: res.data.data.id,
              });
              setSaveStatus("Saved");
              setLastUpdateTime(new Date());
              callBack && callBack(true);
            }
          } else {
            callBack && callBack(false);
          }
        });

        setPreCreateInfo(createInfo);
        return createInfo;
      });
    };

    //更新 Agent 信息
    const updateAgent = (isUpdateNow?: boolean, callBack?: any) => {
      const updateAgentAction = (createInfo: any, preCreateInfo: any) => {
        setSaveStatus("Loading");
        toUpdateAgent({ ...createInfo, id: createId.current }).then(
          (res: any) => {
            if (res.status === 200) {
              setSaveStatus("Saved");
              setLastUpdateTime(new Date());
              callBack && callBack(true);
            } else {
              setSaveStatus("UnSaved");
              callBack && callBack(false);
            }
          }
        );
      };
      if (saveStatus !== "Loading") {
        if (isUpdateNow) {
          updateAgentAction(createInfo, preCreateInfo);
          return;
        }

        setCreateInfo((createInfo: IAgentInfo) => {
          setPreCreateInfo((preCreateInfo?: IAgentInfo) => {
            if (isUpdateNow || !_.isEqual(preCreateInfo, createInfo)) {
              updateAgentAction(createInfo, preCreateInfo);
            }
            return createInfo;
          });
          return createInfo;
        });
      }
    };

    const getContext = () => {
      let ContextDom: any = <></>;

      tabItems.forEach((item, index) => {
        if (item.key === activeTab) {
          ContextDom = item.children;
        }
      });

      return ContextDom;
    };

    // tab信息
    const tabItems = [
      {
        key: 1,
        label: t("create.normalmode"),
        children: (
          <NormalMode
            createInfo={createInfo}
            changeCreateInfo={changeCreateInfo}
            switchSkills={switchSkills}
            updateAgent={updateAgent}
            createId={createId}
            saveStatus={saveStatus}
            ref={NormalModeRef}
          />
        ),
        icon: <AndroidOutlined />,
        iconText: t("create.normalDesc"),
      },
      {
        key: 2,
        label: t("create.professionalmode"),
        children: (
          <ProfessionMode
            createInfo={createInfo}
            changeCreateInfo={changeCreateInfo}
            switchSkills={switchSkills}
            updateAgent={updateAgent}
            createId={createId}
            saveStatus={saveStatus}
            ref={ProfessionModeRef}
          />
        ),
        icon: <AppleOutlined />,
        iconText: t("create.professionalDesc"),
      },
    ];

    return (
      <div className={Styles.page}>
        <div
          className={
            mode === "card" ? Styles.card_create_setting : Styles.create_setting
          }
        >
          {mode === "card" || createInfo.show_mode === 2 ? (
            <CardMode
              createInfo={createInfo}
              changeCreateInfo={changeCreateInfo}
              switchSkills={switchSkills}
              updateAgent={updateAgent}
              createId={createId}
              saveStatus={saveStatus}
              ref={CardModeRef}
            />
          ) : (
            <>
              <div className={Styles.create_tab}>
                {tabItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={classnames(
                        Styles.create_item,
                        item.key === activeTab ? Styles.create_item_active : ""
                      )}
                      onClick={() => {
                        changeCreateInfo("edit_mode", item.key);
                        setActiveTab(item.key);
                      }}
                    >
                      {item.label}
                      <Tooltip title={item.iconText}>
                        <img
                          src={QuestionPic}
                          className={Styles.question}
                          loading="lazy"
                        ></img>
                      </Tooltip>
                    </div>
                  );
                })}
                <div
                  className={classnames(
                    Styles.create_tab_flow,
                    activeTab === 1
                      ? Styles.create_tab_flow_left
                      : Styles.create_tab_flow_right
                  )}
                ></div>
              </div>
              <div className={Styles.create_context}>{getContext()}</div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default Create;
