import React from "react";
import useThemeHooks from "@/hooks/useThemeHooks";
const CopyIcon = ({ isCopyIconHover }: any) => {
  const theme = useThemeHooks();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect
        x="5"
        y="1"
        width="9"
        height="11"
        rx="1"
        stroke={
          theme === "dark"
            ? isCopyIconHover
              ? "#e8e8e6"
              : "#a7a7a7"
            : isCopyIconHover
            ? "#272B30"
            : "#80868d"
        }
        strokeWidth={"1.3"}
      />
      <path
        d="M2 4V14C2 14.5523 2.44772 15 3 15H10"
        stroke={
          theme === "dark"
            ? isCopyIconHover
              ? "#e8e8e6"
              : "#a7a7a7"
            : isCopyIconHover
            ? "#272B30"
            : "#80868d"
        }
        strokeWidth={"1.3"}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CopyIcon;
