import Styles from "../index.module.scss";
import { getImageUrl } from "@/utils/common";
import { Link } from "react-router-dom";
import eyePic from "@/assets/community/eyedeepgray.svg";
import starPic from "@/assets/community/stardeepgray.svg";
import thunderPic from "@/assets/community/thunder.svg";
import { useTranslation } from "react-i18next";
type ISpaceItemData = {
  spaceItemData: {
    avatar_link: string; // 社区图标
    cover_link: string; // 封面图片
    description: string; // 社区描述
    history_count: number; // 历史访问人数
    link: string; // 社区外链地址
    member_count: number; //成员人数
    modify_timestamp: number; //时间戳
    online_count: number; //在线人数
    title: string; // 社区名称
    [property: string]: any;
  };
};

const SpaceItem = ({ spaceItemData }: ISpaceItemData) => {
  const { t } = useTranslation();
  return (
    <Link to={spaceItemData.link} target="_blank">
      <div className={Styles.space_item_context}>
        <div className={Styles.image_box}>
          <div className={Styles.space_item_padding}></div>
          <img src={getImageUrl(spaceItemData?.cover_link)} loading="lazy" />
          {/* <div className={Styles.member_box}>
            <img src={thunderPic}></img>
            <div>{spaceItemData.online_count}</div> {t("community.online")}
          </div> */}
        </div>
        <div className={Styles.space_item_info}>
          <img
            className={Styles.avator_img}
            src={getImageUrl(spaceItemData?.avatar_link)}
            loading="lazy"
          ></img>
          <div>
            <div className={Styles.space_item_info_name}>
              @{spaceItemData.title}
            </div>
            <div className={Styles.space_item_info_content}>
              {spaceItemData.description}
            </div>
            {/* <div className={Styles.space_item_info_count}>
              <div className={Styles.space_item_info_count_item}>
                <img src={eyePic}></img>
                <span>{spaceItemData.history_count}</span>{" "}
                <span>{t("community.cameby")}</span>
              </div>
              <div className={Styles.space_item_info_count_item}>
                <img src={starPic}></img>
                <span>{spaceItemData.member_count}</span>{" "}
                <span>{t("community.member")}</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SpaceItem;
