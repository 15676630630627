import { Link } from "react-router-dom";
import search from "@/assets/special/b_search_line.svg";
import searchActive from "@/assets/special/b_searchactive_fill.svg";
import chat from "@/assets/menu/chat.svg";
import chatLight from "@/assets/menu/chat.svg";
import chatActive from "@/assets/menu/chatActive.svg";
import community from "@/assets/menu/community.svg";
import communityLight from "@/assets/menu/community-light.svg";
import communityActive from "@/assets/menu/communityActive.svg";
import assistant from "@/assets/special/b_assistant_line.svg";
import assistantActive from "@/assets/special/b_assistantactive_fill.svg";
import card from "@/assets/menu/card.svg";
import cardLight from "@/assets/menu/card-light.svg";
import cardActive from "@/assets/menu/cardActive.svg";
import home from "@/assets/menu/home.svg";
import special from "@/assets/menu/special.svg";
import specialActive from "@/assets/menu/specialActive.svg";
import specialLight from "@/assets/menu/special-light.svg";
import homeActive from "@/assets/menu/homeActive.svg";
import myAgent from "@/assets/special/b_special_line.svg";
import myAgentActive from "@/assets/special/b_specialactive_fill.svg";
import knowledge from "@/assets/special/b_knowledge_line.svg";
import knowledgeActive from "@/assets/special/b_knowledgeactive_fill.svg";
import aiTeamLight from "@/assets/menu/ai-team.svg";
import aiTeamActive from "@/assets/menu/aiteamActive.svg";
import enterpriseActive from "@/assets/special/b_enterpriseactive_fill.svg";
import enterprise from "@/assets/special/b_enterprise_line.svg";
import book from "@/assets/special/b_book_line.svg";
import bookActive from "@/assets/special/b_bookactive_fill.svg";
import i18n from "@/translation/i18n";
import explore from "@/assets/menu/explore.svg";
import exploreLight from "@/assets/menu/explore-light.svg";
import exploreActive from "@/assets/menu/exploreActive.svg";
import superAssistant from "@/assets/special/b_agent_line.svg";
import superAssistantActive from "@/assets/special/b_agentactive_fill.svg";

import aiMarketing from "@/assets/menu/aiMarketing.svg";
import aiMarketingActive from "@/assets/menu/aiMarketingActive.svg";
import longTextAnalysis from "@/assets/menu/longTextAnalysis.svg";
import longTextAnalysisActive from "@/assets/menu/longTextAnalysisActive.svg";
import dataAnalysis from "@/assets/menu/dataAnalysis.svg";
import dataAnalysisActive from "@/assets/menu/dataAnalysisActive.svg";

const mode: string = process.env.REACT_APP_ENV || "prod";

const isMobile = window?.innerWidth <= 600;

const sidebar =
  mode === "b_prod" || mode === "b_test"
    ? isMobile
      ? [
          {
            key: "main",
            type: "list",
            label: "",
            children: [
              {
                key: "superAssistant",
                link: "/",
                label: "超级助理",
                icon: superAssistant,
                iconLight: superAssistant,
                activeIcon: superAssistantActive,
                desc: "专注于打造意图识别精准、内容产出高质量的个人超级助理，打造丰富的skill配置和针对用户高频需求和高价值的workflow。超级助理自组织工作流和自反馈机制，数据分析、AI实时数据获取、内容创作、文件解读等高频功能，实现工作学习的高效率。",
              },
            ],
          },
          {
            key: "ai_search",
            link: "/ai_search",
            label: "AI搜索",
            icon: search,
            iconLight: search,
            activeIcon: searchActive,
            desc: "",
          },
        ]
      : [
          {
            key: "main",
            type: "list",
            label: "",
            children: [
              {
                key: "superAssistant",
                link: "/",
                label: "超级助理",
                icon: superAssistant,
                iconLight: superAssistant,
                activeIcon: superAssistantActive,
                desc: "专注于打造意图识别精准、内容产出高质量的个人超级助理，打造丰富的skill配置和针对用户高频需求和高价值的workflow。超级助理自组织工作流和自反馈机制，数据分析、AI实时数据获取、内容创作、文件解读等高频功能，实现工作学习的高效率。",
              },
            ],
          },
          {
            key: "workspace",
            type: "list",
            label: "工作区",
            children: [
              {
                key: "enterpriseZone",
                link: "/enterpriseZone",
                label: i18n.t("common.enterprise.zone"),
                icon: enterprise,
                iconLight: enterprise,
                activeIcon: enterpriseActive,
                desc: "企业专区是智体企业版平台特有以AI助理和AI知识为内容的团队协作共创的功能，在企业专区享受定制功能服务和定向的知识及内容咨询推送；另外您可以在企业专区享受团队共建，您的团队人员创建的AI助理和AI知识分享后可以在这里团队所有成员共用。",
              },
              {
                key: "ai_search",
                link: "/ai_search",
                label: "AI搜索",
                icon: search,
                iconLight: search,
                activeIcon: searchActive,
                desc: "",
              },
              // {
              //   key: "aiteam",
              //   link: "/aiteam",
              //   label: "我的AI团队",
              //   iconLight: aiTeamLight,
              //   icon: aiTeamLight,
              //   activeIcon: aiTeamActive,
              // },
              // {
              //   key: "ai_marketing",
              //   link: "/ai_marketing",
              //   label: "AI营销图",
              //   icon: aiMarketing,
              //   iconLight: aiMarketing,
              //   activeIcon: aiMarketingActive,
              //   disabled: true,
              // },
              // {
              //   key: "long_text_analysis",
              //   link: "/long_text_analysis",
              //   label: "长文本分析",
              //   icon: longTextAnalysis,
              //   iconLight: longTextAnalysis,
              //   activeIcon: longTextAnalysisActive,
              //   disabled: true,
              // },
              // {
              //   key: "data_analysis",
              //   link: "/data_analysis",
              //   label: "数据分析",
              //   icon: dataAnalysis,
              //   iconLight: dataAnalysis,
              //   activeIcon: dataAnalysisActive,
              //   disabled: true,
              // },
            ],
          },
          {
            key: "explore",
            type: "list",
            label: "探索",
            children: [
              {
                key: "aitool",
                link: "/aitool",
                label: i18n.t("common.ai.assistant"),
                icon: assistant,
                iconLight: assistant,
                activeIcon: assistantActive,
                desc: "AI助理中心为您提供各种特殊场景的专业智能生产力工具，您可以使用我们精选的AI助理；同时也可以针对自己的需求快速创建独属于自己或团队的AI助理。",
              },
              {
                key: "aicard",
                link: "/aicard",
                label: i18n.t("common.ai.knowledge"),
                icon: knowledge,
                iconLight: knowledge,
                activeIcon: knowledgeActive,
                desc: "AI知识是一个系统化学习新技术和提升自我的板块，行业丰富经验的讲师作为领路人帮助您在AI的浪潮中快速吸收新的知识，掌握新的技能。提供多样的课程内容呈现，并且结合AI问答和丰富的内容产出形式达到个性化学习效率翻倍。",
              },
            ],
          },
        ]
    : [
        {
          key: "special",
          link: "/",
          label: i18n.t("common.special"),
          icon: special,
          iconLight: specialLight,
          activeIcon: specialActive,
        },
        {
          key: "aicard",
          link: "/aicard",
          label: i18n.t("common.aicard"),
          icon: card,
          iconLight: cardLight,
          activeIcon: cardActive,
        },
        {
          key: "advanced",
          link: "/advanced",
          label: i18n.t("进阶学习"),
          icon: special,
          iconLight: specialLight,
          activeIcon: specialActive,
        },
        {
          key: "ai_search",
          link: "/ai_search",
          label: "AI搜索",
          icon: search,
          iconLight: search,
          activeIcon: searchActive,
        },
        {
          key: "aitool",
          link: "/aitool",
          label: i18n.t("common.seeking"),
          icon: explore,
          iconLight: exploreLight,
          activeIcon: exploreActive,
        },
        {
          key: "community",
          link: "/community",
          label: i18n.t("common.community"),
          icon: community,
          iconLight: communityLight,
          activeIcon: communityActive,
        },
        {
          key: "chat",
          link: "/chat",
          label: i18n.t("common.chat"),
          icon: chat,
          iconLight: chatLight,
          activeIcon: chatActive,
        },
      ];

const userSiderBar = isMobile
  ? []
  : [
      {
        key: "knowledge",
        link: "/knowledge",
        label: "知识库",
        iconLight: book,
        icon: book,
        activeIcon: bookActive,
      },
      {
        key: "myAgent",
        link: "/my_agent",
        label: i18n.t("common.myAgent"),
        iconLight: myAgent,
        icon: myAgent,
        activeIcon: myAgentActive,
      },
    ];

export { sidebar, userSiderBar };
