import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import Styles from "./index.module.scss";
import classnames from "classnames";
import KnowledgeList from "./component/KnowledgeList";
import FolderList from "./component/FolderList";
import { ListType } from "./component/FolderList/type";

interface KnowledgeProps {
  type: ListType;
  onFileSelect?: (files: Array<any>) => void;
  deleteIdList?: number[];
  changeType?: (type: string) => void;
  pageType?: string;
  selectedFilter?: string;
}

// 知识库列表中间层
const KnowledgeContent = forwardRef(
  (
    {
      type,
      onFileSelect,
      deleteIdList,
      changeType,
      selectedFilter,
      pageType,
    }: KnowledgeProps,
    ref
  ) => {
    const [showFolders, setShowFolders] = useState(true);
    const [selectedFolderId, setSelectedFolderId] = useState(0);
    // 用来调用文件夹列表里的弹窗方法
    const folderListRef = useRef<any>(null);

    // 点击文件夹
    const handleFolderClick = (folderId: number) => {
      setSelectedFolderId(folderId);
      setShowFolders(false);
    };

    // 返回文件夹列表
    const handleBackClick = () => {
      setShowFolders(true);
      setSelectedFolderId(0);
    };

    // 选中文件
    const handleFileSelect = (files: Array<any>) => {
      onFileSelect && onFileSelect([...files]);
    };

    // 拼接tab和tag数据
    const requiredData = useMemo(
      () => ({
        tag: selectedFilter,
        pub_scope: pageType === "company" ? 2 : 1,
      }),
      [selectedFilter, pageType]
    );

    // 执行回调
    useEffect(() => {
      const type = showFolders ? "folder" : "knowLedge";
      changeType?.(type);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFolders]);

    useImperativeHandle(ref, () => ({
      handleAddFolderClick: folderListRef.current?.handleAddFolderClick,
      setShowFolders,
      showFolders: () => {
        return showFolders;
      },
    }));

    return (
      <div className={classnames(Styles.page)}>
        {showFolders && (
          <FolderList
            onFolderClick={handleFolderClick}
            type={type}
            ref={folderListRef}
            requiredData={requiredData}
          />
        )}
        {!showFolders && selectedFolderId && (
          <KnowledgeList
            deleteIdList={deleteIdList}
            folderId={selectedFolderId}
            onBackClick={handleBackClick}
            type={type}
            handleFileSelect={handleFileSelect}
            requiredData={requiredData}
          />
        )}
      </div>
    );
  }
);

export default KnowledgeContent;
