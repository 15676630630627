import React, { useEffect, useMemo, useRef, useState } from "react";
import KnowledgeIcon from "@/assets/special/b_book_line.svg";
import { createImageUrl, isMobile } from "@/utils/common";
import Styles from "./input.module.scss";
import classnames from "classnames";
import { Button, Input, message, Tooltip } from "antd";
import { customFileUpload } from "@/utils/upload";
import FileUpload from "../../../FileUpload";
import SendLightDisabledPic from "@/assets/chat/send-light-disabled.svg";
import SendLightPic from "@/assets/chat/send-light.svg";
import RelateKnowledgeBaseModal from "@/components/relateKnowledgeBaseModal";
import UploadFilesList from "@/components/uploadFilesList";
import Workflow from "@/components/ChatWorkflow";

const InputComponent = ({
  chatWorkflowSend,
  onChat,
  setKnowledgeList,
  knowledgeList,
  isOnBottom,
  goChatBottom,
  isLoading,
  onStopChatClick,
  curSuperType,
}: any) => {
  const [superType, setSuperType] = useState(
    curSuperType || localStorage.getItem("superType")
  );
  const [bigAgentList, setBigAgentList] = useState([
    {
      show_name: "关联知识库",
      icon: KnowledgeIcon,
    },
  ]);
  const [chatMsg, setChatMsg] = useState("");
  const [isShowWork, setIsShowWork] = useState(false);

  const [pastedFiles, setPastedFiles] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [fileUploadKey, setFileUploadKey] = useState(0);
  const [deleteFileList, setDeleteFileList] = useState<any>([]);

  const relateKnowledgeBaseModalRef = useRef<any>();

  const chatFiles = useMemo(() => {
    return [...fileList, ...pastedFiles].filter((file: any) => {
      return !deleteFileList.some((item: any) => item.uid === file.uid);
    });
  }, [fileList, pastedFiles, deleteFileList]);

  const clearWoke = () => {
    setIsShowWork(false);
  };

  const sendMsg = () => {
    onChat(chatMsg, chatFiles);
    setChatMsg("");
    setFileList([]);
    setPastedFiles([]);
    setFileUploadKey(fileUploadKey + 1);
  };
  const getFilesList = (fileArr: any) => {
    setFileList(fileArr);
  };
  const handleLinkClick = () => {
    relateKnowledgeBaseModalRef?.current?.show();
  };

  useEffect(() => {
    setSuperType(curSuperType);
  }, [curSuperType]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div className={`${Styles.chat_super_assistant_wrap}`}>
        <div
          className={Styles.chat_to_bottom}
          style={{ visibility: isOnBottom ? "hidden" : "visible" }}
        >
          <img
            className={Styles.chat_insert_send}
            src={SendLightDisabledPic}
            onClick={() => {
              goChatBottom();
            }}
            loading="lazy"
          ></img>
        </div>
        <div
          className={classnames(
            Styles.chat_input_box_wrap,
            isMobile ? Styles.mobileChatInput : ""
          )}
          style={isMobile ? { overflow: "unset" } : {}}
        >
          <div className={Styles.chat_input_active}>
            <Input.TextArea
              // 粘贴图片上传
              onPaste={(event: any) => {
                const items = event.clipboardData.items;
                for (let i = 0; i < items.length; i++) {
                  if (items[i].kind === "file") {
                    const file = items[i].getAsFile();
                    if (file && file.type.startsWith("image/")) {
                      customFileUpload({
                        file,
                        onSuccess: (data: any) => {
                          const isLt100M = file.size / 1024 / 1024 < 100;
                          const currentFile = {
                            name: file.name,
                            uid: data.data.file_url,
                            url: data.data.file_url,
                            size: file.size,
                            response: data,
                            status: isLt100M ? "done" : "error",
                          };

                          setPastedFiles([...pastedFiles, currentFile]);
                          message.success("图片上传成功");
                        },
                        onError: (error: any) => {
                          message.error("图片上传失败，请稍后重试");
                        },
                      });
                    }
                  }
                }
              }}
              className={Styles.chat_insert_input}
              value={chatMsg}
              autoSize={{ minRows: 2, maxRows: 5 }}
              onChange={(e) => {
                setChatMsg(e.target.value);
              }}
              style={{ background: "transparent" }}
              placeholder={"询问您的助理一切问题"}
              onPressEnter={(e) => {
                if (e.which === 13 || e.keyCode === 13) {
                  if (e.shiftKey) {
                    return;
                  }
                  if (!isLoading) {
                    sendMsg();
                  }
                  e.preventDefault();
                }
              }}
            ></Input.TextArea>
            <div className={Styles.chat_insert_tool}>
              <div className={Styles.chat_insert_left}>
                {bigAgentList.map((item: any, index) => {
                  return (
                    <Button
                      key={index}
                      className={Styles.agentBtn}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <div className={Styles.agentBtnContent}>
                        <img src={item.icon} className={Styles.agentIcon} />
                        {item.show_name}
                        {knowledgeList.length > 0 ? (
                          <span className={Styles.agentBtnNumbers}>
                            {knowledgeList.length}
                          </span>
                        ) : null}
                      </div>
                    </Button>
                  );
                })}
              </div>

              <div className={Styles.chat_insert_right}>
                {superType !== "llm_chat" ? null : (
                  <div
                    className={classnames(
                      Styles.chat_workflow,
                      "iconfont",
                      "icon-size24",
                      "icon-classify_line"
                    )}
                    onClick={(e) => {
                      setIsShowWork(true);
                      e.preventDefault();
                    }}
                  ></div>
                )}
                <div className={Styles.chat_upload}>
                  <FileUpload
                    isMultiple={true}
                    onUploadSuccess={() => {}}
                    getFilesList={getFilesList}
                    isCustom
                    folderPath={"chat"}
                    keys={fileUploadKey}
                    maxSize={20}
                  >
                    <Tooltip
                      title={
                        "支持上传文件(最多20个，每个20MB)支持png、jpg、pdf、doc、docx、xlsx、ppt、txt。"
                      }
                    >
                      <div
                        className={classnames(
                          Styles.chat_upload_d,
                          "iconfont",
                          "icon-size24",
                          "icon-a-paperclip_line"
                        )}
                        style={{ cursor: "pointer" }}
                      ></div>
                    </Tooltip>
                  </FileUpload>
                </div>
                <div
                  className={Styles.chat_insert_send}
                  style={{
                    background:
                      !chatMsg && !isLoading
                        ? "rgba(27, 33, 43, 0.10)"
                        : "rgba(29, 33, 42)",
                    cursor: chatMsg || isLoading ? "pointer" : "",
                  }}
                >
                  <i
                    className={classnames(
                      "iconfont",
                      "icon-size24",
                      isLoading ? "icon-suspend_full" : "icon-upward_line"
                    )}
                    onClick={(e) => {
                      if (!isLoading) {
                        sendMsg();
                      } else {
                        onStopChatClick && onStopChatClick();
                      }
                      e.preventDefault();
                    }}
                    style={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {chatFiles && chatFiles.length > 0 && (
        <div className={Styles.filesList}>
          <UploadFilesList
            isMobileChat={isMobile}
            fileList={chatFiles}
            onDelete={(index: number) => {
              setDeleteFileList([...deleteFileList, chatFiles[index]]);
            }}
          />
        </div>
      )}
      {isShowWork ? (
        <Workflow
          isShowWork={isShowWork}
          clearWoke={clearWoke}
          onSend={chatWorkflowSend}
        />
      ) : null}
      <RelateKnowledgeBaseModal
        ref={relateKnowledgeBaseModalRef}
        setCurrentFolderList={(list) => {
          setKnowledgeList(list);
        }}
        curFolderList={knowledgeList}
      ></RelateKnowledgeBaseModal>
    </div>
  );
};

export default InputComponent;
