import React, { useEffect, useRef, memo, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import style from "./Content.module.scss";
import { useTranslation } from "react-i18next";
import AnswerIconDark from "./answer-dark.svg";
import AnswerIconLight from "./answer-light.svg";
import SourceIconDark from "./source-dark.svg";
import SourceIconLight from "./source-light.svg";
import RelatedIconDark from "./relate-dark.svg";
import RelatedIconLight from "./relate-light.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import { message as AntdMessage, Collapse, Tooltip } from "antd";
import rehypeRaw from "rehype-raw";
import { visit } from "unist-util-visit";
import gfm from "remark-gfm";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "./copy";
import TryAgainIcon from "./tryAgain";
import ShareIcon from "./ShareIcon";
import CheckIcon from "@/assets/status/check.svg";
import ErrorIcon from "@/assets/status/error.svg";
import DownPic from "@/assets/common/down_small.svg";
import UpPic from "@/assets/common/up_small.svg";
import chatSuccessPic from "@/assets/common/chat_success.svg";
import classnames from "classnames";
import { debounce } from "lodash";
import { createImageUrl } from "@/utils/common";
import { toPng } from "html-to-image";

const Loading = createImageUrl("common/loading.png");
const webSearchDefaultIcon = createImageUrl("web_search_default_icon.png");

const { Panel } = Collapse;

const Content = ({
  message,
  onRelatedClick,
  curWidth,
  tryAgain,
  handleSetIsShareModalVisible,
  chatMode,
  topicId,
  showShareBtn,
  btnLoading,
  wrapStyle,
}: any) => {
  const theme = useThemeHooks();
  const { content_type = "text", content } = message as any;
  const { t } = useTranslation();
  const [isTryIconHover, setIsTryIconHover] = React.useState(false);
  const [isShareIconHover, setIsShareIconHover] = useState(false);
  const [isCopyIconHover, setIsCopyIconHover] = React.useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const answerRef = useRef<any>(null);
  const pageRef = useRef<any>(null);
  const [normalPreWrap, setNormalPreWrap] = React.useState(false);
  const [workPrompt, setWorkPrompt] = React.useState(false);

  useEffect(() => {
    // 判断answerRef.current里面有没有table标签
    const hasTableTag = answerRef.current?.querySelector("table") !== null;
    setNormalPreWrap(hasTableTag);
  }, [answerRef?.current, message]);

  useEffect(() => {
    const div = pageRef.current as any;
    if (div && div?.querySelectorAll) {
      const anchorTags = div?.querySelectorAll("a");
      anchorTags.forEach((anchor: any) => {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      });
    }
  }, [content, message]);

  useEffect(() => {
    let timer: any = null;
    if (!workPrompt && content?.result_status === "running" && !timer) {
      timer = setTimeout(() => {
        setWorkPrompt(true);
        timer = null;
      }, 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [content, workPrompt]);

  const toggleBox = () => {
    setExpanded(!expanded);
  };

  const Mermaid = memo(({ value }: any) => {
    const ref = useRef(null);
    const initialized = useRef(false);
    const [curValue, setCurValue] = useState(value);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
      if (!initialized.current) {
        (window as any).mermaid.initialize({
          startOnLoad: true,
        });
        initialized.current = true;
      }

      (window as any).mermaid.contentLoaded();
    }, [value]);

    (window as any).mermaid.parseError = (err: any) => {
      setIsError(true);
      setCurValue(() => {
        return <div style={{ color: "#FF2E4D" }}>图形解析失败，请重新尝试</div>;
      });
    };

    const downPng = debounce(() => {
      if (ref?.current === null) {
        return;
      }
      toPng(ref?.current, {
        quality: 1,
        pixelRatio: 2,
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "image.png";
          link.href = dataUrl;
          link.click();
          AntdMessage.success("图片下载成功");
        })
        .catch((err) => {
          AntdMessage.error("图片下载失败");
          console.error("Oops, something went wrong!", err);
        });
    }, 500);

    return (
      <div style={{ position: "relative" }}>
        <div
          ref={ref}
          className="mermaid"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            borderRadius: "8px",
            border: "1px solid #F2F3F5",
          }}
        >
          {curValue}
        </div>
        {isError ? null : (
          <Tooltip title="下载图片">
            <div
              className={style.downloadIcon}
              onClick={() => {
                AntdMessage.loading("图片下载中");
                downPng();
              }}
            >
              <span
                className={classnames(
                  "icon-down_line",
                  "iconfont",
                  "icon-size24"
                )}
              ></span>
            </div>
          </Tooltip>
        )}
      </div>
    );
  });

  // 创建rehype插件

  const rehypeWrapTable = () => (tree: any) => {
    visit(tree, "element", (node, index, parent) => {
      if (node.tagName === "table" && parent) {
        parent.children[index!] = {
          type: "element",
          tagName: "div",
          properties: { className: [style.scrollableTable] },
          children: [node],
        };
      }
    });
  };

  const CodeBlock = ({ node, inline, className, children, ...props }: any) => {
    const match = /language-(\w+)/.exec(className || "");
    const value = String(children).replace(/\n$/, "");
    const [mermaidValue, setMermaidValue] = useState("");

    const debouncedUpdate = debounce((value) => {
      const isFinished =
        message?.is_finished !== undefined ? message.is_finished : true;

      isFinished && setMermaidValue(value);
    }, 0);

    useEffect(() => {
      if (match && match[1] === "mermaid") {
        debouncedUpdate(value);
      }
    }, [value]);

    if (match && match[1] === "mermaid") {
      if (!mermaidValue) return null;
      return <Mermaid value={mermaidValue} />;
    }

    if (!match) {
      return <strong>{children}</strong>;
    }
    return (
      <pre className={style.codeWrap}>
        <div className={style.copyCode}>
          <div className={style.copyCode}>{(match && match[1]) || ""}</div>
          <CopyToClipboard
            text={value}
            onCopy={(text, result) => {
              result && AntdMessage.success(t("chat.copySuccess"));
            }}
          >
            <div className={style.copyCode} style={{ cursor: "pointer" }}>
              <CopyIcon isCopyIconHover={isCopyIconHover} />
              复制代码
            </div>
          </CopyToClipboard>
        </div>
        <code className="hljs" {...props}>
          {children}
        </code>
      </pre>
    );
  };

  const searchType = () => (
    <>
      <div className={style.sectionWrap}>
        <div className={style.title}>
          <img
            src={theme === "dark" ? AnswerIconDark : AnswerIconLight}
            alt=""
            className={style.iconWrap}
          />
          {t("chat.answer")}
        </div>
        <div
          className={`${style.answerContent} ${
            normalPreWrap ? style.hasTableStyle : ""
          }`}
          style={
            normalPreWrap
              ? {
                  whiteSpace: "normal",
                }
              : {}
          }
          ref={answerRef}
        >
          <ReactMarkdown
            rehypePlugins={[rehypeWrapTable, rehypeRaw]}
            remarkPlugins={[gfm]}
            components={{
              code: CodeBlock,
            }}
          >
            {content?.answer}
          </ReactMarkdown>
        </div>
        <div className={style.iconTab}>
          {tryAgain && (
            <CopyToClipboard
              text={
                content_type === "search" ? content?.answer : content || message
              }
              onCopy={(text, result) => {
                result && AntdMessage.success(t("chat.copySuccess"));
              }}
            >
              <div
                className={`${style.iconContent} ${
                  isCopyIconHover ? style.iconHover : ""
                }`}
                onMouseEnter={() => setIsCopyIconHover(true)}
                onMouseLeave={() => setIsCopyIconHover(false)}
              >
                <div className={style.iconContentWrap}>
                  <CopyIcon isCopyIconHover={isCopyIconHover} />
                  {isCopyIconHover ? (
                    <div className={style.showWrap}>{t("chat.copy")}</div>
                  ) : null}
                </div>
              </div>
            </CopyToClipboard>
          )}

          {tryAgain && (
            <div
              className={style.iconContent}
              onMouseEnter={() => setIsTryIconHover(true)}
              onMouseLeave={() => setIsTryIconHover(false)}
              onClick={() => tryAgain()}
            >
              <div className={style.iconContentWrap}>
                <TryAgainIcon isTryIconHover={isTryIconHover} />
                {isTryIconHover ? (
                  <div className={style.showWrap}>{t("chat.tryAgain")}</div>
                ) : null}
              </div>
            </div>
          )}
          {/* <div
            className={style.iconContent}
            onMouseEnter={() => setIsShareIconHover(true)}
            onMouseLeave={() => setIsShareIconHover(false)}
          >
            <div className={style.iconContentWrap} onClick={() => handleSetIsShareModalVisible({topic_id: topicId, chat_mode: chatMode})}>
              <ShareIcon isShareIconHover={isShareIconHover} />
            </div>
          </div> */}
        </div>
      </div>
      {content?.source && content?.source?.length ? (
        <div className={style.sectionWrap}>
          <div className={style.title}>
            <img
              src={theme === "dark" ? SourceIconDark : SourceIconLight}
              alt=""
              className={style.iconWrap}
            />
            {t("chat.source")}
          </div>
          <div
            className={
              curWidth > 600 ? style.customGrid : style.customSmallGrid
            }
          >
            {(content?.source || [])?.map((item: any, index: number) => {
              return (
                <a
                  href={item.url}
                  target="_blank"
                  key={index}
                  className={style.source}
                  rel="noreferrer"
                >
                  <div
                    className={
                      item.type === "knowledge_base"
                        ? style.sourceLocalContentWrap
                        : theme === "dark"
                        ? style.sourceContentWrapDark
                        : style.sourceContentWrap
                    }
                  >
                    <div className={style.sourceTitle}>
                      {item.title}
                      {item.snippet && `: ${item.snippet}`}
                    </div>
                    <div className={style.sourceUrl}>
                      {item.type === "knowledge_base"
                        ? t("chat.localKnowledge")
                        : String(new URL(item.url)?.hostname)}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      ) : null}
      {content?.related && content?.related?.length ? (
        <div>
          <div className={style.title}>
            <img
              src={theme === "dark" ? RelatedIconDark : RelatedIconLight}
              alt=""
              className={style.iconWrap}
            />
            {t("chat.related")}
          </div>
          {content?.related?.map((item: any, index: number) => (
            <div
              key={index}
              className={style.relatedInfo}
              onClick={() => onRelatedClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );

  const getStatusImage = (content: any) => {
    if (content?.result_status === "succeeded") {
      return <img src={chatSuccessPic} alt="" className={style.success} />;
    } else if (content?.result_status === "failed") {
      return (
        <img src={ErrorIcon} alt="" className={classnames(style.success)} />
      );
    } else if (content?.result_status === "stopped") {
      return null;
    } else {
      return (
        <img
          src={Loading}
          alt=""
          className={classnames(style.running, style.running_right)}
        />
      );
    }
  };
  const textType = (msg?: string) => (
    <div
      className={`${style.answerContent} ${
        normalPreWrap ? style.hasTableStyle : ""
      }`}
      ref={answerRef}
      style={
        normalPreWrap
          ? {
              whiteSpace: "normal",
            }
          : {}
      }
    >
      <ReactMarkdown
        rehypePlugins={[rehypeWrapTable, rehypeRaw]}
        remarkPlugins={[gfm]}
        components={{
          code: CodeBlock,
        }}
      >
        {content_type === "tool" ? msg : content || message}
      </ReactMarkdown>
      {btnLoading && (
        <div className={style.chat_history_item_loading}>
          <div className="dot-pulse"></div>
        </div>
      )}
      <div className={style.iconTab}>
        {tryAgain && (
          <CopyToClipboard
            text={
              (content_type === "tool"
                ? msg
                : content_type === "search"
                ? content?.answer
                : content || message) || msg
            }
            onCopy={(text, result) => {
              result && AntdMessage.success(t("chat.copySuccess"));
            }}
          >
            <div
              className={`${style.iconContent} ${
                isCopyIconHover ? style.iconHover : ""
              }`}
              onMouseEnter={() => setIsCopyIconHover(true)}
              onMouseLeave={() => setIsCopyIconHover(false)}
            >
              <div className={style.iconContentWrap}>
                <CopyIcon isCopyIconHover={isCopyIconHover} />
                {isCopyIconHover ? (
                  <div className={style.showWrap}>{t("chat.copy")}</div>
                ) : null}
              </div>
            </div>
          </CopyToClipboard>
        )}
        {content_type === "tool" || !tryAgain ? null : (
          <div
            className={style.iconContent}
            onMouseEnter={() => setIsTryIconHover(true)}
            onMouseLeave={() => setIsTryIconHover(false)}
          >
            <div className={style.iconContentWrap} onClick={() => tryAgain()}>
              <TryAgainIcon isTryIconHover={isTryIconHover} />
              {isTryIconHover ? (
                <div className={style.showWrap}>{t("chat.tryAgain")}</div>
              ) : null}
            </div>
          </div>
        )}
        <div
          className={`${style.iconContent} ${
            isShareIconHover ? style.iconHover : ""
          }`}
          onMouseEnter={() => setIsShareIconHover(true)}
          onMouseLeave={() => setIsShareIconHover(false)}
        >
          {showShareBtn && (
            <div
              className={style.iconContentWrap}
              onClick={() =>
                handleSetIsShareModalVisible({
                  topic_id: topicId,
                  chat_mode: chatMode,
                })
              }
            >
              <ShareIcon isShareIconHover={isShareIconHover} />
              {isShareIconHover ? (
                <div className={style.showWrap}>分享</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const getTextTypeRender = useMemo(() => {
    return textType();
  }, [
    content,
    message,
    normalPreWrap,
    btnLoading,
    content_type,
    isTryIconHover,
    isCopyIconHover,
    isShareIconHover,
  ]);

  const getToolTypeRender = useMemo(() => {
    return textType(content.result);
  }, [
    content,
    message,
    normalPreWrap,
    btnLoading,
    content_type,
    isTryIconHover,
    isCopyIconHover,
    isShareIconHover,
  ]);

  const getSearchTypeRender = useMemo(() => {
    return searchType();
  }, [
    content,
    message,
    normalPreWrap,
    btnLoading,
    content_type,
    isTryIconHover,
    isCopyIconHover,
    isShareIconHover,
  ]);

  return (
    <div
      className={classnames(style.page, wrapStyle)}
      style={
        content_type === "search"
          ? { padding: "24px 0 16px" }
          : { margin: "16px" }
      }
      ref={pageRef}
    >
      {content_type === "search" ? (
        getSearchTypeRender
      ) : content_type === "tool" ? (
        <div>
          {content?.steps.length > 0 ? (
            <div className={style.toolWrap}>
              <div className={style.workflow_title} onClick={toggleBox}>
                <span className={style.workflow_title_loading}>
                  {getStatusImage(content)}
                </span>
                {content?.workflow_name}{" "}
                {content?.steps.length === 1 &&
                content?.steps[0].name === "search_with_web_search_engine"
                  ? `已搜到 ${
                      content?.steps[0]?.outputs?.results?.length || 0
                    } 个来源`
                  : ""}
                <span className={style.anticon}>
                  <img
                    className={style.icon}
                    src={expanded ? DownPic : UpPic}
                    alt=""
                  />
                </span>
              </div>
              {workPrompt && content?.result_status === "running" ? (
                <span className={style.hint}>
                  工作流正在工作，谢谢您的耐心。
                </span>
              ) : null}
              {expanded ? (
                <div className={style.stepsWrap}>
                  {content?.steps.map((item?: any) => {
                    if (item.name === "search_with_web_search_engine") {
                      return (
                        <div className={style.search_engine_wrap}>
                          <div>
                            <div className={style.search_engine_title}>
                              理解问题
                            </div>
                            <div className={style.inputs_data}>
                              {item?.inputs?.query}
                            </div>
                          </div>
                          <div className={style.search_engine_title}>
                            搜索来源
                          </div>
                          <div className={style.outputs_data}>
                            {item?.outputs?.results.map(
                              (
                                result: {
                                  name: string;
                                  url: string;
                                  snippet: string;
                                  favicon: string;
                                },
                                index: number
                              ) => {
                                return (
                                  <div
                                    className={style.search_engine_result_wrap}
                                  >
                                    <img
                                      src={
                                        result.favicon || webSearchDefaultIcon
                                      }
                                      className={
                                        style.search_engine_result_icon
                                      }
                                    ></img>
                                    <a
                                      href={result.url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className={style.search_engine_result}
                                    >
                                      {result.name}
                                    </a>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div key={item?.tool_call_id} className={style.steps}>
                        <div>{item?.alias}</div>
                        {item?.status === "succeeded" && (
                          <img src={CheckIcon} alt="" />
                        )}
                        {item?.status === "failed" && (
                          <img src={ErrorIcon} alt="" />
                        )}
                        {item?.status === "running" && (
                          <img src={Loading} alt="" className={style.running} />
                        )}
                        {item?.status === "stopped" && "运行已停止"}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          ) : null}
          {getToolTypeRender}
        </div>
      ) : (
        getTextTypeRender
      )}
    </div>
  );
};

export default Content;
