import { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import Find from "./components/Find";
import FrontPosition from "./components/FrontPosition";
import { setBrowserUrl } from "@/utils/url";
import { useSearchParams } from "react-router-dom";
import SpacePic from "@/assets/community/space.svg";
import DiscoverPic from "@/assets/community/discover.svg";
import classnames from "classnames";

const Community = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const [pageType, setPageType] = useState<string>("discover");
  const [category, setCategory] = useState<any>([
    {
      id: "discover",
      name: t("community.explore"),
      icon: DiscoverPic,
    },
    {
      id: "space",
      name: t("community.frontier"),
      icon: SpacePic,
    },
  ]);

  // 获取angent 信息
  useEffect(() => {
    let tab = params.getAll("tab")[0];
    if (tab) {
      setPageType(tab);
    }
  }, []);

  useEffect(() => {
    setBrowserUrl({
      tab: pageType,
    });
  }, [pageType]);

  return (
    <div className={Styles.page}>
      {/* <div className={Styles.page_header}>
        <Skeleton width="100%" height={100} />
      </div> */}
      <div className={Styles.category}>
        {category.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={Styles.category_item}
              onClick={() => {
                setPageType(item.id);
              }}
            >
              <div className={Styles.category_item_name_box}>
                <img
                  src={item.icon}
                  className={classnames(
                    Styles.category_img,
                    pageType === item.id ? Styles.category_img_active : ""
                  )}
                ></img>
                <div
                  className={classnames(
                    Styles.category_name,
                    pageType === item.id ? Styles.category_name_active : ""
                  )}
                >
                  {item.name}
                </div>
              </div>
              <div
                className={classnames(
                  Styles.category_item_bottom_active,
                  pageType === item.id ? Styles.category_item_bottom : ""
                )}
              ></div>
            </div>
          );
        })}
      </div>

      <div className={Styles.content}>
        {pageType === "discover" && <Find />}
        {pageType === "space" && <FrontPosition />}
      </div>
    </div>
  );
};

export default Community;
