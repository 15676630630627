import { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.scss";
import { Link } from "react-router-dom";

type props = {
  show: boolean;
  onClose?: any;
};

const CreateAgent = ({ show, onClose }: props) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const cancelModal = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={showModal}
      title={null}
      closeIcon={false}
      footer={null}
      centered
      onCancel={() => {
        cancelModal();
      }}
      className={Styles.modal}
      width={440}
    >
      <div className={Styles.content}>
        <div className={Styles.title}>{t("common.create")}</div>
        <div className={Styles.desc}>{t("create.dialog.agent.desc")}</div>
        <div className={Styles.selector}>
          <Link
            to="/workflow"
            onClick={() => {
              cancelModal();
            }}
          >
            <div className={Styles.selector_item}>
              {t("create.singal.agent")}
            </div>
          </Link>
          <div className={Styles.selector_item}>{t("create.muti.agent")}</div>
        </div>
        <Link
          to="/my_agent"
          onClick={() => {
            cancelModal();
          }}
        >
          <div className={Styles.to_detail}>
            <Button type="primary">{t("create.to.detail")}</Button>
          </div>
        </Link>
      </div>
    </Modal>
  );
};

export default CreateAgent;
