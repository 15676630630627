import { Button, ConfigProvider, message, Modal, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./Content.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";

const ShareModal = ({ setOpen, open, url, shareTitle }: any) => {
  return (
    <ConfigProvider
      wave={{}}
      theme={{
        token: {
          // 使用品牌色
          colorPrimary: "#007890",
        },
      }}
    >
      <Modal
        width={600}
        open={open}
        onCancel={() => setOpen()}
        footer={[]}
        centered
        className={styles.share}
        maskClosable={false}
      >
        <div className={styles.shareContent}>
          <div className={styles.shareTitle}>对话分享</div>
          <div className={styles.shareUrl}>
            {(shareTitle && url && `【${shareTitle}】${url}`) || (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            )}
          </div>
          <CopyToClipboard
            text={`【${shareTitle}】${url}`}
            onCopy={(text, result) => {
              result && message.success("复制成功");
            }}
          >
            <Tooltip title={!url ? "还未生成链接，请稍后..." : ""}>
              <Button
                className={styles.shareBtn}
                disabled={!url}
                type="primary"
              >
                复制链接
              </Button>
            </Tooltip>
          </CopyToClipboard>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default ShareModal;
