import { Modal } from "antd";
import { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import Content from './Content'
import { useTranslation } from "react-i18next";

type IPrivatePolicy = {
  show: boolean;
  onClose: any;
};

const PrivatePolicy = ({ show, onClose }: IPrivatePolicy) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      width={600}
      title={t("privacy_policy.title")}
      className={Styles.modal}
    >
      <Content />
    </Modal>
  );
};

export default PrivatePolicy;
