// store.ts

import { createSlice } from "@reduxjs/toolkit";

// 定义初始状态
interface ModalState {
  selectCompanyModalVisible: boolean;
}

const initialState: ModalState = {
  selectCompanyModalVisible: false,
};

// 创建 slice
const selectCompanyModal = createSlice({
  name: "selectCompanyModal",
  initialState,
  reducers: {
    openSelectCompanyModal(state) {
      state.selectCompanyModalVisible = true;
    },
    closeSelectCompanyModal(state) {
      state.selectCompanyModalVisible = false;
    },
  },
});

// 导出 action creators
export const { openSelectCompanyModal, closeSelectCompanyModal } =
  selectCompanyModal.actions;

export default selectCompanyModal.reducer;
