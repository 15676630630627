import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Styles from "./index.module.scss";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className={Styles.content}>
      <h1>{t("privacy_policy.introduction")}</h1>
      <span>{t("privacy_policy.welcome_message")}</span>
      <h1>{t("privacy_policy.account_registration_and_security")}</h1>
      <p>{t("privacy_policy.provide_accuate_information")}</p>
      <p>{t("privacy_policy.responsibility_of_account")}</p>
      <p>{t("privacy_policy.right_of_refusal")}</p>
      <h1>{t("privacy_policy.user_content")}</h1>
      <p>{t("privacy_policy.retain_ownership")}</p>
      <p>{t("privacy_policy.declare_and_guarantee")}</p>
      <h1>{t("privacy_policy.user_behavior")}</h1>
      <span>{t("privacy_policy.agreement_of_behavior")}</span>
      {Object.keys(
        JSON.parse(String(t("privacy_policy.prohibited_behaviors_string")))
      ).map((key) => (
        <span key={key}>
          {key}. {t(`privacy_policy.prohibited_behaviors.${key}`)}
        </span>
      ))}
      <h1>{t("privacy_policy.intellectual_property")}</h1>
      <p>{t("privacy_policy.protection_of_intellectual_property")}</p>
      <p>{t("privacy_policy.acknowledge_and_agree")}</p>
      <h1>{t("privacy_policy.disclaimer_and_limitation_of_liability")}</h1>
      <p>{t("privacy_policy.service_provided_as_is")}</p>
      <p>{t("privacy_policy.no_liability_for_damage")}</p>
      <h1>{t("privacy_policy.changes_to_terms")}</h1>
      <p>{t("privacy_policy.right_to_modify_terms")}</p>
      <p>{t("privacy_policy.acceptance_of_new_terms")}</p>
      <h1>{t("privacy_policy.applicable_law_and_dispute_resolution")}</h1>
      <p>{t("privacy_policy.governing_law")}</p>
      <p>{t("privacy_policy.dispute_resolution")}</p>
      <h1>{t("privacy_policy.miscellaneous")}</h1>
      <p>{t("privacy_policy.invalid_terms")}</p>
      <p>{t("privacy_policy.no_assignment")}</p>
      <h1>{t("privacy_policy.contact_information")}</h1>
      <span>
        <Trans
          i18nKey="privacy_policy.contact_us"
          values={{
            email: "caofanyu@synergency.cn",
          }}
        >
          <a href="mailto:caofanyu@synergency.cn">caofanyu@synergency.cn</a>
        </Trans>
      </span>
    </div>
  );
};

export default PrivacyPolicy;
