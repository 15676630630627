import { configureStore } from "@reduxjs/toolkit";
import userInfo from "./reducer/userInfo/index";
import loginConfig, {
  openModal,
  closeModal,
  openBindPhoneModal,
  closeBindPhoneModal,
} from "./reducer/loginConfig/index";
import config from "./reducer/config/index";
import selectCompanyModal, {
  openSelectCompanyModal,
  closeSelectCompanyModal,
} from "./reducer/selectComany/index";
import companyInfo from "./reducer/companyInfo";
import knowledgeFiles from "./reducer/knowledgeFiles";

export default configureStore({
  reducer: {
    userInfo,
    loginConfig,
    config,
    selectCompanyModal,
    companyInfo,
    knowledgeFiles,
  },
});

export const loginOption = {
  openModal,
  closeModal,
  openBindPhoneModal,
  closeBindPhoneModal,
};

export const selectCompanyModalOption = {
  openSelectCompanyModal,
  closeSelectCompanyModal,
};
