import baseURL from "@/request/getBaseURL";
import Cookies from "js-cookie";

const fileToDataURL = (file: Blob): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = (e) => resolve((e.target as FileReader).result);
    reader.readAsDataURL(file);
  });
};
const dataURLToImage = (dataURL: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = dataURL;
  });
};
const canvastoFile = (
  canvas: HTMLCanvasElement,
  type: string,
  quality: number
): Promise<Blob | null> => {
  return new Promise((resolve) =>
    canvas.toBlob((blob) => resolve(blob), type, quality)
  );
};
/**
 * 图片压缩方法
 * @param {Object}  file 图片文件
 * @param {String} type 想压缩成的文件类型
 * @param {Nubmber} quality 压缩质量参数
 * @returns 压缩后的新图片
 */
export const compressionFile = async (
  file: any,
  type = "image/jpeg",
  quality = 0.5
) => {
  const fileName = file.name;
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const base64 = await fileToDataURL(file);
  const img = await dataURLToImage(base64);
  canvas.width = img.width;
  canvas.height = img.height;
  context.clearRect(0, 0, img.width, img.height);
  context.drawImage(img, 0, 0, img.width, img.height);
  const blob = (await canvastoFile(canvas, type, quality)) as Blob; // quality:0.5可根据实际情况计算
  const newFile = await new File([blob], fileName, {
    type: type,
  });
  return newFile;
};

export const customFileUpload = ({
  file,
  onSuccess,
  onError,
  folderPath = "chat",
}: any) => {
  // 在这里自定义上传请求
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_path", folderPath || "knowledge");
  const token = localStorage.getItem("token") || Cookies.get("token");

  // 添加令牌到请求头中
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  fetch(`${baseURL}/aichat/file/upload/`, {
    method: "POST",
    headers,
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      // 处理上传成功的响应
      onSuccess(data, file);
    })
    .catch((error) => {
      console.log(error);
      // 处理上传失败的情况
      onError(error, file);
    });
};
