import React, { useEffect, useRef, useState } from "react";
import App from "./components/App";
import KnowledgeContent from "@/components/KnowledgeContent";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import { getUrlParams } from "@/utils/url";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { ListType } from "@/components/KnowledgeContent/component/FolderList/type";
import Styles from "./index.module.scss";  // add actual import location

const MyAgent = () => {
  const { t } = useTranslation();
  const [pageType, setPageType] = useState<string>("app");
  const location = useLocation();
  const listRef = useRef<any>(null);
  const [showAddBtn, setShowAddBtn] = useState(true);

  useEffect(() => {
    const params: any = getUrlParams(window.location.href);

    if (params.pageType) {
      setPageType(params.pageType);
    } else {
      setPageType("app");
    }
  }, [location]);

  const [category, setCategory] = useState<any>([
    {
      id: "app",
      name: t("myagent.app"),
      icon: "icon-application_fill",
    },
    {
      id: "knowledge",
      name: t("myagent.repository"),
      icon: "icon-a-myknowledge_fill",
    },
    {
      id: "tool",
      name: t("myagent.tools"),
      icon: "icon-tool_fill",
      disabled: true,
    },
  ]);

  const handleAddFolderClick = (event: React.MouseEvent) => {
    listRef.current?.handleAddFolderClick(event);
  };

  const changeTypeCallback = (flag: string) => {
    setShowAddBtn(flag === 'folder' ? true : false);
  };

  return (
    <div className={classnames(Styles.page, "scroll-hide")}>
      <div className={Styles.category}>
        {category.map((item: any, index: number) => (
          <div
            key={index}
            className={classnames(
              Styles.category_item,
              item.disabled && Styles.category_item_disabled,
            )}
            onClick={() => {
              if (!item.disabled) {
                setPageType(item.id);
              }
            }}
          >
            <div className={classnames(Styles.category_item_name_box)}>
              <div
                className={classnames(
                  Styles.category_img,
                  pageType === item.id && Styles.category_img_active,
                  "iconfont",
                  "icon-size24",
                  item.icon
                )}
              >
              </div>
              <div
                className={classnames(
                  Styles.category_name,
                  pageType === item.id && Styles.category_name_active
                )}
              >
                {item.name}
              </div>
            </div>
            <div
              className={classnames(
                Styles.category_item_bottom_active,
                pageType === item.id && Styles.category_item_bottom
              )}
            />
          </div>
        ))}
      </div>
      {pageType === "app" && <App />}
      {pageType === "knowledge" && (
        <>{
          showAddBtn && <div className={Styles.page_list_item_add}>
            <div
              className={Styles.page_list_item_add_inner}
              onClick={(e) => handleAddFolderClick(e)}
            >
              <span className={classnames("iconfont","icon-increase_line",Styles.img)}></span>
              <span>{t("knowledge.newFolder")}</span>
            </div>
          </div>
        }
          <KnowledgeContent type={ListType.page} ref={listRef} changeType={changeTypeCallback}/>
        </>
      )}
    </div>
  );
};

export default MyAgent;