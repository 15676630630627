import React from "react";
import useThemeHooks from "@/hooks/useThemeHooks";
const TryAgain = ({ isTryIconHover }: any) => {
  const theme = useThemeHooks();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.12413 8.65625C0.763188 8.63711 0.484282 8.32812 0.500688 7.96719L0.511626 7.74844C0.64561 5.07148 2.85772 2.96875 5.53741 2.96875H8.81319V1.97344C8.81319 1.43477 9.24795 1 9.78663 1C10.0273 1 10.2597 1.09023 10.4374 1.24883L12.5347 3.13555C12.6741 3.26133 12.7507 3.43633 12.7507 3.62227C12.7507 3.8082 12.6714 3.98594 12.5347 4.10898L10.4374 5.9957C10.2597 6.15703 10.0273 6.24453 9.78663 6.24453C9.25069 6.24453 8.81319 5.80977 8.81319 5.27109V4.28125H5.53741C3.55499 4.28125 1.92256 5.83437 1.82413 7.81406L1.81319 8.03281C1.79405 8.39375 1.48506 8.67266 1.12413 8.65625ZM10.1257 4.51367L11.1128 3.625L10.1257 2.73633V4.51367ZM13.8773 7.34375C14.2382 7.36289 14.5171 7.66914 14.5007 8.03281L14.4898 8.25156C14.3558 10.9285 12.1437 13.0312 9.46397 13.0312H6.18819V14.0266C6.18819 14.5625 5.75342 15 5.21475 15C4.97413 15 4.7417 14.9098 4.56397 14.7512L2.4667 12.8645C2.32725 12.7387 2.25069 12.5637 2.25069 12.3777C2.25069 12.1918 2.32999 12.0141 2.4667 11.891L4.56397 10.0043C4.7417 9.84297 4.97413 9.75547 5.21475 9.75547C5.75069 9.75547 6.18819 10.1902 6.18819 10.7289V11.7188H9.46397C11.4464 11.7188 13.0788 10.1656 13.1773 8.18594L13.1882 7.96719C13.2073 7.60625 13.5136 7.32734 13.8773 7.34375ZM4.87569 11.4863L3.88858 12.375L4.87569 13.2637V11.4863Z"
        fill={
          theme === "dark"
            ? isTryIconHover
              ? "#e8e8e6"
              : "#a7a7a7"
            : isTryIconHover
            ? "#272B30"
            : "#80868d"
        }
      />
    </svg>
  );
};

export default TryAgain;
