import { Modal } from "antd";
import Styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import codePic from "./code.jpg";

export type ICode = {
  showCode: boolean;
  closeModal: (showCode: boolean) => void;
  title?: string;
  subTitle?: string;
  content?: string;
};

const CodeModal = ({
  showCode,
  closeModal,
  title,
  subTitle,
  content,
}: ICode) => {
  const { t } = useTranslation();
  const handleCancel = () => {
    closeModal(false);
  };

  return (
    <Modal
      title={title || t("common.contact.us")}
      open={showCode}
      className={Styles.modal}
      onCancel={handleCancel}
      centered
      footer={null}
      width={560}
    >
      <div className={Styles.content}>
        <div className={Styles.title}>{subTitle||t("common.customized.title")}</div>
        <img src={codePic} />
        <div className={Styles.tip}>{content || t("common.wechat.title")}</div>
      </div>
    </Modal>
  );
};

export default CodeModal;
