import { title } from "process";

export default {
  "common.welcome": "登录 AI 智能体",
  "common.password": "密码:",
  "common.password.confirm": "确认密码:",
  "common.userName": "用户名:",
  "common.login": "登录",
  "common.login.success": "登录成功",
  "common.register": "注册",
  "common.register.success": "注册成功",
  "common.register.title": "注册账户",
  "common.product.name": "新智体",
  "common.user.placeHolder": "请输入用户名!",
  "common.user.telephone": "请输入手机号!",
  "common.user.name": "请输入姓名!",
  "common.seeking": "智能工具",
  "common.aicard": "知识卡片",
  "common.special": "你的专区",
  "common.chat": "对话",
  "common.canvas": "画布",
  "common.community": "实践社区",
  "common.create": "智能体创建",
  "common.myAgent": "我的专区",
  "common.publish": "智能体发布",
  "common.submit": "提交",
  "common.send": "发送",
  "common.tags": "标签",
  "common.publish.time": "发布时间:",
  "common.update.time": "最近更新:",
  "common.join.chat": "加入对话",
  "common.create.same": "创建同款",
  "common.agent.singal": "单智能体",
  "common.back": "返回",
  "common.chat.default.greetings": "今天我有什么可以帮你的？",
  "common.you": "你",
  "common.edit": "编辑",
  "common.delete": "删除",
  "common.category": "类别",
  "common.yes": "是",
  "common.no": "否",
  "common.category.recent": "最近",
  "common.category.work": "工作",
  "common.category.learning": "学习",
  "common.category.lifestyle": "生活",
  "common.or": "或",
  "common.like": "喜欢",
  "common.agentCard": "智能体卡片",
  "common.agent": "智能工具",
  "common.ai.knowledge": "AI知识",
  "common.ai.assistant": "AI助理",
  "common.enterprise.zone": "企业专区",
  "common.join.my.ai.team": "加入收藏",
  "common.move.out.team": "取消收藏",
  "common.platform.customization": "平台定制",
  "common.member.publishing": "成员发布",
  "common.contact.us": "联系我们",
  "common.customized.title": "当您有定制化需求时,请与我们联系。",
  "common.wechat.title": "微信扫一扫，添加专属客服",
  "common.custom.available": "暂无AI助理",
  "common.custom.card": "暂无AI知识",
  "common.business.need": "如果您有希望解决的企业需求，",
  "common.please.contactUs": "请联系我们",
  "common.invite.member": "邀请成员",
  "common.affirm": "确认",
  "common.member.added": "成员已添加，该成员可以用手机号登录了。",
  create: {
    dialog: {
      agent: {
        desc: "开始创建您的Agent，你可以直接创建，也可以进入你Agent主页",
      },
    },
    normalmode: "普通模式",
    professionalmode: "专家模式",
    name: {
      agent: "智能体名称",
      placeholder: "请输入智能体名称",
    },
    introduce: {
      agent: "智能体介绍",
      placeholder: "请输入智能体介绍",
    },
    provider: {
      model: "模型提供商",
      placeholder: "请选择模型提供商",
      modelDesc:
        "chatgpt只能与大模型聊天，但响应快,autogen-gpt可以实现联网搜索，爬取知识库，绘图等功能，但响应稍慢",
    },
    humanInput: {
      placeholder: "请选择发送方格式",
    },
    clue: {
      receiver: "助理智能体",
      receiverTip: "扮演不同角色的智能体和你的用户代理进行对话。",
      edit: "提示词编辑",
      placeholder: "请选择提示词编辑类型",
      sender: "用户代理",
      senderTip: "作为你的化身发送和接收其他智能体的信息。",
    },
    repository: {
      upload: "知识库上传",
      greetings: "问候语",
    },
    agent: {
      tools: "智能体工具",
      type: "智能体 类型",
    },
    reply: {
      count: "最大自动连续回复数：",
      countTip:
        "用户代理和其他智能体之间最多回复次数。数值越高，回复内容越多，对应质量也越高，但会消耗更多算力。",
    },
    input: {
      format: "输入格式",
      formatTip:
        "用户代理每次收到消息时是否需要你的输入：(1)当“ALWAYS”时，代理每次收到消息时都会提示你输入。(2)当“TERMINATE”时，只有当收到终止消息或自动回复次数达到最大自动连续回复数，代理才会提示你输入。(3)当“NEVER”时，代理将永远不会提示你输入。",
      prompts: "系统输入提示词",
    },
    emotional: {
      temperature: "情感温度：",
    },
    image: {
      create: "图像生成",
      desc: "可以输入一段描述，比如“一只穿着太空服的猫在月球上散步”，人工智能就会根据这个描述生成一幅相应的图片。这项技术通常使用复杂的算法和大量的图片数据来训练，使得它能理解和转化文字信息为视觉内容。这种功能可以用于各种创意领域，比如艺术创作、产品设计、娱乐等",
      cover: "背景图片",
    },
    info: {
      search: "arxiv 论文检索",
    },
    preview: {
      title: "调试与预览",
    },
    singal: {
      agent: "单 智能体",
    },
    muti: {
      agent: "多 智能体",
    },
    to: {
      detail: "查看我创建的Agent",
    },
    conversation: "对话类",
    greeting: {
      placeholder: "请输入问候语",
    },
    upload: {
      files: "上传文件",
      tips: "已支持'.pdf','.docx','.pptx','.xlsx','.md','.txt','.csv',每个文件不超过100MB",
    },
    last: {
      updatetime: "上次保存时间:",
    },
    category: {
      placeholder: "请选择类别",
    },
    card: {
      title: "卡片标题",
      titlePlaceholder: "请输入卡片标题",
      cardContent: "图文知识",
      maxCardNumber: "（最多可上传9张）",
      uploadMore: "上传更多",
    },
    receiverNamePlaceholder: "请输入该Agent昵称",
    changePortrait: "更换头像",
    nickName: "昵称",
    portrait: "头像",
    receiverCode: "Agent编号",
    deleteReceiver: "删除Agent",
    addReceiver: "添加Agent",
    save: "保存",
    saveTip: "最新编辑保存之后才生效",
    normalDesc: "单智能体模式，创建自己的专属智能体",
    professionalDesc: "多智能体模式，多个智能体互相协作帮你完成任务",
    systemMessageTip: "请输入系统输入提示词",
    saveSuccess: "保存成功",
    saveFail: "信息保存失败，请稍后重试",
    receiverNameRepeat: "Agent昵称不能重复，请重新输入",
    unsavedTitle: "您还未保存编辑内容",
    unsavedContent: "如果点击确定返回按钮,您本次编辑的内容将丢失。",
    unsavedConfirm: "确定返回",
  },

  "publish.setting.title": "发布设置",
  "publish.market.title": "发布到市场",
  "publish.market.desc":
    "用户可以通过Agent市场找到发布的Agent，并将Agent公开给其他用户使用",
  "publish.api.title": "生成API",
  "publish.api.desc": "用户可以通过生成API进行后续开发",
  "publish.link.title": "生成发布链接",
  "publish.link.desc": "用户可以通过网页进入Agent直接进行交互",
  "publish.third.title": "绑定第三方",
  "publish.third.desc": "用户可以绑定第三方App，完成配置后，可在App中使用",
  "publish.dialog.title": "发布信息设置",
  "publish.dialog.style": "发布风格选择",
  "publish.dialog.stylePlaceHolder": "请选择发布风格",
  "publish.dialog.permission": "发布权限设置",
  "publish.dialog.toPlace": "发布到Agent广场",
  "publish.dialog.toDashBoard": "仅发布到个人中心",
  "publish.dialog.edit.permission": "编辑权限设置",
  "publish.dialog.viewOnly": "仅查看",
  "publish.dialog.editView": "编辑和查看",
  "publish.dialog.Agreement": "点击阅读《Agent智能体平台创建协议》",
  "publish.copy.success": "复制成功",
  "publish.recommand.tags": "推荐标签",
  "publish.question.example": "提问示例",
  "publish.editable": "是否允许其他用户创建同款",
  "publish.tags.placeholder": "请选择标签",
  "publish.btnText": "发布智能体",
  "publish.agreement": "发布即表示您同意遵守",
  "publish.policy": "《Syngents.cn的内容政策》",
  "publish.last.time": "上次发布时间:",
  "publish.rollback": "撤回发布",
  "publish.rollbackTip": '你确定要撤回"<0>{{name}}</0>"吗？',
  "publish.success": "发布成功",
  "publish.toCompany": "发布到企业专区",
  "publish.disabledToPublish": "个人版不支持发布到企业专区",

  chat: {
    withModal: "与大模型对话",
    withAgent: "智能体列表",
    input: {
      placeholder: "输入你想了解的内容",
    },
    delete: {
      title: "删除对话？",
      content: "确定删除“<0>{{name}}</0>”?",
    },
    source: "来源",
    answer: "答复",
    related: "相关內容",
    copy: "复制",
    copySuccess: "复制成功",
    tryAgain: "再试一次",
    localKnowledge: "本地知识库",
    refresh: "换一批",
  },

  "community.explore": "发现",
  "community.frontier": "AI 前沿阵地",
  "community.cameby": "人来过",
  "community.member": "成员",
  "community.online": "人在线",
  "community.watched": "人观看",
  "community.share": "人分享",
  login: {
    title: "登录到Syngents",
    inputEmail: "请输入你的邮箱",
    inputPhone: "请输入你的手机号",
    inputName: "请输入姓名",
    goLogin: "登录",
    google: "Google登录",
    wechat: "微信登录",
    continue: "继续即表示您接受我们的 <0>{{policy}}</0>",
    getCode: "获取验证码",
    inputCode: "请输入验证码",
    getCodeSuccess: "获取验证码成功",
    secondsLeft: "<0>{{second}}</0> 秒后重新获取",
    gettingCode: "获取中...",
  },
  myagent: {
    app: "应用",
    repository: "知识库",
    tools: "工具",
    newagent: "创建AI助理",
    subtotal: {
      left: "一共创建",
      right: "个智能体",
    },
    delete: {
      title: "删除 Agent",
      content: '你确定要删除"<0>{{name}}</0>"吗？',
      success: "删除成功",
      successText: '"<0>{{name}}</0>"已从个人中心列表中删除！',
      fail: "删除失败",
      failText: "删除失败，请稍后重试或联系管理员。",
    },
    newCardAgent: "创建AI知识",
    uploadImage: "上传图片",
    empty: "暂无内容",
  },
  knowledge: {
    title: "我的知识库",
    upload: "上传知识库",
    delete: {
      title: "删除 知识库",
      content: '你确定要删除"<0>{{name}}</0>"吗？',
    },
    newFolder: "创建文件夹",
  },
  enterprise: {
    customer: "企业用户",
    delete: {
      title: "删除企业用户",
      content: '你确定要删除"<0>{{name}}</0>"吗？',
    },
    title:'提示',
    subTitle:'可用席位为0，请联系客服进行购买',
    content:'请扫描添加客服',
    add:{
      title:'添加企业成员',
      abstract:'企业成员"是你在企业、部门或组织内的同事',
      hint:'输入手机号添加成员。',
    }
  },
  "explore.tab.tool": "多模态工具",
  "explore.tab.study": "学习",
  "explore.tab.analysis": "研究分析",
  "explore.tab.writing": "专业写作",
  "explore.tab.coding": "代码",
  "explore.tab.prompt": "提示词",
  "explore.tab.assistant": "小助手",
  "explore.tab.entertainment": "娱乐休闲",
  privacy_policy: {
    title: "智纪服务条款",
    introduction: "简介",
    welcome_message:
      "欢迎使用智纪平台（以下简称“智纪”或“我们”）。本服务条款（“条款”）规定了您对智纪提供的网站、移动应用程序及其它相关产品和服务（统称为“服务”）的访问和使用。通过访问或使用我们的服务，您同意遵守以下条款和我们的隐私政策。",
    account_registration_and_security: "账户注册与安全",
    provide_accuate_information:
      "您必须提供真实、准确、完整的信息以创建账户，并定期更新账户信息。",
    responsibility_of_account: "您负责对账户下的所有活动负责。",
    right_of_refusal:
      "智纪有权拒绝服务、终止账户或删除内容，尤其是在违反本条款的情况下。",
    user_content: "用户内容",
    retain_ownership:
      "您通过智纪提交的内容，您保留所有权，但授予智纪非排他性、免版税、全球性、永久且可再许可的权利。",
    declare_and_guarantee:
      "您声明并保证拥有提交内容的所有权利，或已获得必要的许可。",
    user_behavior: "用户行为",
    agreement_of_behavior: "您同意不使用服务进行以下行为：",
    prohibited_behaviors_string: `{
  "A": "发布或传播诽谤、淫秽、色情、粗俗、冒犯性或种族歧视的内容。",
  "B": "侵犯知识产权、商业秘密、隐私权或其他专有权利。",
  "C": "提交含有病毒、木马、间谍软件或其他破坏性代码的内容。",
  "D": "发布未经授权的广告、垃圾邮件、连锁信、任何其他形式的未经授权的招揽或任何形式的彩票或赌博。",
  "E": "提供虚假或误导性信息，或冒充任何个人或实体。",
  "F": "违反任何适用的法律或法规，包括但不限于版权法、商标法、隐私法和反垃圾邮件法。",
  "G": "宣扬非法、有害或暴力活动。",
  "H": "骚扰、威胁、欺凌、欺骗或以其他方式干扰其他用户。",
  "I": "违反服务的任何程序、政策或规定。",
  "J": "干扰、破坏服务或相关网络的正常运行。",
  "K": "未经授权访问服务、其他账户或与服务连接的计算机系统或网络。",
  "L": "使用任何机器人、蜘蛛、抓取工具或其他自动化方式访问服务。",
  "M": "逆向工程、反编译、反汇编或以其他方式尝试获取服务的源代码。",
  "N": "修改、适配、破解或以其他方式破坏服务的完整性。",
  "O": "未经智纪明确书面许可，复制、拷贝、出售、转售或利用服务的任何部分。"
}`,
    prohibited_behaviors: {
      A: "发布或传播诽谤、淫秽、色情、粗俗、冒犯性或种族歧视的内容。",
      B: "侵犯知识产权、商业秘密、隐私权或其他专有权利。",
      C: "提交含有病毒、木马、间谍软件或其他破坏性代码的内容。",
      D: "发布未经授权的广告、垃圾邮件、连锁信、任何其他形式的未经授权的招揽或任何形式的彩票或赌博。",
      E: "提供虚假或误导性信息，或冒充任何个人或实体。",
      F: "违反任何适用的法律或法规，包括但不限于版权法、商标法、隐私法和反垃圾邮件法。",
      G: "宣扬非法、有害或暴力活动。",
      H: "骚扰、威胁、欺凌、欺骗或以其他方式干扰其他用户。",
      I: "违反服务的任何程序、政策或规定。",
      J: "干扰、破坏服务或相关网络的正常运行。",
      K: "未经授权访问服务、其他账户或与服务连接的计算机系统或网络。",
      L: "使用任何机器人、蜘蛛、抓取工具或其他自动化方式访问服务。",
      M: "逆向工程、反编译、反汇编或以其他方式尝试获取服务的源代码。",
      N: "修改、适配、破解或以其他方式破坏服务的完整性。",
      O: "未经智纪明确书面许可，复制、拷贝、出售、转售或利用服务的任何部分。",
    },
    intellectual_property: "知识产权",
    protection_of_intellectual_property:
      "智纪的服务内容、软件和商标受法律保护，未经授权不得使用。",
    acknowledge_and_agree: "您承认并同意不侵犯智纪或任何第三方的知识产权。",
    disclaimer_and_limitation_of_liability: "免责声明和责任限制",
    service_provided_as_is:
      "服务按“现状”提供，智纪不对服务的中断、错误或用户数据的损失承担责任。",
    no_liability_for_damage:
      "在任何情况下，智纪不对任何直接、间接、特殊、后果性或惩罚性损害承担责任，包括但不限于利润、商誉、使用损失、数据损失或其他无形损失。",
    changes_to_terms: "条款变更",
    right_to_modify_terms:
      "智纪可随时修改这些条款，并通过网站或电子邮件通知您。",
    acceptance_of_new_terms:
      "继续使用服务即表示您接受新条款。如果您不同意新条款，您必须停止使用服务。",
    applicable_law_and_dispute_resolution: "适用法律和争议解决",
    governing_law: "本条款受智纪所在司法管辖区的法律管辖。",
    dispute_resolution:
      "任何争议应首先通过协商解决，协商不成时，应提交有约束力的仲裁。",
    miscellaneous: "其他",
    invalid_terms:
      "如果条款的任何部分无法执行或无效，该部分将在最小范围内受到限制或消除。",
    no_assignment:
      "您不得转让或转让这些条款以及根据本条款授予的任何权利和许可。",
    contact_information: "联系方式",
    contact_us:
      "如果您对这些条款有任何疑问或疑虑，请通过<0>{{email}}</0>与我们联系。",
  },
  workflow: {
    fileName: "文件名称",
  },
  status: "状态",
  size: "大小",
  action: "操作",
  cancel: "取消",
  confirm: "确定",
  logout: "退出登录",
  companyManage: "企业管理",
  company: {
    hasBound: "已经被以下企业绑定,您可以点击进入企业工作台",
    personalVersion: "个人版",
    switchAccount: "切换企业",
  },
};
