import qs from "qs";

export const getUrlParams = (url: string) => {
  const index = url.indexOf("?");
  if (index === -1) return {};
  const params = url.slice(index + 1);
  return qs.parse(params);
};

export function setBrowserUrl(queryObj: any) {
  // stringify是queryString的一个api，具体可以查看node官网，也可以自己实现

  let paramObj: any = getUrlParams(window.location.href);

  paramObj = {
    ...paramObj,
    ...queryObj,
  };

  let url = `${window.location.pathname}?${qs.stringify(paramObj)}`;
  window.history.replaceState({ url: url }, "", url);
}

export function deleteBrowserUrl(queryObj: any) {
  let paramObj: any = getUrlParams(window.location.href);

  for (let i in queryObj) {
    if (paramObj[i]) {
      delete paramObj[i];
    }
  }

  let url = `${window.location.pathname}?${qs.stringify(paramObj)}`;
  window.history.replaceState({ url: url }, "", url);
}

export function deleteAllQuery() {
  let url = window.location.pathname;
  window.history.replaceState({ url: url }, "", url);
}
