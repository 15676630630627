import ChatBox from "@/components/ChatBox";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { mainQuestionList } from "@/apis/common";
import { useEffect, useState } from "react";
import useConfigHooks from "@/hooks/useConfigHooks";
import style from "./index.module.scss";
import superAssistantChatBg from "@/assets/common/superAssistantChatBg.svg";

const HomeIcon = createImageUrl('common/knowledge-agent.png');

const Home = () => {
  const [homeQuestionList, setHomeQuestionList] = useState<any[]>([]);

  const { config } = useConfigHooks();

  useEffect(() => {
    getMainQuestionList();
  }, []);

  const getMainQuestionList = async () => {
    setHomeQuestionList([]);
    mainQuestionList({
      size: 4,
    }).then((res: any) => {
      if (res && res.data && res.data.status === 200) {
        setHomeQuestionList(res.data.data);
      }
    });
  };

  const refresh = () => {
    getMainQuestionList();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 64px)",
        width: "100%",
        minWidth: "850px",
        backgroundImage: `url(${superAssistantChatBg})`,
              backgroundSize: "cover",
      }}
      className={style.page}
    >
      <div
        style={{
          //   width: "50vw",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "calc(100vh - 64px)",
        }}
      >
        <ChatBox
          mode="pub_agent"
          agentId={config.homepage_agent_id}
          pubAgentId={config.homepage_agent_id}
          topicId={-2}
          boxStyle="ai_search"
          homeQuestionList={homeQuestionList}
          getMainQuestionList={getMainQuestionList}
          history={
            [
              // {
              //   id: -1,
              //   agent_id: 10,
              //   role: 2,
              //   content: "我是AI助手",
              //   create_time: new Date(),
              //   update_time: new Date(),
              // },
            ]
          }
          botAvator={getImageUrl(HomeIcon)}
          botName={"Syngents AI搜索"}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default Home;
