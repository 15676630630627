import { useEffect, useState } from "react";
import { getArticleList } from "@/apis/common";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Styles from "./index.module.scss";
import { Pagination } from "antd";
import ArticleItem from "./ArticleItem";

const FrontPosition = () => {
  const [articleList, setArticleList] = useState(new Array(10).fill(undefined));

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArticleListInfo();
  }, [pageNo]);

  const getArticleListInfo = () => {
    setLoading(true);
    getArticleList({
      page_number: pageNo,
      page_size: pageSize,
    }).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        setArticleList(res.data.data);
      }
    });
  };

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  return (
    <div className={Styles.article}>
      {articleList.map((item, index) => {
        return (
          <div className={Styles.article_item} key={index}>
            {!loading && item ? (
              <ArticleItem articleItemData={item} />
            ) : (
              <Skeleton width="100%" height={100} />
            )}
          </div>
        );
      })}

      <div className={Styles.pagination}>
        <Pagination
          pageSize={pageSize}
          current={pageNo}
          total={total}
          showSizeChanger={false}
          // hideOnSinglePage
          onChange={changePagination}
        />
      </div>
    </div>
  );
};

export default FrontPosition;
