import axios from "axios";
import { message } from "antd";
import baseURL from "./getBaseURL";
import Cookies from "js-cookie";
import store, { loginOption } from "@/store";
let instance = axios.create({
  baseURL,
  timeout: 15000, // 毫秒
});

const onLoginAndRegister = () => {
  store.dispatch(loginOption.openModal());
};

// 设置请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token") || Cookies.get("token");
    if (!config.url?.includes("/users/login")) {
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
    }
    // console.log(config) // 该处可以将config打印出来看一下，该部分将发送给后端（server端）
    // config.headers.Authorization = token;
    return config; // 对config处理完后返回，下一步将向后端发送请求
  },
  (error) => {
    // 当发生错误时，执行该部分代码
    // console.log(error) // 调试用
    return Promise.reject(error);
  }
);

function getTopLevelAndSecondLevelDomain(hostname: any) {
  // 分割主机名
  const parts = hostname.split(".");
  // 确保至少有三部分（例如 sub.example.com）
  if (parts.length > 2) {
    // 提取最后两部分作为顶级域名和二级域名
    return parts.slice(-2).join(".");
  }
  // 如果主机名部分不足，返回原始主机名
  return hostname;
}
const hostname = window.location.hostname;
const topLevelAndSecondLevelDomain = getTopLevelAndSecondLevelDomain(hostname);

// 定义响应拦截器 -->token值无效时,清空token,并强制跳转登录页
instance.interceptors.response.use(
  function (response) {
    // 响应状态码为 2xx 时触发成功的回调，形参中的 response 是“成功的结果”
    if (
      response.config.url?.includes("/users/email/checkcode") ||
      response.config.url?.includes("/users/login/")
    ) {
      if (response.data.access) {
        localStorage.setItem("token", response.data.access);
        Cookies.set("token", localStorage.getItem("token") as string, {
          domain: `${topLevelAndSecondLevelDomain}`,
        });
      }
    }

    if (
      (response.data && response.data.status === 200) ||
      response.config.url?.includes("/users/email/checkcode") ||
      response.config.url?.includes("/users/login/")
    ) {
    } else {
      message.error(response.data.message || response.data.msg);
    }

    return response;
  },
  function (error) {
    // 响应状态码不是 2xx 时触发失败的回调，形参中的 error 是“失败的结果”
    if (error.response && error.response.status === 401) {
      // 无效的 token
      // 把 localstorage 中的 token 重置为空
      onLoginAndRegister();

      if (error.request.responseURL.includes("/users/login/")) {
        message.error("登录失败, 请检查用户名和密码是否正确");
      } else {
        // message.error("登录过期, 请重新登录");
      }
      localStorage.removeItem("token");
      Cookies.set("token", "");
    }

    return Promise.resolve(error);
  }
);

export default instance;
