import { Layout, QRCode, message, Menu, Button } from "antd";
import { useEffect, useState, useCallback, useRef } from "react";
// import header from "@/common/headerbar";
import LogoLight from "@/assets/common/header-logo.svg";
import langList from "@/common/langList";
import wechatQRCode from "@/assets/header/wechat-qrcode.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./index.module.scss";
import classnames from "classnames";
import { debounce } from 'lodash';
import { Select, Popover } from "antd";
import { useTranslation } from "react-i18next";
import useThemeHooks from "@/hooks/useThemeHooks";
// import { FeedbackFish } from "@feedback-fish/react";
import useLoginHooks from "@/hooks/useLoginHooks";
import store, { loginOption, selectCompanyModalOption } from "@/store";
import unLogin from "@/assets/menu/unLogin.svg";
import Cookies from "js-cookie";
import useCompanyInfoHooks from "@/hooks/useCompanyHooks";
import { useSearchParams } from "react-router-dom";
import enshrinePic from "@/assets/common/enshrine.svg";
import enshrineActivePic from "@/assets/common/enshrineActive.svg";
import {
  addPubAgent,
  deletePubAgent,
  toGetAgentPublicInfo,
  toGetAgentInfo,
  getWechatQRCodeApi
} from "@/apis/common";
import qs from "qs";
import { createImageUrl, getImageUrl, isMobile } from "@/utils/common";

const LogoDark = createImageUrl("common/header-logo-dark.png");
const Avatar = createImageUrl("menu/avator.png");
const agentBg = createImageUrl("common/agent-bg.png");

function getTopLevelAndSecondLevelDomain(hostname: any) {
  // 分割主机名
  const parts = hostname.split(".");
  // 确保至少有三部分（例如 sub.example.com）
  if (parts.length > 2) {
    // 提取最后两部分作为顶级域名和二级域名
    return parts.slice(-2).join(".");
  }
  // 如果主机名部分不足，返回原始主机名
  return hostname;
}

const { Header } = Layout;
const { Option } = Select;

const HeaderBar = () => {
  let navigate = useNavigate();

  const { isLogin, username, isUserInfoLoading } = useLoginHooks();

  const { is_company_admin, company_name, company_logo } =
    useCompanyInfoHooks();

  const { t, i18n } = useTranslation();
  const location = useLocation();

  const isShareRoute = location.pathname === '/share';

  const [themetype, setThemeType] = useState(
    localStorage.getItem("theme") || "dark"
  );

  const hostname = window.location.hostname;
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  const [lang, setLang] = useState(i18n.language);

  const [params] = useSearchParams();
  const isDialogue = Boolean(params.getAll("isDialogue")?.[0] || false);
  const publicId = Number(params.getAll("publicId")?.[0]) || 0;
  const agentId = Number(params.getAll("agentId")?.[0]) || 0;
  const email = params.getAll("email")?.[0] || "";
  const type = params.getAll("type")?.[0] || "";
  const [collect, setCollect] = useState(!!params.getAll("isCollect")?.[0]);
  const [chatInfo, setChatInfo] = useState({
    id: 0,
    name: "",
    create_time: "",
    avatar_url: "",
  });
  const theme = useThemeHooks();

  const themeList = [
    {
      value: "light",
      label: "白天",
    },
    {
      value: "dark",
      label: "黑夜",
    },
  ];

  const changeTheme = () => {
    if (themetype === "dark") {
      localStorage.setItem("theme", "light");
      document.querySelectorAll("html")[0].setAttribute("theme", "light");
    } else {
      localStorage.setItem("theme", "light");
      document.querySelectorAll("html")[0].setAttribute("theme", "light");
    }
    setThemeType(themetype === "dark" ? "light" : "light");
  };

  const changeLanguage = (item: any) => {
    setLang(item.value);
    i18n.changeLanguage(item.value);
    window.location.reload();
  };

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const logout = (type: string) => {
    Cookies.set("token", "", {
      domain: `${topLevelAndSecondLevelDomain}`,
    });

    localStorage.removeItem("token");

    window.location.reload();
  };

  // 收藏逻辑
  const collectAction = () => {
    if (!collect) {
      addPubAgent({ pub_agent_id: chatInfo.id, show_mode: 1 }).then((res) => {
        if (res && res.data && res.status === 200) {
          setCollect(true);
        }
      });
    } else {
      deletePubAgent({ pub_agent_ids: [chatInfo.id], show_mode: 1 }).then(
        (res) => {
          if (res && res.data && res.status === 200) {
            setCollect(false);
          }
        }
      );
    }
  };

  const createSame = () => {
    localStorage.setItem("createAgentInfo", "");
    let api = toGetAgentPublicInfo;
    if (type === "private") {
      api = toGetAgentInfo;
    }
    api({
      id: chatInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          const createAgentInfo = res.data.data;

          localStorage.setItem(
            "createAgentInfo",
            JSON.stringify(createAgentInfo)
          );

          if (type === "private") {
            let data = {
              step: "create",
            };
            navigate(`/workflow?${qs.stringify(data)}`);
          } else {
            navigate(
              `/workflow?${qs.stringify({
                step: "create",
                publicId: chatInfo.id,
                type: "public",
              })}`
            );
          }
        }
      }
    });
  };

  const getInfo = () => {
    let api = toGetAgentPublicInfo;
    if (type === "private") {
      api = toGetAgentInfo;
    }
    api({
      id: type === "private" ? Number(agentId) : Number(publicId),
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          setChatInfo(res.data.data);
        }
      }
    });
  };
  useEffect(() => {
    if (isDialogue) {
      getInfo();
    }
  }, []);

  const switchAccount = () => {
    store.dispatch(selectCompanyModalOption.openSelectCompanyModal());
  };

  const languagePopover = () => {
    return (
      <div className={Styles.language_popover_inside}>
        {langList.map((item, index) => {
          return (
            <div
              className={classnames(
                Styles.language_popover_inside_item,
                lang === item.value
                  ? Styles.language_popover_inside_item_active
                  : ""
              )}
              onClick={() => {
                changeLanguage(item);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    );
  };

  const WechatQRCode = () => {
    const [isShowQRCode, setIsShowQRCode] = useState(false);
    const [QRCodeUrl, setQRCodeUrl] = useState("");

    //获取微信群聊二维码
    const handleMouseEnterAddWechat = async (e: any) => {
      setIsShowQRCode(true);
      e.preventDefault();
      try {
        const res = await getWechatQRCodeApi({
          keys: ["wechat_group_qr_url"],
        });
        if (res.data?.status === 200 && res.data?.data?.wechat_group_qr_url) {
          setQRCodeUrl(res.data.data.wechat_group_qr_url);
        } else {
          setIsShowQRCode(false);
          message.error("获取微信二维码失败!");
        }
      } catch (error) {
        // 获取失败取消loading code 展示
        setIsShowQRCode(false);
        message.error("获取微信二维码失败!");
      }
    };

    const debouncedHandleMouseEnter = useCallback(debounce(handleMouseEnterAddWechat, 300), []);
    const debouncedHandleMouseLeave = useCallback(debounce(() => {
      setIsShowQRCode(false);
      setQRCodeUrl("");
    }, 300), []);

    return (
      <div
        className={classnames(Styles.addWechat, { [Styles.hasLogin]: isLogin } )}
        onMouseEnter={debouncedHandleMouseEnter}
        onMouseLeave={debouncedHandleMouseLeave}
      >
        <img
          className={Styles.wechatQRCode}
          loading="lazy"
          src={wechatQRCode || ""}
        />
        一起共创有AI未来
        {
          // 点击获取按钮展示loading code(网慢),获取成功展示link code
          isShowQRCode && (
            <div className={Styles.QRCodeContent}>
              {QRCodeUrl ? (
                <QRCode
                  color={theme === "dark" ? "#fff" : "#272b30"}
                  size={120}
                  value={QRCodeUrl}
                  bordered={false}
                ></QRCode>
              ) : (
                <QRCode
                  color={theme === "dark" ? "#fff" : "#272b30"}
                  size={120}
                  value="loading"
                  bordered={false}
                  status="loading"
                ></QRCode>
              )}
              扫码加入交流群
            </div>
          )
        }
      </div>
    );
  };

  const userPopover = () => {
    return (
      <div className={Styles.userWrap}>
        <div className={Styles.userInfoWrap}>
          {company_logo && (
            <img src={company_logo} className={Styles.companyIcon}></img>
          )}
          <div className={Styles.nameWrap}>
            <div className={Styles.userName}>{username}</div>
            {company_name && (
              <div className={Styles.companyName}>{company_name}</div>
            )}
          </div>
        </div>
        {is_company_admin && (
          <div
            className={Styles.logout}
            onClick={() => {
              navigate(`/enterprise`);
            }}
          >
            {/* <img src={SettingIcon}></img> */}
            <div className={classnames( Styles.img,"iconfont","icon-size20","icon-manage_line")}></div>
            {t("companyManage")}
          </div>
        )}
        <div
          className={Styles.logout}
          onClick={() => {
            switchAccount();
          }}
        >
          <div className={classnames( Styles.img,"iconfont","icon-size20","icon-switch_line")}></div>
          {t("company.switchAccount")}
        </div>

        <div
          className={Styles.logout}
          onClick={() => {
            logout("logout");
          }}
        >
          <div className={classnames( Styles.img,"iconfont","icon-size20","icon-exit_line")}></div>
          {t("logout")}
        </div>
      </div>
    );
  };

  const headerRender = () => {
    return (
      <div className={Styles.header}>
        <div className={Styles.left_box}>
          <div
            className={Styles.logo_box}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              className={Styles.logo}
              src={theme === "dark" ? LogoDark : LogoLight}
              loading="lazy"
            ></img>
            {/* <span className={Styles.title}>{t("common.product.name")}</span> */}
          </div>
        </div>
        <div className={Styles.right_box}>
        <WechatQRCode />
          {/* <img className={Styles.right_icon} src={MessagePic}></img> */}
          {/* <Popover
      content={languagePopover}
      placement="bottom"
      className={Styles.language_popover}
    >
      <img
        className={Styles.right_icon}
        src={theme === "dark" ? LanguagePic : LanguageLightPic}
      ></img>
    </Popover> */}

          {/* <img
      className={Styles.right_icon}
      src={theme === "dark" ? MoonPic : MoonLightPic}
      onClick={() => {
        changeTheme();
      }}
    ></img> */}
          {isLogin ? (
            <div
              className={classnames(
                Styles.myCollection,
                "iconfont",
                "icon-size24",
                "icon-star_line"
              )}
              onClick={() => navigate("/aiteam")}
            >
              <span style={{fontSize:"14px"}}>我的收藏</span>
            </div>
          ) : null}

          {isLogin ? (
            <Popover
              content={userPopover}
              placement="bottomLeft"
              className={Styles.language_popover}
            >
              <img
                className={Styles.user_icon}
                src={isLogin ? Avatar : unLogin}
              ></img>
            </Popover>
          ) : (
            !isUserInfoLoading && (
              <div onClick={onLoginAndRegister}>
                <span className={Styles.login}>{t("common.login")}</span>
              </div>
            )
          )}

          {/* <FeedbackFish projectId="fa01aa098d108b">
      <div>Send feedback</div>
    </FeedbackFish> */}
          {/* <div className={Styles.theme_box}>
      <Select
        className={Styles.theme_select}
        onChange={(value) => {
          changeTheme(value);
        }}
        value={themetype}
      >
        {themeList.map((item) => {
          return <Option key={item.value}>{item.label}</Option>;
        })}
      </Select>
    </div> */}

          {/* <div className={Styles.lang_box}>
      <Select
        className={Styles.lang_select}
        onChange={(value) => {
          setLang(value);
          i18n.changeLanguage(value);
          window.location.reload();
        }}
        value={lang}
      >
        {langList.map((item) => {
          return (
            <Option value={item.value} key={item.value}>
              {item.label}
            </Option>
          );
        })}
      </Select>
    </div> */}
        </div>
      </div>
    );
  };

  const chatRender = () => {
    return (
      <div className={Styles.header}>
        <div className={Styles.left_box}>
          <img
            className={Styles.avatar_url}
            src={getImageUrl(chatInfo?.avatar_url) || agentBg}
            loading="lazy"
          ></img>
          <div className={Styles.left_box_info}>
            <div>{chatInfo?.name}</div>
            <div>
              {email} <span></span> 发布于{chatInfo?.create_time}
            </div>
          </div>
        </div>
        <div className={Styles.right_box}>
          {type !== "private" ? (
            <Button
              className={classnames(
                Styles.right_box_btn_left,
                collect ? Styles.right_box_btn_left_ac_btn : ""
              )}
              onClick={() => {
                collectAction();
              }}
            >
              <img src={collect ? enshrineActivePic : enshrinePic} alt="" />
              <span className={collect ? Styles.right_box_btn_left_ac : ""}>
                {collect ? "已经加入团队" : "加入团队"}
              </span>
            </Button>
          ) : (
            ""
          )}
          <Button
            type="primary"
            className={Styles.right_box_btn}
            onClick={() => {
              createSame();
            }}
          >
            创建同款
          </Button>
        </div>
      </div>
    );
  };
if(isMobile || isShareRoute) {
  return null;
}
  return (
    <Header className={Styles.header_box}>
      {isDialogue ? chatRender() : headerRender()}
    </Header>
  );
};

export default HeaderBar;
