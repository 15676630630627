import { createSlice } from "@reduxjs/toolkit";

type IuserInfo = {
  id: number | string;
};

export const userInfoSlice = createSlice({
  name: "counter",
  initialState: {
    baseInfo: {
      id: 0,
      isUserInfoLoading: false,
      token: "",
    },
  },
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
    updateUserInfo: (state, action) => {
      state.baseInfo = { ...state.baseInfo, ...action.payload };
    },

    updateToken: (state, action) => {
      state.baseInfo.token = action.payload;
    },
  },
});

export const { updateUserInfo, updateToken } = userInfoSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (userInfo: IuserInfo) => (dispatch: any) => {
  setTimeout(() => {
    dispatch(updateUserInfo(userInfo));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getId = (state: any) => state.userInfo.id;

export default userInfoSlice.reducer;
