export default {
  "common.welcome": "AIエージェントへログイン",
  "common.password": "パスワード：",
  "common.password.confirm": "パスワード確認：",
  "common.userName": "ユーザー名：",
  "common.login": "ログイン",
  "common.login.success": "ログイン成功",
  "common.register": "登録",
  "common.register.success": "登録成功",
  "common.register.title": "アカウント登録",
  "common.product.name": "新しい知識体",
  "common.user.placeHolder": "ユーザー名を入力してください！",
  "common.password.placeHolder": "パスワードを入力してください！",
  "common.seeking": "探索",
  "common.chat": "チャット",
  "common.canvas": "キャンバス",
  "common.community": "コミュニティ",
  "common.create": "エージェント作成",
  "common.myAgent": "私のエージェント",
  "common.publish": "エージェント公開",
  "common.submit": "送信",
  "common.send": "送信",
  "common.tags": "タグ",
  "common.publish.time": "公開日：",
  "common.update.time": "最終更新：",
  "common.join.chat": "チャット参加",
  "common.create.same": "同様の作成",
  "common.agent.singal": "単一エージェント",
  "common.back": "戻る",
  "common.chat.default.greetings": "今日、何をお手伝いできますか？",
  "common.you": "あなた",
  "common.edit": "編集",
  "common.category": "カテゴリー",
  "common.yes": "はい",
  "common.no": "いいえ",
  "common.category.recent": "最近",
  "common.category.work": "仕事",
  "common.category.learning": "学習",
  "common.category.lifestyle": "ライフスタイル",

  "create.dialog.agent.desc":
    "エージェントの作成を始め、直接作成またはエージェントのホームページに移動できます",
  "create.normalmode": "通常モード",
  "create.professionalmode": "専門モード",
  "create.name": "エージェント名",
  "create.name.placeholder": "エージェント名を入力してください",
  "create.introduce": "エージェント紹介",
  "create.introduce.placeholder": "エージェント紹介を入力してください",
  "create.provider": "モデルプロバイダー",
  "create.provider.placeholder": "モデルプロバイダーを選択してください",
  "create.clue.edit": "手がかりの編集",

  "create.clue.placeholder": "手がかりの編集タイプを選択してください",
  "create.clue.sender": "送信者",
  "create.humanInput.placeholder": "送信者の形式を選択してください",
  "create.clue.receiver": "受信者",
  "create.agent.type": "エージェントタイプ",
  "create.repository.upload": "ナレッジベースのアップロード",
  "create.repository.greetings": "挨拶",
  "create.agent.tools": "エージェントツール",
  "create.reply.count": "最大自動連続返信数：",
  "create.input.format": "入力フォーマット",
  "create.input.prompts": "システム入力プロンプト",
  "create.emotional.temperature": "感情の温度：",
  "create.image.create": "画像生成",
  "create.info.search": "情報検索",
  "create.image.desc":
    "「宇宙服を着た猫が月面を歩いている」といった説明を入力すると、AIはその説明に基づいて対応する画像を生成します。この技術は通常、複雑なアルゴリズムと大量の画像データを使用してトレーニングされ、テキスト情報を視覚的なコンテンツに変換できるようになります。この機能は、芸術制作、製品デザイン、エンターテイメントなど、さまざまな創造的な領域で活用できます。",
  "create.preview.title": "デバッグとプレビュー",
  "create.singal.agent": "単一エージェント",
  "create.muti.agent": "複数エージェント",
  "create.to.detail": "私が作成したエージェントを表示",
  "create.conversation": "対話カテゴリー",
  "create.image.cover": "背景画像",
  "create.upload": "ファイルをアップロード",
  "create.greeting.placeholder": "挨拶を入力してください",
  "create.upload.tips":
    "サポートされているファイル形式：'txt'、'json'、'csv'、'tsv'、'md'、'html'、'htm'、'rtf'、'rst'、'jsonl'、'log'、'xml'、'yaml'、'yml'、'pdf'、各ファイルのサイズ制限は10MB未満",
  "create.last.updatetime": "最終保存日：",
  "create.category.placeholder": "カテゴリーを選択してください",

  "publish.setting.title": "公開設定",
  "publish.market.title": "マーケットに公開",
  "publish.market.desc":
    "ユーザーはエージェントマーケットで公開されたエージェントを見つけ、他のユーザーに公開できます",
  "publish.api.title": "APIを生成",
  "publish.api.desc": "ユーザーはAPIを生成してさらなる開発が可能です",
  "publish.link.title": "公開リンクを生成",
  "publish.link.desc":
    "ユーザーはウェブページを介してエージェントに直接アクセスできます",
  "publish.third.title": "サードパーティをバインド",
  "publish.third.desc":
    "ユーザーはサードパーティのアプリをバインドでき、設定が完了するとそのアプリで使用できます",
  "publish.dialog.title": "公開情報の設定",
  "publish.dialog.style": "公開スタイルを選択",
  "publish.dialog.stylePlaceHolder": "公開スタイルを選択してください",
  "publish.dialog.permission": "公開権限の設定",
  "publish.dialog.toPlace": "エージェントプラザに公開",
  "publish.dialog.toDashBoard": "個人センターにのみ公開",
  "publish.dialog.edit.permission": "編集権限の設定",
  "publish.dialog.viewOnly": "閲覧のみ",
  "publish.dialog.editView": "編集と閲覧",
  "publish.dialog.Agreement": "「エージェントプラットフォーム作成契約」を読む",
  "publish.copy.success": "コピー成功",
  "publish.recommand.tags": "おすすめのタグ",
  "publish.question.example": "質問例",
  "publish.editable": "編集可能",
  "publish.tags.placeholder": "タグを選択してください",
  "publish.btntext": "マーケットに公開",
  "publish.agreement": "公開は、ご利用規約に同意したことを意味します",
  "publish.policy": "「Syngents.cnのコンテンツポリシー」",
  "publish.last.time": "最終公開日：",
  chat: {
    withModal: "大規模モデルとの対話",
    withAgent: "エージェントリスト",
    input: {
      placeholder: "知りたい内容を入力してください",
    },
    delete: {
      title: "対話を削除しますか？",
      content: "“<0>{{name}}</0>” を削除しますか？",
    },
  },
  "community.explore": "探索",
  "community.frontier": "AIフロンティア",
  "community.cameby": "訪れた人数",
  "community.member": "メンバー",
  "community.online": "オンライン",
  "community.watched": "閲覧数",
  "community.share": "共有数",

  myagent: {
    app: "アプリ",
    repository: "ナレッジベース",
    tools: "ツール",
    newagent: "新しいエージェントを作成",
    subtotal: {
      left: "合計エージェント数：",
      right: "エージェントが作成されました",
    },
    delete: {
      title: "エージェントを削除",
      content: 'このエージェントを削除してもよろしいですか？ "<0>{{name}}</0>"',
      success: "削除が成功しました",
      successText:
        '"<0>{{name}}</0>" はマイエージェントリストから正常に削除されました！',
      fail: "削除に失敗しました",
      failText:
        "削除に失敗しました。後でもう一度試すか、管理者に連絡してください。",
    },
  },

  "explore.tab.tool": "マルチモーダルツール",
  "explore.tab.analysis": "研究分析",
  "explore.tab.writing": "プロの執筆",
  "explore.tab.coding": "コーディング",
  "explore.tab.prompt": "プロンプトワード",
  "explore.tab.assistant": "アシスタント",
  "explore.tab.entertainment": "エンターテインメントとリラックス",
  privacy_policy: {
    title: "Syngents.cnサービス利用規約",
    introduction: "はじめに",
    welcome_message:
      "Syngents.cnプラットフォームへようこそ（以下、「Syngents.cn」または「私たち」といいます）。これらのサービス利用規約（以下、「規約」といいます）は、Syngents.cnが提供するウェブサイト、モバイルアプリケーション、およびその他関連製品およびサービス（以下「サービス」といいます）へのアクセスおよび利用を規定しています。本サービスへのアクセスまたは利用により、これらの規約および当社のプライバシーポリシーに同意したものとみなされます。",
    account_registration_and_security: "アカウント登録とセキュリティ",
    provide_accuate_information:
      "アカウントを作成するために真実、正確、かつ完全な情報を提供する必要があります。",
    responsibility_of_account:
      "アカウントで発生したすべての活動について責任を負います。",
    right_of_refusal:
      "Syngents.cnは、特にこれらの規約に違反した場合には、サービスを拒否し、アカウントを終了し、コンテンツを削除する権利を有します。",
    user_content: "ユーザーコンテンツ",
    retain_ownership:
      "Syngents.cnを介して提出されたコンテンツの所有権は保持されますが、Syngents.cnには非排他的、無償、世界的、永続的で完全なサブライセンス可能な権利が与えられます。",
    declare_and_guarantee:
      "提出されたコンテンツのすべての権利を所有しているか、必要な許可を取得していることを表明し保証します。",
    user_behavior: "ユーザー行動",
    agreement_of_behavior:
      "以下の行動についてサービスを使用しないことに同意します。",
    prohibited_behaviors_string: `{
  "A": "名誉を傷つけたり、わいせつ、ポルノ、下品、冒涜的、人種差別的なコンテンツを投稿または配布しないでください。",
  "B": "知的財産権、営業秘密、プライバシー権その他の所有権を侵害しないでください。",
  "C": "ウイルス、トロイの木馬、スパイウェアその他破壊的なコードを含むコンテンツを提出しないでください。",
  "D": "不正な広告、スパム、チェーンメール、その他の形式の不正な勧誘、宝くじまたは賭博のいずれかの形式の投稿をしないでください。",
  "E": "虚偽または誤解を招く情報を提供したり、他人やエンティティをなりすますことはありません。",
  "F": "著作権法、商標法、プライバシー法、スパム防止法を含む適用される法律または規制に違反しないでください。",
  "G": "違法、有害、暴力的な活動を促進しないでください。",
  "H": "他のユーザーを嫌がらせ、脅迫、いじめ、欺く、または他の方法で邪魔しないでください。",
  "I": "サービスの手続き、ポリシー、または規則に違反しないでください。",
  "J": "サービスまたは関連ネットワークの正常な動作を妨げたり、妨害したりしないでください。",
  "K": "承認されていないサービス、他のアカウント、または接続されたコンピューターシステムやネットワークにアクセスしないでください。",
  "L": "ロボット、スパイダー、スクレーピングツール、または他の自動化された方法を使用してサービスにアクセスしないでください。",
  "M": "ソースコードを取得しようとするリバースエンジニアリング、逆コンパイル、逆アセンブル、または他の方法を試みないでください。",
  "N": "サービスの完全性を変更、適応、クラック、またはその他の方法で破壊しないでください。",
  "O": "Syngents.cnの明示的な書面による許可なしに、サービスの任意の部分をコピー、複製、販売、再販、または利用しないでください。"
}`,
    prohibited_behaviors: {
      A: "名誉を傷つけたり、わいせつ、ポルノ、下品、冒涜的、人種差別的なコンテンツを投稿または配布しないでください。",
      B: "知的財産権、営業秘密、プライバシー権その他の所有権を侵害しないでください。",
      C: "ウイルス、トロイの木馬、スパイウェアその他破壊的なコードを含むコンテンツを提出しないでください。",
      D: "不正な広告、スパム、チェーンメール、その他の形式の不正な勧誘、宝くじまたは賭博のいずれかの形式の投稿をしないでください。",
      E: "虚偽または誤解を招く情報を提供したり、他人やエンティティをなりすますことはありません。",
      F: "著作権法、商標法、プライバシー法、スパム防止法を含む適用される法律または規制に違反しないでください。",
      G: "違法、有害、暴力的な活動を促進しないでください。",
      H: "他のユーザーを嫌がらせ、脅迫、いじめ、欺く、または他の方法で邪魔しないでください。",
      I: "サービスの手続き、ポリシー、または規則に違反しないでください。",
      J: "サービスまたは関連ネットワークの正常な動作を妨げたり、妨害したりしないでください。",
      K: "承認されていないサービス、他のアカウント、または接続されたコンピューターシステムやネットワークにアクセスしないでください。",
      L: "ロボット、スパイダー、スクレーピングツール、または他の自動化された方法を使用してサービスにアクセスしないでください。",
      M: "ソースコードを取得しようとするリバースエンジニアリング、逆コンパイル、逆アセンブル、または他の方法を試みないでください。",
      N: "サービスの完全性を変更、適応、クラック、またはその他の方法で破壊しないでください。",
      O: "Syngents.cnの明示的な書面による許可なしに、サービスの任意の部分をコピー、複製、販売、再販、または利用しないでください。",
    },
    intellectual_property: "知的財産",
    protection_of_intellectual_property:
      "Syngents.cnのコンテンツ、ソフトウェア、商標は法的に保護されており、許可なしに使用することはできません。",
    acknowledge_and_agree:
      "Syngents.cnまたは第三者の知的財産権を侵害しないことを認識し、同意します。",
    disclaimer_and_limitation_of_liability: "免責事項と責任制限",
    service_provided_as_is:
      "サービスは「現状のまま」提供され、Syngents.cnはいかなる中断、エラー、またはユーザーデータの損失に対しても責任を負いません。",
    no_liability_for_damage:
      "いかなる場合も、利益、善意、利用損失、データ損失、またはその他の無形の損失を含む、直接、間接、特別、結果的または懲罰的な損害についてSyngents.cnは一切責任を負いません。",
    changes_to_terms: "条件の変更",
    right_to_modify_terms:
      "Syngents.cnはいつでもこれらの条件を変更でき、ウェブサイトまたはメールで通知します。",
    acceptance_of_new_terms:
      "サービスの継続的な使用は、新しい条件に同意したことを示します。新しい条件に同意しない場合は、サービスの使用を停止する必要があります。",
    applicable_law_and_dispute_resolution: "適用法と紛争解決",
    governing_law:
      "これらの条件は、Syngents.cnが運営する管轄区域の法律に準拠します。",
    dispute_resolution:
      "紛争はまず協議によって解決され、解決しない場合は仲裁に提出されるべきです。",
    miscellaneous: "その他",
    invalid_terms:
      "これらの条件のいずれかの部分が執行不能または無効である場合、その部分は必要最小限に制限または除外されます。",
    no_assignment:
      "これらの条件またはこれらの条件の下で付与された権利および許可を譲渡または移転することはできません。",
    contact_information: "連絡先情報",
    contact_us:
      "これらの条件に関するご質問やご不明な点がございましたら、<0>{{email}}</0>までお問い合わせください。",
  },
  workflow: {
    fileName: "ファイル名",
  },
  status: "ステータス",
  size: "サイズ",
  action: "アクション",
};
