import Styles from "./index.module.scss";
import useThemeHooks from "@/hooks/useThemeHooks";
import EmptyPic from "@/assets/myAgent/empty-dark.svg";
import EmptyLightPic from "@/assets/myAgent/empty.svg";
import { useTranslation, Trans } from "react-i18next";

const NoContent = () => {
    const { t } = useTranslation();
    const theme = useThemeHooks();
    return (
        <div className={Styles.emptyWrap}>
            <img
                src={theme === "dark" ? EmptyPic : EmptyLightPic}
                className={Styles.empty_img}
            ></img>
            {t("myagent.empty")}
        </div>
    )
};

export default NoContent;