import { Modal, Input, Form, Button, message } from "antd";
import React, { useEffect, useState, useRef } from "react";
import Styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { enterpriseFieldType } from "@/types/common";
import _ from "lodash";
import { inviteMember } from "@/apis/common";
import CodeModal from "@/components/CodeModal";

export type IAddModel = {
  showAddModel: boolean;
  closeAddModal: (showCode: boolean) => void;
  handleMessage: () => void;
  companyId:number
};

const CodeAddModal = ({
  showAddModel,
  closeAddModal,
  handleMessage,
  companyId
}: IAddModel) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const handleCancel = () => {
    closeAddModal(false);
  };

  const onFinishFailed = () => {};

  const closeModal = () => {
    setShowCode(false);
    handleCancel();
  };

  const onFinish = (data: any) => {
    setLoading(true);
    inviteMember({
      company_id: companyId,
      ...data,
    }).then((res) => {
      setLoading(false);
      if (res?.data?.status === 200) {
        handleCancel();
        handleMessage();
        message.success(t("common.member.added"));
      }
      if (res?.data?.status === 40501) {
        setShowCode(true);
      }
    });
  };

  return (
    <Modal
      title={t("enterprise.add.title")}
      open={showAddModel}
      className={Styles.modal}
      onCancel={handleCancel}
      centered
      footer={null}
      width={560}
    >
      <div className={Styles.content}>
        <div className={Styles.title}>{t("enterprise.add.abstract")}</div>
        <div className={Styles.tip}>{t("enterprise.add.hint")}</div>
        <div className={Styles.form}>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item<enterpriseFieldType>
              name="telephone"
              className={Styles.form_item}
              rules={[
                {
                  required: true,
                  message: t("common.user.telephone"),
                  pattern: /^1[3456789]\d{9}$/,
                },
              ]}
            >
              <Input
                placeholder={t("login.inputPhone")}
                className={Styles.input}
              ></Input>
            </Form.Item>
            <Form.Item<enterpriseFieldType>
              className={Styles.form_item}
              name="user_name"
              rules={[{ required: true, message: t("common.user.name"),max:20 }]}
            >
              <Input placeholder={t("login.inputName")}></Input>
            </Form.Item>
            <div className={Styles.footer}>
              <Button
                onClick={handleCancel}
                className={Styles.footer_btn_cancel}
              >
                {t("cancel")}
              </Button>
              <Button
                className={Styles.footer_btn}
                htmlType="submit"
                loading={loading}
              >
                {t("common.affirm")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <CodeModal
        showCode={showCode}
        closeModal={closeModal}
        title={t("enterprise.title")}
        subTitle={t("enterprise.subTitle")}
        content={t("enterprise.content")}
      />
    </Modal>
  );
};

export default CodeAddModal;
