import { Select, Tag, message } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";

// 定义组件的Props接口
interface SelectToolProps {
  form: {
    setFieldValue: (field: string, value: any) => void;
    getFieldValue: (field: string) => any;
  };
  initialTags: string[];
}

/** 标签选择器 */
const SelectTool: React.FC<SelectToolProps> = ({ form, initialTags }) => {
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(
    form.getFieldValue("tags") || []
  );
  const formTags = form.getFieldValue("tags");

  // 处理标签改变事件
  const handleChange = (newSelectedTags: string[]) => {
    if (newSelectedTags.length > 4) {
      message.warning("最多只能添加四个标签");
      return; // 如果标签数量超过四个则不更新
    }
    // 移除超过20个字符的标签并发出警告
    const filteredTags = newSelectedTags.filter((tag) => {
      if (tag.length > 20) {
        message.warning("标签长度不能超过20个字符");
        return false;
      }
      return true;
    });

    setSelectedTags(filteredTags);
    form.setFieldValue("tags", filteredTags); // 更新表单字段值
    const newTags = _.difference(tags, filteredTags); // 获取新的标签
    setTags(newTags);
  };

  // 处理标签点击事件
  const handleClickTag = (tag: string) => {
    const updatedSelectedTags = _.cloneDeep(selectedTags); // 深度克隆选中的标签
    if (!updatedSelectedTags.includes(tag)) {
      updatedSelectedTags.push(tag);
    }
    handleChange(updatedSelectedTags); // 更新选中的标签
  };

  useEffect(() => {
    setTags(initialTags); // 当 initialTags 改变时，更新 tags 状态
  }, [initialTags]);

  useEffect(() => {
    setSelectedTags(form.getFieldValue("tags") || []);
  }, [formTags, form]);

  return (
    <div className={styles.select}>
      <Select
        mode='tags'
        style={{ width: "100%" }}
        placeholder='输入后按下回车形成标签~'
        onChange={(value) => handleChange(value as string[])} // 处理选择改变事件
        open={false} // 关闭可选项下拉框
        value={selectedTags} // 绑定选中的标签值
      />
      <div className={styles.tags}>
        {tags?.map((tag: string) => (
          <Tag key={tag} onClick={() => handleClickTag(tag)}>
            {" "}
            {/* 为每个标签添加点击事件 */}
            {tag}
          </Tag>
        ))}
      </div>
      <div className={styles.selectCount}>{`${selectedTags?.length} / 4`}</div>
    </div>
  );
};

export default SelectTool;
