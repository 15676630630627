import { useEffect, useRef, useState } from "react";
import Styles from "./index.module.scss";
import {
  toGetSeekingList,
  toGetTagData,
  likeStatus,
  toDeleteAgent,
} from "@/apis/common";
import Skeleton from "react-loading-skeleton";
import { Pagination, message, Alert } from "antd";
import "react-loading-skeleton/dist/skeleton.css";
import ToolItem from "@/components/ToolItems";
import { createImageUrl, unique } from "@/utils/common";
import { toGetUserNames } from "@/apis/common";
import { IEnterpriseProps } from "@/types/common";
import classnames from "classnames";
import CardItem from "@/components/CardItem";
import { useTranslation, Trans } from "react-i18next";
import ConfirmModal from "@/components/Modal";
import CodeModal from "@/components/CodeModal";

const emptyPic = createImageUrl('enterprise/empty.svg')

const Enterprise = ({ categoryType }: IEnterpriseProps) => {
  const SkeletonItem = () => {
    return (
      <div className={Styles.skeleton}>
        <Skeleton width="100%" height={200} />
        <div className={Styles.skeleton_info}>
          <div>
            <Skeleton width={30} height={30} borderRadius="50%" />
          </div>
          <div className={Styles.skeleton_info_name}>
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
        </div>
      </div>
    );
  };
  const { t } = useTranslation();
  const [data, setData] = useState(new Array(12).fill(undefined));
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [pageType, setPageType] = useState<number>(1);
  const [tagNameObj, setTagNameObj] = useState({});
  const [userNameObj, setUserNameObj] = useState({});
  const [likeStatusObj, setLikeStatusObj] = useState({});
  const [total, setTotal] = useState(0);
  const [deleteAgentName, setDeleteAgentName] = useState("");
  const [deleteAgentId, setDeleteAgentId] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [showCode, setShowCode] = useState(false);
  const [needUpdateData, setNeedUpdateData] = useState(false);
  const [category, setCategory] = useState<any>([
    {
      id: 1,
      name: t("common.platform.customization"),
    },
    {
      id: 2,
      name: t("common.member.publishing"),
    },
  ]);
  const ConfirmModalRef = useRef<any>(null);

  const getSeekingList = () => {
    setLoading(true);
    const param: any = {
      page_number: pageNo,
      page_size: pageSize,
      show_mode: categoryType,
      pub_agent_source: pageType,
      release_scope: 2, // 1:全平台 2:企业
    };
    setData(new Array(12).fill(undefined));
    toGetSeekingList(param).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data;
        setData(data);
        setTotal(res.data.total_items || []);

        let tagList: Array<number> = [];

        data.forEach((item: any, index: number) => {
          if (item.tags && item.tags.length) {
            tagList = [...tagList, ...item.tags];
          }
        });

        let userIdsList: Array<number> = [];
        let agentIdList: Array<number> = [];

        data.forEach((item: any, index: number) => {
          if (item.author) {
            userIdsList = [...userIdsList, item.author];
          }

          if (item && item.agent_id) {
            agentIdList = [...agentIdList, item.id];
          }
        });

        let newUserNameObj: any = {};
        let newLikeStatusObj: any = { ...likeStatusObj };

        if (agentIdList.length) {
          likeStatus({
            pub_agent_ids: agentIdList,
          }).then((res: any) => {
            if (res.data && res.data.status === 200) {
              res.data.data.forEach((item: any, index: number) => {
                newLikeStatusObj[item.pub_agent_id] = item;
              });
            }
          });

          setTimeout(() => {
            setLikeStatusObj(newLikeStatusObj);
          });
        }

        if (userIdsList.length) {
          toGetUserNames({ ids: unique(userIdsList) }).then((res: any) => {
            if (res.data && res.data.status === 200 && res.data.data.length) {
              res.data.data.forEach((item: any, index: number) => {
                newUserNameObj[item.id] = item.username;
              });

              setUserNameObj(newUserNameObj);
            }
          });
        }
        if (tagList.length) {
          toGetTagData({ ids: unique(tagList) }).then((res: any) => {
            if (res.data && res.data.status === 200 && res.data.data.length) {
              const tagObj: any = {};

              res.data.data.forEach((item: any, index: number) => {
                tagObj[item.id] = item.name;
              });
              setTagNameObj(tagObj);
            }
          });
        }
      }
    });
  };

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
    getSeekingList();
  };

  const onDelete = (agentInfo: any) => {
    setDeleteAgentName(agentInfo?.name);
    setDeleteAgentId(agentInfo?.id);
    ConfirmModalRef?.current?.showModal();
  };

  const deleteResult = (success = true) => {
    messageApi.open({
      content: (
        <Alert
          message={t(
            success ? "myagent.delete.success" : "myagent.delete.fail"
          )}
          description={
            success ? (
              <Trans
                i18nKey="myagent.delete.successText"
                values={{ name: deleteAgentName }}
              >
                {deleteAgentName}
              </Trans>
            ) : (
              t("myagent.delete.failText")
            )
          }
          type={success ? "success" : "error"}
          showIcon
          closable
        />
      ),
      className: Styles.customClass,
      duration: 3,
    });
  };

  const confirmFn = () => {
    toDeleteAgent({
      id: deleteAgentId,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        deleteResult();
        ConfirmModalRef?.current?.hideModal();
        getSeekingList();
        return;
      }
      deleteResult(false);
    });
  };

  useEffect(() => {
    setPageNo(1);
    getSeekingList();
  }, [pageType, categoryType]);;

  const closeModal = () => {
    setShowCode(false);
  };

  const agentRender = () => {
    return data?.map((item, index) => {
      return (
        <div
          className={
            categoryType === 1
              ? Styles.page_recommand_item
              : Styles.page_recommand_item_card
          }
          key={index}
        >
          {!loading && item ? (
            categoryType === 1 ? (
              <ToolItem
                agentInfo={item}
                tagNameObj={tagNameObj}
                userNameObj={userNameObj}
                likeStatusObj={likeStatusObj}
                collectSwitch={true}
                setLikeStatusObj={setLikeStatusObj}
              />
            ) : (
              <CardItem
                agentInfo={item}
                tagNameObj={tagNameObj}
                userNameObj={userNameObj}
                likeStatusObj={likeStatusObj}
                setLikeStatusObj={setLikeStatusObj}
                onDelete={onDelete}
                isSelfItem={false}
                collectSwitch={true}
                setNeedUpdateData={setNeedUpdateData}
              />
            )
          ) : (
            <SkeletonItem />
          )}
        </div>
      );
    });
  };

  const emptyRender = () => {
    return (
      <div className={Styles.empty_box_conent}>
        <img src={emptyPic} />
        <div className={Styles.empty_box_title}>
          {categoryType === 1
            ? t("common.custom.available")
            : t("common.custom.card")}
        </div>
        <div className={Styles.empty_box_content}>
          {t("common.business.need")}
          <span
            onClick={() => {
              setShowCode(true);
            }}
          >
            {t("common.please.contactUs")}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.enterpriseZonePage}>
      <div className={Styles.page_header}>
        <div className={classnames(Styles.category)}>
          {category.map((item: any, index: number) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setPageType(item.id);
                }}
              >
                <div
                  className={classnames(
                    Styles.sider_menu_item,
                    pageType === item.id ? Styles.sider_menu_item_active : ""
                  )}
                >
                  {t(item.name)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={data.length === 0 ? Styles.empty_box : Styles.page_recommand}
      >
        {data.length === 0 ? emptyRender() : agentRender()}
      </div>
      <div className={Styles.pagination}>
        <Pagination
          pageSize={pageSize}
          current={pageNo}
          total={total}
          showSizeChanger={false}
          hideOnSinglePage
          onChange={changePagination}
        />
      </div>
      <ConfirmModal
        ref={ConfirmModalRef}
        confirmFn={confirmFn}
        title={t("myagent.delete.title")}
      >
        <Trans
          i18nKey="myagent.delete.content"
          values={{
            name: deleteAgentName,
          }}
        >
          {deleteAgentName}
        </Trans>
      </ConfirmModal>
      <CodeModal showCode={showCode} closeModal={closeModal} />
      {contextHolder}
    </div>
  );
};
export default Enterprise;
