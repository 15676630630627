import { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import {
  Modal,
  Button,
  Form,
  Input,
  ConfigProvider,
  Select,
  InputNumber,
} from "antd";
import { getWorkflowInputForm } from "@/apis/common";
import { useNavigate } from "react-router-dom";

type DynamicFormProps = {
  isShowWorkForm: boolean;
  clearWoke?: any;
  clearDynamicForm: any;
  onSend?: any;
  id?: number;
  tag?: string | undefined;
  userInputForm?:any
};

const DynamicForm = ({
  isShowWorkForm,
  clearWoke,
  id,
  clearDynamicForm,
  onSend,
  userInputForm
}: DynamicFormProps) => {
  const [wokeList, setWokeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const getList = () => {
    setLoading(true);
    getWorkflowInputForm({ workflow_id: id }).then((res: any) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data.user_input_form;
        setWokeList(data);
      }
    });
  };

  const handleFolderSubmit = (values: any) => {
    let data = {
      id,
      inputs: values,
      originData: wokeList,
    };
    if(clearWoke){
      clearWoke()
    }
    localStorage.setItem("chatWorkFlowData", JSON.stringify(data));
    onSend();
  };

  const renderFormItems = () => {
    return (
      wokeList &&
      wokeList.map((item: any) => {
        const { variable, label, type, options, max_length, required } = item;
        if (type === "text-input") {
          return (
            <Form.Item
              key={variable}
              label={label}
              name={variable}
              rules={[
                { required: required, message: `请输入${label}` },
                {
                  max: max_length,
                  message: `${label}不能超过${max_length}个字符`,
                },
              ]}
            >
              <Input placeholder={"请输入" + label} allowClear />
            </Form.Item>
          );
        } else if (type === "select") {
          return (
            <Form.Item
              key={variable}
              label={label}
              name={variable}
              rules={[{ required: required, message: `请选择${label}` }]}
            >
              <Select placeholder={"请选择" + label} allowClear>
                {options.map((option: any) => (
                  <Select.Option value={option} key={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          );
        } else if (type === "number") {
          return (
            <Form.Item
              key={variable}
              label={label}
              name={variable}
              rules={[
                { required: required, message: `请输入${label}` },
                { type: "number", message: `${label}必须为数字` },
              ]}
            >
              <InputNumber
                placeholder={"请输入" + label}
                min={0}
                className={Styles.inputNumber}
                maxLength={max_length}
              />
            </Form.Item>
          );
        } else if (type === "paragraph") {
          return (
            <Form.Item
              key={variable}
              label={label}
              name={variable}
              rules={[
                { required: required, message: `请输入${label}` },
                {
                  max: max_length,
                  message: `${label}不能超过${max_length}个字符`,
                },
              ]}
            >
              <Input.TextArea
                maxLength={max_length}
                showCount
                autoSize={{ minRows: 3 }}
                placeholder={"请输入" + label}
                allowClear
              />
            </Form.Item>
          );
        }

        return null;
      })
    );
  };

  useEffect(() => {
    if(!userInputForm){
      getList();
    }else{
      setWokeList(userInputForm);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#007890",
        },
        components: {
          Input: {
            activeShadow: "none",
          },
          Modal: {
            contentBg: "#F7F8FA",
            headerBg: "#F7F8FA",
          },
        },
      }}
    >
      <div className={Styles.page}>
        <Modal
          title={"工作流输入"}
          width={380}
          open={isShowWorkForm}
          onOk={form.submit}
          onCancel={clearDynamicForm}
          footer={[
            <Button key="back" onClick={clearDynamicForm}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={form.submit}>
              发送
            </Button>,
          ]}
        >
          <Form layout="vertical" form={form} onFinish={handleFolderSubmit}>
            <br />
            {renderFormItems()}
          </Form>
        </Modal>
      </div>
    </ConfigProvider>
  );
};
export default DynamicForm;
