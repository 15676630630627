import NoContent from "@/components/NoContent";
import { IKnowledgeInfo, IKnowledgeListProps } from "@/types/common";
import React, { useEffect, useRef, useState } from "react";
import Styles from "./index.module.scss";
import Skeleton from "react-loading-skeleton";
import KnowledgeItem from "../KnowledgeItem";
import {
  deleteKnowledge,
  toGetKnowledgeList,
  addKnowledgeContent,
  addKnowledgeContentList,
} from "@/apis/common";
import {
  Alert,
  Button,
  ConfigProvider,
  Pagination,
  message,
  Modal,
  Input,
  Form,
} from "antd";
import { updateFiles } from "@/store/reducer/knowledgeFiles";
import store from "@/store";
import { useTranslation, Trans } from "react-i18next";
import ConfirmModal from "@/components/Modal";
import backPic from "@/assets/myAgent/back.svg";
import classnames from "classnames";
import { ListType } from "../FolderList/type";
import FileUpload from "@/components/FileUpload";

const KnowledgeList = ({
  folderId,
  onBackClick,
  type,
  handleFileSelect,
  deleteIdList,
  requiredData,
}: IKnowledgeListProps) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deleteKnowledgeId, setDeleteKnowledgeId] = useState<number[]>([]);
  const [deleteKnowledgeName, setDeleteKnowledgeName] = useState("");
  const ConfirmModalRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [folder_id, setFolder_id] = useState(folderId || 0);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const formRef = useRef<any>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadType, setUploadType] = useState("file");
  const [website, setWebsite] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [uploadFiles, setUploadFiles] = useState<any>([]);

  const handleOkClick = () => {
    if (uploadType === "file") {
      if (uploadFiles.length === 0) {
        message.error("请上传文件");
        return;
      }

      addKnowledgeContentList(uploadFiles).then((res: any) => {
        if (res?.data?.status === 200) {
          setIsModalVisible(false);
          getKnowledgeList();
          message.success("上传成功");
          setUploadFiles([]);
          return;
        }
        message.error("上传失败，请重试");
      });
    }

    if (uploadType === "website" && formRef?.current) {
      formRef.current
        .validateFields()
        .then((values: any) => {
          addKnowledgeContent({
            folder_id: folder_id,
            file_name: websiteName,
            file: website,
            file_source: 2,
            file_category: 1,
          }).then((res: any) => {
            if (res?.data?.status === 200) {
              setIsModalVisible(false);
              getKnowledgeList();
              message.success("上传成功");
              return;
            }
            message.error("上传失败，请重试");
          });
        })
        .catch(() => {});
    }
  };

  const handleDeleteKnowledge = (knowledgeInfo: IKnowledgeInfo) => {
    setDeleteKnowledgeId([knowledgeInfo?.id]);
    setDeleteKnowledgeName(knowledgeInfo?.file_name);
    ConfirmModalRef?.current?.showModal();
  };

  useEffect(() => {
    getKnowledgeList();
  }, [pageNo]);

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  const getKnowledgeList = () => {
    setLoading(true);
    toGetKnowledgeList({
      page_number: pageNo,
      page_size: pageSize,
      folder_id: folder_id,
    }).then((res: any) => {
      setLoading(false);
      res = JSON.parse(JSON.stringify(res));
      if (res.data.status === 200) {
        if (res.data?.data) {
          setData(res.data?.data || []);
          setTotal(res.data.total_items);
        }
      }
    });
  };

  const confirmFn = () => {
    deleteKnowledge({
      ids: deleteKnowledgeId,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        deleteResult();
        ConfirmModalRef?.current?.hideModal();
        getKnowledgeList();
        return;
      }
      deleteResult(false);
    });
  };

  const deleteResult = (success = true) => {
    messageApi.open({
      content: (
        <Alert
          message={t(
            success ? "myagent.delete.success" : "myagent.delete.fail"
          )}
          description={
            success ? (
              <Trans
                i18nKey="myagent.delete.successText"
                values={{ name: deleteKnowledgeName }}
              >
                {deleteKnowledgeName}
              </Trans>
            ) : (
              t("myagent.delete.failText")
            )
          }
          type={success ? "success" : "error"}
          showIcon
          closable
        />
      ),
      className: Styles.customClass,
      duration: 3,
    });
  };

  // 处理上传成功后的逻辑
  const handleUploadSuccess = (data: any) => {
    const newKnowledge = [];
    newKnowledge.push({
      file: data?.file,
      file_name: data?.file_name,
      id: data?.id,
      file_size: data?.file_size,
    });
    getKnowledgeList();
  };

  // 获取最新的文件列表
  const handleGetFileLists = (fileLists: any) => {
    setUploadedFiles(fileLists);
  };

  const knowledgeList =
    (store.getState().knowledgeFiles.knowledgeFiles as any[]) || [];
  const uniqueKnowledgeList = knowledgeList.reduce((acc, current) => {
    // 如果当前 id 已经存在于 acc 中，则跳过
    if (!acc.find((item: any) => item.id === current.id)) {
      acc.push(current);
    }
    return acc;
  }, []);

  const handleCheckboxChange = (isChecked: boolean, file: any) => {
    let updatedFiles,
      uniqueFiles = uniqueKnowledgeList;
    if (isChecked) {
      // 如果复选框被选中，将文件添加到 selectedFiles 数组中
      const files = [...selectedFiles, file];
      updatedFiles = files;
    } else {
      // 如果复选框被取消选中，将文件从 selectedFiles 数组中移除
      const files = selectedFiles.filter((item: any) => item.id !== file.id);
      updatedFiles = files;

      uniqueFiles = uniqueKnowledgeList.filter(
        (item: any) => item.id !== file.id
      );
    }

    // 合并并去重
    const mergedFiles = Array.from(new Set([...uniqueFiles, ...updatedFiles]));
    const mergedFileList = mergedFiles.reduce((acc, current) => {
      // 如果当前 id 已经存在于 acc 中，则跳过
      if (!acc.find((item: any) => item.id === current.id)) {
        acc.push(current);
      }
      return acc;
    }, []);

    // 更新 selectedFiles 和 knowledgeFiles
    setSelectedFiles(updatedFiles);
    handleFileSelect(updatedFiles);
    store.dispatch(
      updateFiles({ knowledgeFiles: mergedFileList, from: "knowledge" })
    );
  };

  useEffect(() => {
    setSelectedFiles(knowledgeList);
  }, [knowledgeList]);

  const SkeletonItem = () => {
    return (
      <div className={Styles.skeleton_container}>
        <div className={Styles.skeleton_image}>
          <Skeleton width={90} height={90} />
        </div>
        <div className={Styles.skeleton_info}>
          <div className={Styles.skeleton_title}>
            <Skeleton width={120} />
          </div>
          <div className={Styles.skeleton_content}>
            <div className={Styles.skeleton_status}>
              <Skeleton width={50} />
            </div>
            <div className={Styles.skeleton_delete}>
              <Skeleton width={50} height={28} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <span className={Styles.title}>
        <div className={Styles.back}>
          <img src={backPic} onClick={onBackClick} alt="" />
        </div>
        {type !== ListType.insert && t("knowledge.title")}
      </span>
    );
  };

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          // 使用品牌色
          colorPrimary: "#007890",
        },
        components: {
          Button: {
            /* 这里是你的组件 token */
            defaultBorderColor: "#007890",
            defaultColor: "#007890",
            primaryShadow: "none",
          },
          Input: {
            activeShadow: "none",
          },
        },
      }}
    >
      <div className={classnames(Styles.page)}>
        {renderHeader()}
        {type !== ListType.insert && requiredData.pub_scope === 1 && (
          <div className={Styles.operator}>
            <div></div>
            <div className={Styles.btnWrap}>
              <Button
                type="primary"
                onClick={() => {
                  setUploadType("file");
                  setIsModalVisible(true);
                }}
              >
                上传文档
              </Button>

              <Button
                onClick={() => {
                  setUploadType("website");
                  setIsModalVisible(true);
                }}
              >
                添加网站
              </Button>
            </div>
          </div>
        )}
        {loading ? (
          <div className={Styles.card_container}>
            {Array(12)
              .fill(null)
              .map((_, index) => (
                <SkeletonItem key={index} />
              ))}
          </div>
        ) : (
          <div className={Styles.card_container}>
            {data && data.length > 0 ? (
              data.map((item: any, index: number) => {
                return (
                  <KnowledgeItem
                    knowledgeInfo={item}
                    key={index}
                    onDelete={handleDeleteKnowledge}
                    type={type}
                    isChecked={selectedFiles.some(
                      (file: any) => file.id === item.id
                    )}
                    onCheckboxChange={(isChecked: boolean) =>
                      handleCheckboxChange(isChecked, item)
                    }
                  />
                );
              })
            ) : (
              <NoContent />
            )}
          </div>
        )}
        <div className={Styles.pagination}>
          <Pagination
            pageSize={pageSize}
            current={pageNo}
            total={total}
            showSizeChanger={false}
            hideOnSinglePage
            onChange={changePagination}
          />
        </div>
        {isModalVisible && (
          <Modal
            open={isModalVisible}
            onOk={handleOkClick}
            onCancel={() => {
              setIsModalVisible(false);
              setUploadFiles([]);
            }}
            width={480}
            centered
            title={uploadType === "file" ? "上传文档" : "添加网站"}
            className={Styles.modal}
            footer={[
              <Button key="submit" type="primary" onClick={handleOkClick}>
                确定
              </Button>,
            ]}
          >
            {uploadType === "file" ? (
              <FileUpload
                isMultiple={true}
                folderPath={"knowledge"}
                onUploadSuccess={(file: any) => {
                  console.log(file);
                  setUploadFiles((prev: any) => {
                    return [
                      ...prev,
                      {
                        folder_id: folder_id,
                        file_name: file.file_name,
                        file: file.file_url,
                        file_size: file.file_size,
                        file_type: file.file_type,
                        file_source: 1,
                        file_category: 1,
                      },
                    ];
                  });
                }}
              />
            ) : (
              <Form ref={formRef} className={Styles.form}>
                <div className={Styles.label}>名称</div>
                <Form.Item
                  label={null}
                  name="websiteName"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  className={Styles.form_item}
                >
                  <Input
                    className={Styles.input}
                    placeholder="请输入名称"
                    onChange={(e: any) => {
                      setWebsiteName(e?.target?.value);
                    }}
                  ></Input>
                </Form.Item>
                <div className={Styles.label}>网址</div>
                <Form.Item
                  label={null}
                  name="website"
                  rules={[
                    {
                      required: true,
                      message: website ? "地址信息有误" : "请输入网址",
                      pattern:
                        /^(http|https):\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?$/,
                    },
                  ]}
                  className={Styles.form_item}
                >
                  <Input
                    placeholder="请输入网址"
                    className={Styles.input}
                    onChange={(e: any) => {
                      setWebsite(e?.target?.value);
                    }}
                  ></Input>
                </Form.Item>
              </Form>
            )}
          </Modal>
        )}
        <ConfirmModal
          ref={ConfirmModalRef}
          confirmFn={confirmFn}
          title={t("knowledge.delete.title")}
        >
          <Trans
            i18nKey="knowledge.delete.content"
            values={{ name: deleteKnowledgeName }}
          >
            {deleteKnowledgeName}
          </Trans>
        </ConfirmModal>
      </div>
    </ConfigProvider>
  );
};

export default KnowledgeList;
