import { IKnowledgeInfoProps } from "@/types/common";
import Styles from "./index.module.scss";
import classnames from "classnames";
import csvPic from "@/assets/myAgent/csv.svg";
import docPic from "@/assets/myAgent/doc.svg";
import mdPic from "@/assets/myAgent/md.svg";
import pdfPic from "@/assets/myAgent/pdf.svg";
import pptPic from "@/assets/myAgent/ppt.svg";
import txtPic from "@/assets/myAgent/txt.svg";
import xlsPic from "@/assets/myAgent/xls.svg";
import html from "@/assets/myAgent/html.svg";

import { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ListType } from "../FolderList/type";

const KnowledgeItem = ({
  knowledgeInfo,
  onDelete,
  type,
  onCheckboxChange,
  isChecked,
}: IKnowledgeInfoProps) => {
  const [isCheck, setIsCheck] = useState(isChecked);

  useEffect(() => {
    setIsCheck(isChecked);
  }, [isChecked]);

  const getFileTypeIcon = (fileType: string) => {
    switch (fileType) {
      case ".pdf":
        return pdfPic;
      case ".docx":
        return docPic;
      case ".pptx":
        return pptPic;
      case ".xlsx":
        return xlsPic;
      case ".csv":
        return csvPic;
      case ".md":
        return mdPic;
      case ".txt":
        return txtPic;
      default:
        return html;
    }
  };

  const getStatusText = (status: number) => {
    switch (status) {
      case 0:
        return "解析中";
      case 1:
        return "就绪";
      case 2:
        return "解析失败";
      case 3:
        return "解析失败";
      default:
        return "";
    }
  };

  const getStatusClass = (status: number) => {
    switch (status) {
      case 0:
        return "status_parsing";
      case 1:
        return "status_ready";
      case 2:
        return "status_failed";
      case 3:
        return "status_failed";
      default:
        return "";
    }
  };

  const getRedirectUrl = (fileType: string, file: string) => {
    const officeFileTypes = [".pptx", ".docx", ".xlsx"];
    if (officeFileTypes.includes(fileType)) {
      return `https://view.officeapps.live.com/op/view.aspx?src=${file}`;
    } else {
      return file;
    }
  };

  const handleCardClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (
      (event.target as HTMLElement).tagName.toLowerCase() !== "button" &&
      (event.target as HTMLElement).tagName.toLowerCase() !== "input" &&
      type !== ListType.insert
    ) {
      window.open(
        getRedirectUrl(knowledgeInfo.file_type, knowledgeInfo.file),
        "_blank"
      );
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const isCheck = e.target.checked;
    setIsCheck(isCheck);
    onCheckboxChange && onCheckboxChange(isCheck, knowledgeInfo);
  };

  const handleDeleteClick = () => {
    onDelete?.(knowledgeInfo);
  };

  return (
    <div className={classnames(Styles.card)} onClick={handleCardClick}>
      <div
        className={classnames(
          Styles.card_content,
          isCheck ? Styles.checked : ""
        )}
      >
        <div className={classnames(Styles.image)}>
          <img
            src={getFileTypeIcon(knowledgeInfo.file_type)}
            loading="lazy"
          ></img>
        </div>
        <div className={classnames(Styles.detail_content)}>
          <div className={classnames(Styles.text)}>
            {knowledgeInfo.file_name}
          </div>
          <div className={classnames(Styles.footer)}>
            <div className={classnames(Styles.status)}>
              <div
                className={classnames(
                  Styles.text,
                  Styles[getStatusClass(knowledgeInfo.status)]
                )}
              >
                {getStatusText(knowledgeInfo.status)}
              </div>
            </div>
            <div className={classnames(Styles.delete)}>
              {type !== ListType.insert && (
                <button
                  onClick={handleDeleteClick}
                  className={classnames(Styles.delete_button)}
                >
                  删除
                </button>
              )}
            </div>
          </div>
        </div>
        {type === ListType.insert && (
          <Checkbox
            checked={isCheck}
            type="checkbox"
            className={classnames(Styles.checkbox)}
            onChange={handleCheckboxChange}
          />
        )}
      </div>
    </div>
  );
};

export default KnowledgeItem;
