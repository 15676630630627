import { IAgentInfo } from "@/types/common";
import Styles from "./index.module.scss";
import classnames from "classnames";
import { Carousel } from "antd";
import ChatBox from "@/components/ChatBox";
import LeftArrowPic from "@/assets/card/left-arrow.svg";
import RightArrowPic from "@/assets/card/right-arrow.svg";
import { CloseOutlined } from "@ant-design/icons";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useEffect, useRef, useState } from "react";
import useThemeHooks from "@/hooks/useThemeHooks";
import Cookies from "js-cookie";
import {
  toCreatePubAgent,
  toGetAgentPublicInfo,
  toGetPubAgentHistory,
  toGetAgentInfo,
  toGetMyAgentHistory,
} from "@/apis/common";
import superAssistantChatBg from "@/assets/common/superAssistantChatBg.svg";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const agentBg = createImageUrl('common/agent-bg.png');

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type ICardItemDetail = {
  agentInfo: IAgentInfo;
  tagNameObj: any;
  userNameObj: any;
  showDetail: boolean;
  setShowDetail: any;
  isSelfItem?: boolean;
};

const CardItemDetail = ({
  agentInfo,
  tagNameObj,
  userNameObj,
  showDetail,
  setShowDetail,
  isSelfItem,
}: ICardItemDetail) => {
  const [show, setShow] = useState(false);
  const carousel = useRef<any>(null);
  const [carouselIndex, setCarouselIndex] = useState(1);
  const [cardImages, setCardImages] = useState([]);
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [magnifier, setMagnifier] = useState(false);

  const pdfRef = useRef<any>(null);
  const [pdfMagnifier, setPdfMagnifier] = useState(false);

  const [activeTab, setActiveTab] = useState("3");
  const [numPages, setNumPages] = useState(0);

  const theme = useThemeHooks();

  const onChange = (currentSlide: number, next: number) => {
    setCarouselIndex(next + 1);
  };

  const changeHistoryAction = (data: any) => {
    if (data && data.length) {
      setChatHistory(data);
    } else {
      setChatHistory([]);
    }
  };

  const getPubAgentHistory = () => {
    if (isSelfItem) {
      toGetMyAgentHistory({
        page_number: 1,
        page_size: 30,
        agent_id: agentInfo.id,
        topic_id: -2,
      })
        .then((res) => {
          if (res.data && res.data.status === 200) {
            changeHistoryAction(res.data.data.reverse());
          } else {
          }
        })
        .catch(() => {});
      return;
    }

    toGetPubAgentHistory({
      page_number: 1,
      page_size: 30,
      agent_id: agentInfo.id,
      topic_id: -2,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          changeHistoryAction(res.data.data.reverse());
        } else {
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    setShow(showDetail);
    setCarouselIndex(1);
  }, [showDetail]);

  useEffect(() => {
    const div = pdfRef.current as any;
    if (div && div?.querySelectorAll) {
      const anchorTags = div?.querySelectorAll("a");

      anchorTags.forEach((anchor: any) => {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      });
    }
  }, [pdfRef?.current, numPages]);

  const isCarouselArray = () => {
    return Array.isArray(cardImages) && cardImages!.length > 1;
  };

  const createPubAgent = () => {
    return toCreatePubAgent({
      pub_agent_id: agentInfo.pub_agent_id || -1,
      name: agentInfo.name,
    }).then((res: any) => {
      let data;
      if (res.data && res.data.status === 200) {
        // addSuccess(res.data.data.id, current.topicId);
        data = res.data.data;
      }
      return data;
    });
  };

  const createTopic = () => {
    // if (token) {
    //   createPubAgent();
    // } else {
    //   return Promise.resolve({
    //     id: -1,
    //   });
    // }
  };

  useEffect(() => {
    if (showDetail) {
      if (isSelfItem) {
        toGetAgentInfo({
          id: agentInfo.id,
        }).then((res: any) => {
          if (res && res.data && res.data.status === 200) {
            let newCardImages: any = [];

            if (
              res.data.data &&
              res.data.data?.flow_config?.card_images.length > 0
            ) {
              newCardImages = res.data.data?.flow_config?.card_images.filter(
                (item: any) => item !== ""
              );
            }
            setCardImages(newCardImages || []);
          }
        });
        getPubAgentHistory();
        return;
      }

      toGetAgentPublicInfo({
        id: agentInfo.id,
      }).then((res: any) => {
        if (res && res.data && res.data.status === 200) {
          let newCardImages: any = [];

          if (
            res.data.data &&
            res.data.data?.flow_config?.card_images.length > 0
          ) {
            newCardImages = res.data.data?.flow_config?.card_images.filter(
              (item: any) => item !== ""
            );
          }
          setCardImages(newCardImages || []);
        }
      });
      getPubAgentHistory();
    }
  }, [showDetail]);

  const [pdfUrl, setPdfUrl] = useState<any>(null);
  // useEffect(() => {
  //   if (
  //     agentInfo?.flow_config?.card_documents &&
  //     agentInfo?.flow_config?.card_documents[0]
  //   ) {
  //     console.log(agentInfo?.flow_config?.card_documents[0], "---f------");
  //     fetch(agentInfo?.flow_config?.card_documents[0], {
  //       mode: "cors",
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         // 将 Blob 对象转换为 URL
  //         const blobUrl = URL.createObjectURL(blob);
  //         console.log(blobUrl);
  //         setPdfUrl(blobUrl);
  //       })
  //       .catch((error) => {
  //         console.error("There was a problem with the fetch operation:", error);
  //       });
  //   }
  // }, [agentInfo, showDetail]);

  return show ? (
    <div
      className={classnames(
        Styles.card_detail,
        show ? Styles.card_detail_show : Styles.card_details_box_hidden
      )}
    >
      <div className={Styles.card_details_box}>
        <div
          className={Styles.card_details_inner}
          style={{
            backgroundImage: `url(${superAssistantChatBg})`,
            backgroundSize: "cover",
          }}
        >
          {(!!agentInfo?.flow_config?.card_video ||
            (agentInfo?.flow_config?.card_documents &&
              agentInfo?.flow_config?.card_documents.length > 0)) && (
            <div className={Styles.switchWrap}>
              {!!agentInfo?.flow_config?.card_video && (
                <div
                  className={
                    activeTab === "1" ? Styles.activeTab : Styles.normalTab
                  }
                  onClick={() => setActiveTab("1")}
                >
                  {activeTab === "1" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M1.04102 10C1.04102 7.624 1.98488 5.34531 3.66497 3.66522C5.34506 1.98513 7.62376 1.04126 9.99977 1.04126C12.3758 1.04126 14.6545 1.98513 16.3346 3.66522C18.0146 5.34531 18.9585 7.624 18.9585 10C18.9585 12.376 18.0146 14.6547 16.3346 16.3348C14.6545 18.0149 12.3758 18.9588 9.99977 18.9588C7.62376 18.9588 5.34506 18.0149 3.66497 16.3348C1.98488 14.6547 1.04102 12.376 1.04102 10ZM11.256 7.48751C10.7085 7.15001 10.2485 6.86626 9.86852 6.69001C9.48102 6.51126 9.04102 6.37626 8.58102 6.51751C8.27904 6.61228 8.00798 6.78623 7.79602 7.02126C7.48602 7.36126 7.38352 7.80626 7.33727 8.25126C7.29102 8.69376 7.29102 9.26751 7.29102 9.96376V10.0363C7.29102 10.7325 7.29102 11.3063 7.33727 11.7488C7.38352 12.1938 7.48602 12.6388 7.79602 12.9788C8.00852 13.2138 8.27852 13.3888 8.58227 13.4825C9.04102 13.625 9.48227 13.4888 9.86852 13.31C10.2485 13.135 10.7085 12.85 11.256 12.5113L11.3235 12.4688C11.891 12.1188 12.3648 11.825 12.7085 11.5525C13.0623 11.2713 13.3723 10.9388 13.4848 10.4763C13.5598 10.1638 13.5598 9.83626 13.4848 9.52376C13.3723 9.06126 13.0623 8.72876 12.7085 8.44876C12.3648 8.17501 11.891 7.88251 11.3235 7.53126L11.256 7.48876V7.48751Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M0.0412598 9.00001C0.0412598 6.624 0.985125 4.34531 2.66522 2.66522C4.34531 0.985125 6.624 0.0412598 9.00001 0.0412598C11.376 0.0412598 13.6547 0.985125 15.3348 2.66522C17.0149 4.34531 17.9588 6.624 17.9588 9.00001C17.9588 11.376 17.0149 13.6547 15.3348 15.3348C13.6547 17.0149 11.376 17.9588 9.00001 17.9588C6.624 17.9588 4.34531 17.0149 2.66522 15.3348C0.985125 13.6547 0.0412598 11.376 0.0412598 9.00001ZM10.2563 6.48751C9.70876 6.15001 9.24876 5.86626 8.86876 5.69001C8.48126 5.51126 8.04126 5.37626 7.58126 5.51751C7.27929 5.61228 7.00822 5.78623 6.79626 6.02126C6.48626 6.36126 6.38376 6.80626 6.33751 7.25126C6.29126 7.69376 6.29126 8.26751 6.29126 8.96376V9.03626C6.29126 9.73251 6.29126 10.3063 6.33751 10.7488C6.38376 11.1938 6.48626 11.6388 6.79626 11.9788C7.00876 12.2138 7.27876 12.3888 7.58251 12.4825C8.04126 12.625 8.48251 12.4888 8.86876 12.31C9.24876 12.135 9.70876 11.85 10.2563 11.5113L10.3238 11.4688C10.8913 11.1188 11.365 10.825 11.7088 10.5525C12.0625 10.2713 12.3725 9.93876 12.485 9.47626C12.56 9.16376 12.56 8.83626 12.485 8.52376C12.3725 8.06126 12.0625 7.72876 11.7088 7.44876C11.365 7.17501 10.8913 6.88251 10.3238 6.53126L10.2563 6.48876V6.48751Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  视频
                </div>
              )}
              {agentInfo?.flow_config?.card_documents &&
                agentInfo?.flow_config?.card_documents.length > 0 && (
                  <div
                    className={
                      activeTab === "2" ? Styles.activeTab : Styles.normalTab
                    }
                    onClick={() => setActiveTab("2")}
                  >
                    {activeTab === "2" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <g clipPath="url(#clip0_5902_5371)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 1.6665V7.08317C10 7.41469 10.1317 7.73263 10.3662 7.96705C10.6006 8.20147 10.9185 8.33317 11.25 8.33317H16.6667V16.6665C16.6667 17.1085 16.4911 17.5325 16.1786 17.845C15.866 18.1576 15.4421 18.3332 15 18.3332H5.00004C4.55801 18.3332 4.13409 18.1576 3.82153 17.845C3.50897 17.5325 3.33337 17.1085 3.33337 16.6665V3.33317C3.33337 2.89114 3.50897 2.46722 3.82153 2.15466C4.13409 1.8421 4.55801 1.6665 5.00004 1.6665H10ZM12.5 12.4998H7.50004C7.27903 12.4998 7.06707 12.5876 6.91079 12.7439C6.7545 12.9002 6.66671 13.1122 6.66671 13.3332C6.66671 13.5542 6.7545 13.7661 6.91079 13.9224C7.06707 14.0787 7.27903 14.1665 7.50004 14.1665H12.5C12.7211 14.1665 12.933 14.0787 13.0893 13.9224C13.2456 13.7661 13.3334 13.5542 13.3334 13.3332C13.3334 13.1122 13.2456 12.9002 13.0893 12.7439C12.933 12.5876 12.7211 12.4998 12.5 12.4998ZM8.33337 9.1665H7.50004C7.27903 9.1665 7.06707 9.2543 6.91079 9.41058C6.7545 9.56686 6.66671 9.77882 6.66671 9.99984C6.66671 10.2209 6.7545 10.4328 6.91079 10.5891C7.06707 10.7454 7.27903 10.8332 7.50004 10.8332H8.33337C8.55439 10.8332 8.76635 10.7454 8.92263 10.5891C9.07891 10.4328 9.16671 10.2209 9.16671 9.99984C9.16671 9.77882 9.07891 9.56686 8.92263 9.41058C8.76635 9.2543 8.55439 9.1665 8.33337 9.1665ZM11.6667 1.70234C11.9824 1.7693 12.2719 1.9265 12.5 2.15484L16.1784 5.83317C16.4067 6.06127 16.5639 6.35077 16.6309 6.6665H11.6667V1.70234Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5902_5371">
                            <rect width="20" height="20" fill="currentColor" />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="18"
                        viewBox="0 0 14 18"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7 0.666504V6.08317C7 6.41469 7.1317 6.73263 7.36612 6.96705C7.60054 7.20147 7.91848 7.33317 8.25 7.33317H13.6667V15.6665C13.6667 16.1085 13.4911 16.5325 13.1785 16.845C12.866 17.1576 12.442 17.3332 12 17.3332H2C1.55798 17.3332 1.13405 17.1576 0.821491 16.845C0.508931 16.5325 0.333336 16.1085 0.333336 15.6665V2.33317C0.333336 1.89114 0.508931 1.46722 0.821491 1.15466C1.13405 0.842099 1.55798 0.666504 2 0.666504H7ZM9.5 11.4998H4.5C4.27899 11.4998 4.06703 11.5876 3.91075 11.7439C3.75447 11.9002 3.66667 12.1122 3.66667 12.3332C3.66667 12.5542 3.75447 12.7661 3.91075 12.9224C4.06703 13.0787 4.27899 13.1665 4.5 13.1665H9.5C9.72102 13.1665 9.93298 13.0787 10.0893 12.9224C10.2455 12.7661 10.3333 12.5542 10.3333 12.3332C10.3333 12.1122 10.2455 11.9002 10.0893 11.7439C9.93298 11.5876 9.72102 11.4998 9.5 11.4998ZM5.33334 8.1665H4.5C4.27899 8.1665 4.06703 8.2543 3.91075 8.41058C3.75447 8.56686 3.66667 8.77882 3.66667 8.99984C3.66667 9.22085 3.75447 9.43281 3.91075 9.58909C4.06703 9.74537 4.27899 9.83317 4.5 9.83317H5.33334C5.55435 9.83317 5.76631 9.74537 5.92259 9.58909C6.07887 9.43281 6.16667 9.22085 6.16667 8.99984C6.16667 8.77882 6.07887 8.56686 5.92259 8.41058C5.76631 8.2543 5.55435 8.1665 5.33334 8.1665ZM8.66667 0.702337C8.9824 0.7693 9.2719 0.926497 9.5 1.15484L13.1783 4.83317C13.4067 5.06127 13.5639 5.35077 13.6308 5.6665H8.66667V0.702337Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                    文档
                  </div>
                )}
              <div
                className={
                  activeTab === "3" ? Styles.activeTab : Styles.normalTab
                }
                onClick={() => setActiveTab("3")}
              >
                {activeTab === "3" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M2.15016 15.8417L2.1335 15.8583C1.9085 15.3667 1.76683 14.8083 1.7085 14.1917C1.76683 14.8 1.92516 15.35 2.15016 15.8417Z"
                      fill="currentColor"
                    />
                    <path
                      d="M7.50018 8.65002C8.59554 8.65002 9.48351 7.76205 9.48351 6.66668C9.48351 5.57132 8.59554 4.68335 7.50018 4.68335C6.40481 4.68335 5.51685 5.57132 5.51685 6.66668C5.51685 7.76205 6.40481 8.65002 7.50018 8.65002Z"
                      fill="currentColor"
                    />
                    <path
                      d="M13.4917 1.66675H6.50841C3.47508 1.66675 1.66675 3.47508 1.66675 6.50841V13.4917C1.66675 14.4001 1.82508 15.1917 2.13341 15.8584C2.85008 17.4417 4.38341 18.3334 6.50841 18.3334H13.4917C16.5251 18.3334 18.3334 16.5251 18.3334 13.4917V11.5834V6.50841C18.3334 3.47508 16.5251 1.66675 13.4917 1.66675ZM16.9751 10.4167C16.3251 9.85841 15.2751 9.85841 14.6251 10.4167L11.1584 13.3917C10.5084 13.9501 9.45841 13.9501 8.80841 13.3917L8.52508 13.1584C7.93341 12.6417 6.99175 12.5917 6.32508 13.0417L3.20841 15.1334C3.02508 14.6667 2.91675 14.1251 2.91675 13.4917V6.50841C2.91675 4.15841 4.15841 2.91675 6.50841 2.91675H13.4917C15.8417 2.91675 17.0834 4.15841 17.0834 6.50841V10.5084L16.9751 10.4167Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Frame">
                      <g id="vuesax/bold/gallery">
                        <g id="gallery">
                          <path
                            id="Vector"
                            d="M2.15016 15.8417L2.1335 15.8583C1.9085 15.3667 1.76683 14.8083 1.7085 14.1917C1.76683 14.8 1.92516 15.35 2.15016 15.8417Z"
                            fill="currentColor"
                          />
                          <path
                            id="Vector_2"
                            d="M7.49993 8.65002C8.5953 8.65002 9.48327 7.76205 9.48327 6.66668C9.48327 5.57132 8.5953 4.68335 7.49993 4.68335C6.40457 4.68335 5.5166 5.57132 5.5166 6.66668C5.5166 7.76205 6.40457 8.65002 7.49993 8.65002Z"
                            fill="currentColor"
                          />
                          <path
                            id="Vector_3"
                            d="M13.4915 1.66675H6.50817C3.47484 1.66675 1.6665 3.47508 1.6665 6.50841V13.4917C1.6665 14.4001 1.82484 15.1917 2.13317 15.8584C2.84984 17.4417 4.38317 18.3334 6.50817 18.3334H13.4915C16.5248 18.3334 18.3332 16.5251 18.3332 13.4917V11.5834V6.50841C18.3332 3.47508 16.5248 1.66675 13.4915 1.66675ZM16.9748 10.4167C16.3248 9.85841 15.2748 9.85841 14.6248 10.4167L11.1582 13.3917C10.5082 13.9501 9.45817 13.9501 8.80817 13.3917L8.52484 13.1584C7.93317 12.6417 6.9915 12.5917 6.32484 13.0417L3.20817 15.1334C3.02484 14.6667 2.9165 14.1251 2.9165 13.4917V6.50841C2.9165 4.15841 4.15817 2.91675 6.50817 2.91675H13.4915C15.8415 2.91675 17.0832 4.15841 17.0832 6.50841V10.5084L16.9748 10.4167Z"
                            fill="currentColor"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
                图片
              </div>
            </div>
          )}
          {activeTab === "1" ? (
            <div className={Styles.card_video_box}>
              <video
                style={{ borderRadius: "12px" }}
                width="100%"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                // playsinline={true}
                x5-video-player-type="h5"
                x5-video-orientation="h5"
                x5-video-player-fullscreen="true"
                preload="auto"
                controls={true}
                src={agentInfo?.flow_config?.card_video}
              ></video>
            </div>
          ) : activeTab === "2" ? (
            <div className={Styles.card_documents} ref={pdfRef}>
              <div
                className={classnames(
                  Styles.card_documents_item,
                  pdfMagnifier ? Styles.pdfMagnifier : "scroll-hide"
                )}
                // onClick={() => {
                //   setPdfMagnifier(true);
                // }}
              >
                {pdfMagnifier ? (
                  <CloseOutlined
                    className={Styles.card_pdf_box_close}
                    onClick={(event: any) => {
                      setPdfMagnifier(false);
                      event.stopPropagation();
                    }}
                  />
                ) : null}
                <Document
                  file={agentInfo?.flow_config?.card_documents[0]}
                  onLoadSuccess={({ numPages }) => {
                    console.log(`Loaded a file with ${numPages} pages!`);
                    setNumPages(numPages);
                  }}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </div>
            </div>
          ) : (
            <div
              className={classnames(
                Styles.card_image_box,
                magnifier ? Styles.card_image_box_fullscreen : ""
              )}
            >
              <div className={Styles.card_image_box_inner}>
                <CloseOutlined
                  className={Styles.card_image_box_close}
                  onClick={() => {
                    setMagnifier(false);
                  }}
                />
                {isCarouselArray() && (
                  <div className={Styles.card_image_index}>
                    {carouselIndex}/{cardImages.length}
                  </div>
                )}
                {isCarouselArray() && (
                  <img
                    style={
                      carouselIndex !== 1
                        ? {}
                        : {
                            cursor: "not-allowed",
                            opacity: 0.3,
                          }
                    }
                    src={LeftArrowPic}
                    onClick={() => {
                      if (carouselIndex !== 1) {
                        carousel.current?.prev();
                      }
                    }}
                    className={Styles.left_arrow}
                  ></img>
                )}
                <Carousel
                  beforeChange={onChange}
                  ref={carousel}
                  infinite={false}
                >
                  {!isCarouselArray() ? (
                    <div className={Styles.contentStyle}>
                      <img
                        src={
                          getImageUrl(cardImages?.[0]) ||
                          agentInfo?.avatar_url ||
                          agentBg
                        }
                        onClick={() => {
                          setMagnifier((magnifier) => !magnifier);
                        }}
                        loading="lazy"
                      ></img>
                    </div>
                  ) : (
                    cardImages.map((item: any, index: number) => {
                      return (
                        <div className={Styles.contentStyle} key={index}>
                          <img
                            src={getImageUrl(item) || agentBg}
                            draggable="false"
                            onClick={() => {
                              setMagnifier((magnifier) => !magnifier);
                            }}
                            loading="lazy"
                          ></img>
                        </div>
                      );
                    })
                  )}
                </Carousel>
                {isCarouselArray() && (
                  <img
                    style={
                      carouselIndex !== cardImages.length
                        ? {}
                        : {
                            cursor: "not-allowed",
                            opacity: 0.3,
                          }
                    }
                    src={RightArrowPic}
                    onClick={() => {
                      if (carouselIndex !== cardImages.length) {
                        carousel.current?.next();
                      }
                    }}
                    className={Styles.right_arrow}
                  ></img>
                )}
              </div>
            </div>
          )}
          <div className={Styles.card_chat_box}>
            {agentInfo && agentInfo.id && (
              <div className={Styles.card_chat_box_inner}>
                <ChatBox
                  mode={isSelfItem ? "agent" : "pub_agent"}
                  boxStyle="card"
                  agentId={isSelfItem ? agentInfo.id : agentInfo.agent_id}
                  pubAgentId={agentInfo.id}
                  topicId={-2}
                  firstChat={createTopic}
                  history={chatHistory}
                  questionEx={
                    agentInfo.question_samples ? agentInfo.question_samples : []
                  }
                  frameId={agentInfo.ai_framework}
                  receiverInfo={agentInfo?.flow_config?.receiver?.[0]}
                  botAvator={
                    getImageUrl(cardImages[0]) ||
                    agentInfo?.avatar_url ||
                    agentBg
                  }
                  botName={agentInfo.name}
                  greeting={agentInfo?.greeting}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={Styles.card_details_close}
          onClick={() => {
            setShow(false);
            setShowDetail((showDetail: boolean) => !showDetail);
          }}
        >
          <div className={classnames(
            "icon-close_line",
            theme === "dark" ? Styles.icon_close_white : null,
            "iconfont",
            "icon-size24"
          )}>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default CardItemDetail;
