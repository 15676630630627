import _ from "lodash";
import classnames from "classnames";
import Styles from "../../index.module.scss";
import {
  Form,
  Input,
  Select,
  Card,
  Slider,
  Switch,
  Spin,
  Tooltip,
  Modal,
  Button,
  message,
} from "antd";
import { createFieldType } from "@/types/common";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { modalList, agentTypeList, skillsAll } from "../../list";
import FileUpload from "../../components/FileUpload";
import BgUpload from "@/components/Create/components/BgUpload";
import SelectArrowPic from "@/assets/common/select-arrow.svg";
import { getImageUrl } from "@/utils/common";
import ProgressForm from "./components/Progress";
import type { UploadFile } from "antd/es/upload/interface";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import QuestionPic from "@/assets/common/question.svg";
import KnowledgeContent from "@/components/KnowledgeContent";
import { useSelector } from "react-redux";
import { ListType } from "@/components/KnowledgeContent/component/FolderList/type";
import RelateKnowledgeBaseModal from "@/components/relateKnowledgeBaseModal";
import FolderListTable from "../../components/FolderListTable";
import { getKnowledgeFolderList } from "@/apis/common";

const { Option } = Select;
const { TextArea } = Input;
const NormalMode = forwardRef(
  (
    {
      createInfo,
      changeCreateInfo,
      switchSkills,
      updateAgent,
      createId,
      saveStatus,
    }: any,
    ref
  ) => {
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation();
    const [curFileLists, setCurFileLists] = useState<UploadFile[]>([]);
    const [needLoading, setNeedLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);
    const [hideIdList, setHideIdList] = useState<number[]>([]);
    const onUploadSuccess = (url: string) => {
      changeCreateInfo("avatar_url", url);
    };

    useImperativeHandle(ref, () => ({
      validateFn,
    }));

    const agentRef = useRef<any>();
    const systemMessageRef = useRef<any>();
    const relateKnowledgeBaseModalRef = useRef<any>();
    const [curFolderList, setCurFolderList] = useState<any>([]);

    const [agentTop, setAgentTop] = useState(0);
    const [systemTop, setSystemTop] = useState(0);

    const [hasGetFolderList, setHasGetFolderList] = useState(false);

    const getKnowledgeFolderListIds = async () => {
      if (
        !createInfo?.flow_config?.knowledge_folder ||
        !createInfo?.flow_config?.knowledge_folder.length
      ) {
        return;
      }
      let ids = createInfo?.flow_config?.knowledge_folder.map(
        (item: any) => item.id
      );
      getKnowledgeFolderList({
        ids
      } as any).then((res: any) => {
        if (res && res.data && res.data.status === 200) {
          console.log(res.data, "res.data.data");
          setCurFolderList(
            res.data.data.map((item: any) => {
              return {
                id: item.id,
                name: item.name,
                user_id: item?.user_id,
              };
            })
          );
          setHasGetFolderList(true);
        }
      });
    };

    useEffect(() => {
      const { top: agentRefTop } = agentRef?.current?.getBoundingClientRect();
      const { top: systemMessageRefTop } =
        systemMessageRef?.current?.getBoundingClientRect();
      setAgentTop(agentRefTop);
      setSystemTop(systemMessageRefTop);
    }, []);

    const validateFn = (fn: any, ref: any) => {
      form
        .validateFields()
        .then((res) => {
          fn(true, res);
        })
        .catch((error: any) => {
          const { errorFields } = error;
          if (errorFields.length) {
            const errorTop =
              errorFields[0].name[0] === "agentName" ? agentTop : systemTop;
            ref?.scrollTo({ top: errorTop - 100, behavior: "smooth" });
          }

          fn(false, error);
        });
    };

    useEffect(() => {
      setNeedLoading(saveStatus === "Loading");
    }, [saveStatus]);

    const formRef = useRef<any>();

    useEffect(() => {
      // 当表单字段值改变时，手动调用 setFieldsValue 来更新表单字段的值
      formRef?.current.setFieldsValue({
        agentName: createInfo.name,
        systemMessage: createInfo.flow_config.receiver[0].system_message,
      });
      if (!hasGetFolderList) {
        getKnowledgeFolderListIds();
      }
    }, [createInfo]);

    const handleLinkClick = () => {
      relateKnowledgeBaseModalRef?.current?.show();
    };

    const knowledgeFiles = useSelector(
      (state: any) => state.knowledgeFiles.knowledgeFiles
    );
    const from = useSelector((state: any) => state.knowledgeFiles.from);

    const updateHideIdList = () => {
      if (from === "knowledge") {
        const newHideIdList = [...hideIdList];
        knowledgeFiles.forEach((file: any) => {
          const index = newHideIdList.indexOf(file.id);
          if (index !== -1) {
            newHideIdList.splice(index, 1);
          }
        });
        setHideIdList(newHideIdList);
      }
    };

    useEffect(() => {
      updateHideIdList();
    }, [knowledgeFiles]);

    useEffect(() => {
      changeCreateInfo(
        "flow_config.knowledge_folder",
        curFolderList.map((item: any) => {
          return {
            id: item?.id,
            user_id: item?.user_id,
          };
        })
      );
    }, [curFolderList]);

    return (
      <div
        className={classnames(
          Styles.create_setting_inner,
          Styles.professional_mode,
          "scroll-hide"
        )}
      >
        <Form
          layout="horizontal"
          form={form}
          ref={formRef}
          autoComplete={"off"}
        >
          <div className={Styles.upload_box}>
            <div className={Styles.form_item_title}>
              {t("create.image.cover")}
            </div>
            {needLoading ? (
              <div className={Styles.spin}>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </div>
            ) : (
              <BgUpload
                onUploadSuccess={onUploadSuccess}
                defaultImageUrl={getImageUrl(createInfo.avatar_url)}
              />
            )}
          </div>
          <div className={Styles.form_item_title} ref={agentRef}>
            {t("create.name.agent")}
          </div>
          <Form.Item<createFieldType>
            label=""
            name="agentName"
            className={Styles.form_item}
            rules={[{ required: true, message: t("create.name.placeholder") }]}
          >
            <Input
              value={createInfo.name}
              placeholder={t("create.name.placeholder")}
              className={Styles.input}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                changeCreateInfo("name", e.target.value);
              }}
            ></Input>
          </Form.Item>

          {/* <div className={Styles.form_item_title}>头像上传</div> */}

          <div className={Styles.form_item_title}>
            {t("create.introduce.agent")}
          </div>
          <Form.Item<createFieldType>
            label=""
            className={Styles.form_item}
            rules={[
              { required: false, message: t("create.introduce.placeholder") },
            ]}
          >
            {/* <Input
              value={createInfo.intro}
              placeholder={t("create.introduce.placeholder")}
              className={Styles.input}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                changeCreateInfo("intro", e.target.value);
              }}
            ></Input> */}
            <TextArea
              rows={4}
              placeholder={t("create.introduce.placeholder")}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                changeCreateInfo("intro", e.target.value);
              }}
              value={createInfo.intro}
              className={Styles.textarea}
            />
          </Form.Item>

          {/* <div className={Styles.form_item_title}>{t("create.agent.type")}</div>
        <Form.Item<createFieldType>
          label=""
          className={Styles.form_item}
          rules={[
            { required: true, message: t("create.provider.placeholder") },
          ]}
        >
          <Select
            value={createInfo.agent_type}
            placeholder={t("create.provider.placeholder")}
            className={Styles.select}
            onChange={(value: string) => {
              changeCreateInfo("agent_type", value);
            }}
          >
            {agentTypeList.map((item, index) => {
              return (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item> */}

          <div
            className={Styles.form_item_title}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {t("create.provider.model")}
            <Tooltip title={t("create.provider.modelDesc")}>
              <img
                src={QuestionPic}
                className={Styles.question}
                loading="lazy"
              ></img>
            </Tooltip>
          </div>
          <Form.Item<createFieldType>
            label=""
            className={Styles.form_item}
            rules={[
              { required: true, message: t("create.provider.placeholder") },
            ]}
          >
            <Select
              value={
                (createInfo.flow_config.receiver[0].model === "gpt-3.5-turbo"
                  ? modalList[0].modalId
                  : createInfo.flow_config.receiver[0].model) +
                (createInfo?.ai_framework || 101)
              }
              placeholder={t("create.provider.placeholder")}
              className={Styles.select}
              onChange={(value: string, option: any) => {
                changeCreateInfo(
                  ["ai_framework", "flow_config.receiver[0].model"],
                  [option.frameId, option.modalId]
                );
                // changeCreateInfo("flow_config.receiver[0].model", value);
              }}
            >
              {modalList.map((item, index) => {
                return (
                  <Option
                    key={item.value}
                    value={item.value}
                    frameId={item.frameId}
                    modalId={item.modalId}
                  >
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <div
            className={classnames(
              Styles.form_item_title,
              Styles.form_item_title_inline
            )}
            ref={systemMessageRef}
          >
            <div>
              {t("create.input.prompts")}{" "}
              {/* <Select
              value={createInfo.flow_config.receiver[0].model}
              placeholder={t("create.provider.placeholder")}
              className={classnames(Styles.select, Styles.model_select)}
              onChange={(value: string) => {
                changeCreateInfo("flow_config.receiver[0].model", value);
              }}
              suffixIcon={<img src={SelectArrowPic}></img>}
            >
              {modalList.map((item, index) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select> */}
            </div>
            <div className={Styles.temperature}>
              <span>{t("create.emotional.temperature")}</span>
              <span className={Styles.number}>
                {createInfo.flow_config.receiver[0].temperature.toFixed(1) ||
                  "0"}
              </span>
              <Slider
                min={0}
                max={1}
                step={0.1}
                onChange={(value) => {
                  changeCreateInfo(
                    `flow_config.receiver[0].temperature`,
                    value
                  );
                }}
                tooltip={{
                  color: "var(--theme-bg10)",
                  overlayClassName: Styles.tooltip,
                }}
                value={createInfo.flow_config.receiver[0].temperature}
                className={classnames(Styles.slider, Styles.slider_temperature)}
              />
            </div>
          </div>
          <Form.Item<createFieldType>
            label=""
            className={Styles.form_item}
            name="systemMessage"
            rules={[{ required: true, message: t("create.systemMessageTip") }]}
          >
            <TextArea
              rows={8}
              placeholder={t("create.systemMessageTip")}
              value={createInfo.flow_config.receiver[0].system_message}
              onChange={(e) => {
                changeCreateInfo(
                  `flow_config.receiver[0].system_message`,
                  e.target.value
                );
              }}
              className={Styles.textarea}
            />
          </Form.Item>
          {/* <Form.Item<createFieldType>
          label=""
          className={Styles.form_item}
          rules={[
            { required: true, message: t("create.provider.placeholder") },
          ]}
        >
          <Select
            value={createInfo.flow_config.receiver[0].model}
            placeholder={t("create.provider.placeholder")}
            className={classnames(Styles.select, Styles.model_select)}
            onChange={(value: string) => {
              changeCreateInfo("flow_config.receiver[0].model", value);
            }}
          >
            {modalList.map((item, index) => {
              return (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item> */}
          <div className={`${Styles.form_item_title} ${Styles.flexContainer}`}>
            {t("知识库关联")}
            <div className={Styles.form_item_actions}>
              <div className={Styles.actions_link} onClick={handleLinkClick}>
                关联已有知识库
              </div>
            </div>
          </div>

          <RelateKnowledgeBaseModal
            ref={relateKnowledgeBaseModalRef}
            setCurrentFolderList={(list) => {
              setHasGetFolderList(true);
              setCurFolderList(list);
            }}
            curFolderList={curFolderList}
          ></RelateKnowledgeBaseModal>

          <FolderListTable
            data={curFolderList}
            unRelate={(id: number) => {
              message.success("解除关联成功");
              setCurFolderList(
                curFolderList.filter((item: any) => item.id !== id)
              );
            }}
          />

          <ProgressForm
            data={{
              knowledge_base: createInfo?.flow_config?.knowledge_base || [],
              curFileLists,
            }}
            changeCreateInfo={changeCreateInfo}
            updateAgent={updateAgent}
            hideIdList={hideIdList}
            handleHideIdList={(deleteIdList: typeof hideIdList) => {
              setHideIdList(deleteIdList);
            }}
          />
        </Form>

        <div className={Styles.form_item_title}>
          {t("create.repository.greetings")}
        </div>

        <Form.Item<createFieldType> label="" className={Styles.form_item}>
          <Input
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              changeCreateInfo("greeting", e.target.value);
            }}
            className={Styles.input}
            placeholder={t("create.greeting.placeholder")}
            value={createInfo.greeting}
          />
        </Form.Item>

        <div className={Styles.form_item_title}>{t("create.agent.tools")}</div>

        {skillsAll.map((item: any, index) => {
          return (
            <Card
              key={item.skill}
              title={
                <div className={Styles.skill_title}>
                  <div>{item.title}</div>
                  <Switch
                    checked={
                      !!createInfo.flow_config.skills.includes(item.skill)
                    }
                    onChange={(value) => {
                      switchSkills(item.skill, value);
                    }}
                    style={{
                      backgroundColor: "var(--theme-bg10)",
                      borderColor: "green",
                    }}
                    className={Styles.switch}
                  />
                </div>
              }
              className={classnames(
                Styles.skill,
                !item?.desc ? Styles.skills_nodata : "",
                Styles.card,
                !item?.desc ? Styles.card_nodata : ""
              )}
            >
              {item?.desc || <></>}
            </Card>
          );
        })}
      </div>
    );
  }
);

export default NormalMode;
