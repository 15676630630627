import Styles from "./index.module.scss";
import { Button, Image, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { IAgentInfoProps } from "@/types/common";
import { useNavigate } from "react-router-dom";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useTranslation } from "react-i18next";
import CardItemDetail from "./CardItemDetail";
import {
  like,
  disLike,
  toGetAgentInfo,
  addPubAgent,
  deletePubAgent,
} from "@/apis/common";
import { numberFormat } from "@/utils/common";
import _ from "lodash";
import qs from "qs";
import useLoginHooks from "@/hooks/useLoginHooks";
import Cookies from "js-cookie";
import store, { loginOption } from "@/store";
import classnames from "classnames";

const agentBg = createImageUrl("common/agent-bg.png");
const Avatar = createImageUrl("menu/avator.png");

const CardItem = ({
  agentInfo,
  tagNameObj,
  userNameObj,
  likeStatusObj,
  setLikeStatusObj,
  isSelfItem,
  setNeedUpdateData,
  onDelete,
  reloadList,
  collectSwitch = false,
}: IAgentInfoProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const itemRef: any = useRef();
  const [currentWidth, setCurrentWidth] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { username } = useLoginHooks();

  const token = localStorage.getItem("token") || Cookies.get("token");

  const curId = isSelfItem ? agentInfo?.pub_agent_id : agentInfo?.id;

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };
  // 点赞逻辑
  const likeAction = () => {
    if (isLiking) {
      return;
    }

    if (setNeedUpdateData) {
      setNeedUpdateData(true);
    }

    if (curId && setLikeStatusObj) {
      setIsLiking(true);
      if (!likeStatusObj[curId]?.is_my_like) {
        like({ pub_agent_id: curId })
          .then((res) => {
            setIsLiking(false);
            if (res && res.data && res.status === 200) {
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  ...newLikeStatusObj[curId],
                  is_my_like: true,
                  like_count:
                    newLikeStatusObj[curId]?.like_count &&
                    newLikeStatusObj[curId]?.like_count > 0
                      ? newLikeStatusObj[curId]?.like_count + 1
                      : 1,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      } else {
        disLike({ pub_agent_ids: [curId] })
          .then((res) => {
            if (res && res.data && res.status === 200) {
              setIsLiking(false);
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  ...newLikeStatusObj[curId],
                  is_my_like: false,
                  like_count:
                    newLikeStatusObj[curId]?.like_count &&
                    newLikeStatusObj[curId]?.like_count > 0
                      ? newLikeStatusObj[curId]?.like_count - 1
                      : 0,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      }
    }
  };

  // 收藏逻辑
  const collectAction = () => {
    if (isLiking) {
      return;
    }

    if (curId && setLikeStatusObj) {
      setIsLiking(true);
      if (!likeStatusObj[curId]?.is_my_collect) {
        addPubAgent({ pub_agent_id: curId, show_mode: 2 })
          .then((res) => {
            setIsLiking(false);
            if (res && res.data && res.status === 200) {
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  ...newLikeStatusObj[curId],
                  is_my_collect: true,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      } else {
        deletePubAgent({ pub_agent_ids: [curId], show_mode: 2 })
          .then((res) => {
            if (res && res.data && res.status === 200) {
              setIsLiking(false);
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  ...newLikeStatusObj[curId],
                  is_my_collect: false,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
              reloadList && reloadList(2);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      }
    }
  };

  const deleteItem = () => {
    if (onDelete) {
      onDelete(agentInfo);
    }
  };

  const edit = () => {
    let param: any = {
      step: "create",
      id: String(agentInfo?.id),
      type: "private",
    };

    param.mode = agentInfo?.show_mode === 2 ? "card" : "";
    if (!!agentInfo.pub_agent_id) {
      param.publicId = agentInfo?.pub_agent_id;
    }

    navigate(`/workflow?${qs.stringify(param)}`);
  };

  const createSame = () => {
    localStorage.setItem("createAgentInfo", "");
    toGetAgentInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          localStorage.setItem(
            "createAgentInfo",
            JSON.stringify(res.data.data)
          );
        }
      }
    });
    let data = {
      step: "create",
      mode: "card",
    };
    navigate(`/workflow?${qs.stringify(data)}`);
  };

  return (
    <div className={Styles.agent_item} ref={itemRef}>
      <div className={Styles.agent_item_content}>
        <div className={Styles.agent_item_img_box}>
          {isSelfItem && (
            <>
              <div className={Styles.agent_item_mask}></div>
              <div className={Styles.agent_item_action}>
                <div
                  className={Styles.agent_item_action_item}
                  onClick={() => {
                    edit();
                  }}
                >
                  <span
                    className={classnames(
                      Styles.img,
                      "iconfont",
                      "icon-edit_line"
                    )}
                  ></span>
                  <span>{t("common.edit")}</span>
                </div>
                |
                <div
                  className={Styles.agent_item_action_item}
                  onClick={() => {
                    deleteItem();
                  }}
                >
                  <span
                    className={classnames(
                      Styles.img,
                      "iconfont",
                      "icon-delete_line"
                    )}
                  ></span>
                  <span>{t("common.delete")}</span>
                </div>
                |
                <div
                  className={Styles.agent_item_action_item}
                  onClick={() => {
                    createSame();
                  }}
                >
                  <span
                    className={classnames(
                      Styles.img,
                      "iconfont",
                      "icon-copy_line"
                    )}
                  ></span>
                  <span>{t("common.create.same")}</span>
                </div>
              </div>
            </>
          )}
          {collectSwitch && (
            <div className={Styles.agent_item_collect}>
              <Tooltip
                placement="bottom"
                title={
                  likeStatusObj[curId || ""]?.is_my_collect
                    ? t("common.move.out.team")
                    : t("common.join.my.ai.team")
                }
                color={"#000"}
                zIndex={10000}
              >
                <div
                  className={Styles.iconfont_collect}
                  onClick={(e) => {
                    e.stopPropagation();
                    collectAction();
                  }}
                >
                  <span
                    className={classnames(
                      "iconfont",
                      "icon-size24",
                      likeStatusObj[curId || ""]?.is_my_collect
                        ? "icon-staractive_fill"
                        : "icon-star_line"
                    )}
                  ></span>
                </div>
              </Tooltip>
            </div>
          )}
          <div className={Styles.agent_item_img_padding}></div>
          <img
            className={Styles.agent_item_img}
            onClick={() => {
              if (!token) {
                onLoginAndRegister();
              } else {
                if (agentInfo.jump_url) {
                  window.open(agentInfo.jump_url);
                } else {
                  setShowDetail((showDetail: boolean) => !showDetail);
                }
              }
            }}
            src={getImageUrl(agentInfo?.avatar_url || agentBg)}
            onError={(e: any) => {
              e.target.src = agentBg;
            }}
            loading="lazy"
          ></img>
          <div className={Styles.agent_item_padding}></div>
          {/* <div className={Styles.agent_item_type}>
            <img src={SingalAgentPic}></img>
            <span>{t("common.agent.singal")}</span>
          </div> */}
          {/* <div className={Styles.agent_item_mask}></div> */}
          <CardItemDetail
            agentInfo={agentInfo}
            tagNameObj={tagNameObj}
            userNameObj={userNameObj}
            showDetail={showDetail}
            setShowDetail={setShowDetail}
            isSelfItem={isSelfItem}
          />
        </div>
        {/* <div className={Styles.tag_list}>
        {agentInfo.tags?.map((item, index) => {
          return (
            <div className={Styles.tag_list_item} key={index}>
              {tagNameObj[item]}
            </div>
          );
        })}
      </div> */}
        <div className={Styles.agent_item_info}>
          {/* <img className={Styles.avator} src={Avatar}></img> */}
          {/* <div> */}
          <div className={Styles.name}>{agentInfo?.name}</div>
          <div className={Styles.user_info}>
            <div className={Styles.author}>
              <img className={Styles.avator} src={Avatar} loading="lazy"></img>
              <span>
                {isSelfItem
                  ? username
                  : userNameObj[agentInfo?.author || ""] || ""}
              </span>
            </div>

            {isSelfItem && !agentInfo.pub_agent_id ? null : (
              <div className={Styles.like}>
                <div
                  onClick={likeAction}
                  className={classnames(
                    Styles.img,
                    "iconfont",
                    likeStatusObj[curId || ""]?.is_my_like
                      ? "icon-heartactive_fill"
                      : "icon-heart_line"
                  )}
                ></div>
                {numberFormat(likeStatusObj[curId || ""]?.like_count || 0)}
              </div>
            )}
          </div>
          {/* </div> */}
          {/* <div className={Styles.action}>
          <Button style={{ marginLeft: "20px" }} size="small" onClick={toChat}>
            {t("对话")}
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            size="small"
            onClick={createSame}
          >
            {t("创建同款")}
          </Button>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardItem;
