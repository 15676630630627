import AiSearch from "@/pages/AiSearch";
import SuperAssistant from "@/pages/SuperAssistant";
import CardList from "@/pages/CardList";
import ToolList from "@/pages/ToolList";
import Chat from "@/pages/Chat";
import Canvas from "@/pages/Canvas";
import Community from "@/pages/Community";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import Advanced from "@/pages/Advanced";
// import Create from "@/components/Create";
// import Publish from "@/components/Publish";
import Workflow from "@/pages/Workflow";
import MyAgent from "@/pages/MyAgent";
import AiTeam from "@/pages/AiTeam";
import EnterpriseZone from "@/pages/EnterpriseZone";
import Enterprise from "@/pages/Enterprise";
import { routerList } from "@/types/common";
import Knowledge from "@/pages/Knowledge";
import Share from "@/pages/Share";
import SuperAssistantChat from "@/pages/SuperAssistantChat";

const routerMap: routerList = [
  {
    path: "/",
    element: <SuperAssistant />,
  },
  {
    path: "/SuperAssistantChat",
    element: <SuperAssistantChat />,
  },
  {
    path: "/ai_search",
    element: <AiSearch />,
  },
  {
    path: "/aiteam",
    element: <AiTeam />,
  },
  {
    path: "/aicard",
    element: <CardList />,
  },
  {
    path: "/aitool",
    element: <ToolList />,
  },
  {
    path: "/chat",
    element: <Chat />,
  },
  {
    path: "/canvas",
    element: <Canvas />,
  },
  {
    path: "/community",
    element: <Community />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  // {
  //   path: "/create",
  //   element: <Create />,
  // },
  // {
  //   path: "/publish",
  //   element: <Publish />,
  // },
  {
    path: "/workflow",
    element: <Workflow />,
  },
  {
    path: "/my_agent",
    element: <MyAgent />,
  },
  {
    path: "/enterpriseZone",
    element: <EnterpriseZone />,
  },
  {
    path: "/enterprise",
    element: <Enterprise />,
  },
  {
    path: "/knowledge",
    element: <Knowledge />,
  },
  {
    path: "/share",
    element: <Share />,
  },
];

export { routerMap };
