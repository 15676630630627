import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styles from "./index.module.scss";
import { Input, Form, Button, Card, message } from "antd";
import { IRegister } from "@/types/common";
import { toRegister, toLogin, toUsersIslogin } from "@/apis/common";
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "@/store/reducer/userInfo";
import store from "@/store";
import Cookies from "js-cookie";

const Register = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onFinish = (data: any) => {
    setLoading(true);
    toRegister(data).then((res) => {
      if (res?.data?.status === 200) {
        login(data);
      }
    });
  };

  function getTopLevelAndSecondLevelDomain(hostname: any) {
    // 分割主机名
    const parts = hostname.split(".");
    // 确保至少有三部分（例如 sub.example.com）
    if (parts.length > 2) {
      // 提取最后两部分作为顶级域名和二级域名
      return parts.slice(-2).join(".");
    }
    // 如果主机名部分不足，返回原始主机名
    return hostname;
  }
  const hostname = window.location.hostname;
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  const login = (data: any) => {
    setLoading(false);

    toLogin(data).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        message.success(t("common.register.success"));
        navigate("/");

        toUsersIslogin().then((res: any) => {
          store.dispatch({
            ...updateUserInfo(res.data.data),
            isUserInfoLoading: false,
            token: localStorage.getItem("token"),
          });
        });

        console.log(localStorage.getItem("token"), 888);

        Cookies.set("token", localStorage.getItem("token") as string, {
          domain: `${topLevelAndSecondLevelDomain}`,
        });
      }
    });
  };

  const onFinishFailed = () => {};

  return (
    <div className={styles.pages}>
      <div className={styles.login_box}>
        <Form
          name="register"
          className={styles.form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h3 className={styles.welcome}>{t("common.register.title")}</h3>
          <Form.Item<IRegister>
            label={t("common.userName")}
            name="username"
            className={styles.form_item}
            rules={[{ required: true, message: t("common.user.placeHolder") }]}
          >
            <Input className={styles.input} />
          </Form.Item>
          <Form.Item<IRegister>
            label={t("common.password")}
            name="password"
            className={styles.form_item}
            rules={[
              { required: true, message: t("common.password.placeHolder") },
            ]}
          >
            <Input.Password className={styles.input} />
          </Form.Item>
          <Form.Item<IRegister>
            label={t("common.password.confirm")}
            name="confirmPassword"
            className={styles.form_item}
            rules={[
              {
                required: true,
                message: t("common.password.placeHolder"),
              },
            ]}
          >
            <Input.Password className={styles.input} />
          </Form.Item>

          <Button type="primary" htmlType="submit" className={styles.login_btn}>
            {t("common.register")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Register;
