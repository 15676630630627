export default {
  "common.welcome": "Bienvenido a AI Agent",
  "common.password": "Contraseña:",
  "common.password.confirm": "Confirmar contraseña:",
  "common.userName": "Nombre de usuario:",
  "common.login": "Iniciar sesión",
  "common.login.success": "Inicio de sesión exitoso",
  "common.register": "Registrarse",
  "common.register.success": "Registro exitoso",
  "common.register.title": "Registrar cuenta",
  "common.product.name": "Nuevo Ente Inteligente",
  "common.user.placeHolder": "¡Por favor, introduce tu nombre de usuario!",
  "common.password.placeHolder": "¡Por favor, introduce tu contraseña!",
  "common.seeking": "Explorar",
  "common.chat": "Diálogo",
  "common.canvas": "Lienzo",
  "common.community": "Comunidad",
  "common.create": "Creación de Ente Inteligente",
  "common.myAgent": "Mi Ente Inteligente",
  "common.publish": "Publicación de Ente Inteligente",
  "common.submit": "Enviar",
  "common.send": "Mandar",
  "common.tags": "Etiquetas",
  "common.publish.time": "Hora de publicación:",
  "common.update.time": "Última actualización:",
  "common.join.chat": "Unirse al diálogo",
  "common.create.same": "Crear igual",
  "common.agent.singal": "Ente Inteligente individual",
  "common.back": "Regresar",
  "common.chat.default.greetings": "¿En qué puedo ayudarte hoy?",
  "common.you": "Tú",
  "common.edit": "Editar",
  "common.category": "Categoría",
  "common.yes": "Sí",
  "common.no": "No",
  "common.category.recent": "Reciente",
  "common.category.work": "Trabajo",
  "common.category.learning": "Estudio",
  "common.category.lifestyle": "Estilo de vida",

  "create.dialog.agent.desc":
    "Comienza a crear tu Agente, puedes crear directamente o entrar a la página principal de tu Agente",
  "create.normalmode": "Modo Normal",
  "create.professionalmode": "Modo Experto",
  "create.name": "Nombre del Ente Inteligente",
  "create.name.placeholder":
    "Por favor, introduce el nombre del Ente Inteligente",
  "create.introduce": "Introducción al Ente Inteligente",
  "create.introduce.placeholder":
    "Por favor, introduce una introducción al Ente Inteligente",
  "create.provider": "Proveedor del modelo",
  "create.provider.placeholder":
    "Por favor, selecciona un proveedor del modelo",
  "create.clue.edit": "Edición de palabras clave",

  "create.clue.placeholder":
    "Por favor, selecciona un tipo de edición de palabras clave",
  "create.clue.sender": "Emisor",
  "create.humanInput.placeholder":
    "Por favor, selecciona el formato del emisor",
  "create.clue.receiver": "Receptor",
  "create.agent.type": "Tipo de Agente",
  "create.repository.upload": "Subida a la base de conocimientos",
  "create.repository.greetings": "Saludos",
  "create.agent.tools": "Herramientas del Agente",
  "create.reply.count": "Número máximo de respuestas automáticas consecutivas:",
  "create.input.format": "Formato de entrada",
  "create.input.prompts": "Indicaciones del sistema",
  "create.emotional.temperature": "Temperatura:",
  "create.image.create": "Generación de imágenes",
  "create.info.search": "Búsqueda de información",
  "create.image.desc":
    "Puede introducir una descripción, como 'un gato con traje espacial paseando por la luna', y la inteligencia artificial generará una imagen correspondiente según esta descripción. Esta tecnología suele utilizar algoritmos complejos y una gran cantidad de datos de imágenes para entrenar, permitiéndole comprender y transformar la información textual en contenido visual. Esta función puede usarse en varios campos creativos, como arte, diseño de productos, entretenimiento, etc.",
  "create.preview.title": "Depuración y vista previa",
  "create.singal.agent": "Agente Individual",
  "create.muti.agent": "Múltiples Agentes",
  "create.to.detail": "Ver mi Agente creado",
  "create.conversation": "Diálogo",
  "create.image.cover": "Imagen de fondo",
  "create.upload": "Subir archivo",
  "create.greeting.placeholder": "Por favor, introduce un saludo",
  "create.upload.tips":
    "Se admiten 'txt', 'json', 'csv', 'tsv', 'md', 'html', 'htm', 'rtf', 'rst', 'jsonl', 'log', 'xml', 'yaml', 'yml', 'pdf', cada archivo no debe superar los 10MB",
  "create.last.updatetime": "Última vez guardado:",
  "create.category.placeholder": "Por favor, selecciona una categoría",

  "publish.setting.title": "Configuración de publicación",
  "publish.market.title": "Publicar en el mercado",
  "publish.market.desc":
    "Los usuarios pueden encontrar Agentes publicados en el mercado y hacerlos públicos para otros usuarios",
  "publish.api.title": "Generar API",
  "publish.api.desc":
    "Los usuarios pueden desarrollar más a través de la generación de una API",
  "publish.link.title": "Generar enlace de publicación",
  "publish.link.desc":
    "Los usuarios pueden interactuar directamente con el Agente a través de la web",
  "publish.third.title": "Vincular con terceros",
  "publish.third.desc":
    "Los usuarios pueden vincular una App de terceros y usarla en la App una vez configurada",
  "publish.dialog.title": "Configuración de información de publicación",
  "publish.dialog.style": "Selección de estilo de publicación",
  "publish.dialog.stylePlaceHolder":
    "Por favor, selecciona un estilo de publicación",
  "publish.dialog.permission": "Configuración de permisos de publicación",
  "publish.dialog.toPlace": "Publicar en la plaza del Agente",
  "publish.dialog.toDashBoard": "Publicar solo en el centro personal",
  "publish.dialog.edit.permission": "Configuración de permisos de edición",
  "publish.dialog.viewOnly": "Solo vista",
  "publish.dialog.editView": "Editar y ver",
  "publish.dialog.Agreement":
    "Haz clic para leer 'Contrato de creación de la plataforma de Agentes Inteligentes'",
  "publish.copy.success": "Copia exitosa",
  "publish.recommand.tags": "Etiquetas recomendadas",
  "publish.question.example": "Ejemplo de pregunta",
  "publish.editable": "Editable",
  "publish.tags.placeholder": "Por favor, selecciona etiquetas",
  "publish.btntext": "Publicar en el mercado",
  "publish.agreement": "Publicar significa que aceptas cumplir",
  "publish.policy": "'Política de contenido de Syngents.cn'",
  "publish.last.time": "Última vez publicado:",
  chat: {
    withModal: "Conversar con un gran modelo",
    withAgent: "Lista de agentes inteligentes",
    input: {
      placeholder: "Ingrese el contenido que desea conocer",
    },
    delete: {
      title: "¿Eliminar conversación?",
      content: '¿Está seguro de que desea eliminar "<0>{{name}}</0>"?',
    },
  },
  "community.explore": "Descubrir",
  "community.frontier": "Frontera de IA",
  "community.cameby": "Gente que ha venido",
  "community.member": "Miembros",
  "community.online": "Gente en línea",
  "community.watched": "Gente viendo",
  "community.share": "Gente compartiendo",

  myagent: {
    app: "Aplicación",
    repository: "Base de conocimientos",
    tools: "Herramientas",
    newagent: "Crear Nuevo Agente Inteligente",
    subtotal: {
      left: "Total creado",
      right: "Agentes Inteligentes",
    },
    delete: {
      title: "Eliminar Agente",
      content:
        '¿Estás seguro de que quieres eliminar este agente? "<0>{{name}}</0>"',
      success: "Eliminado Exitosamente",
      successText:
        '¡"<0>{{name}}</0>" ha sido eliminado de mi lista de agentes exitosamente!',
      fail: "Fallo al eliminar",
      failText:
        "Fallo al eliminar. Por favor, inténtalo de nuevo más tarde o contacta al administrador.",
    },
  },

  "explore.tab.tool": "Herramientas multimodales",
  "explore.tab.analysis": "Análisis de investigación",
  "explore.tab.writing": "Escritura profesional",
  "explore.tab.coding": "Código",
  "explore.tab.prompt": "Palabras clave",
  "explore.tab.assistant": "Asistente",
  "explore.tab.entertainment": "Entretenimiento y ocio",
  privacy_policy: {
    title: "Términos del Servicio de Syngents.cn",
    introduction: "Introducción",
    welcome_message:
      'Bienvenido a la plataforma Syngents.cn (en adelante "Syngents.cn" o "nosotros"). Estos Términos del Servicio (los "Términos") rigen su acceso y uso de los sitios web, aplicaciones móviles y otros productos y servicios relacionados proporcionados por Syngents.cn (colectivamente denominados el "Servicio"). Al acceder o utilizar nuestro Servicio, usted acepta cumplir con estos Términos y nuestra Política de Privacidad.',
    account_registration_and_security: "Registro de Cuenta y Seguridad",
    provide_accuate_information:
      "Debe proporcionar información veraz, precisa y completa para crear una cuenta y actualizar regularmente su información de cuenta. ",
    responsibility_of_account:
      "Usted es responsable de todas las actividades que ocurran bajo su cuenta. ",
    right_of_refusal:
      "Syngents.cn tiene el derecho de rechazar el servicio, terminar cuentas o eliminar contenido, especialmente en casos de violación de estos Términos.",
    user_content: "Contenido del Usuario ",
    retain_ownership:
      "Usted retiene la propiedad del contenido que envía a través de Syngents.cn, pero otorga a Syngents.cn un derecho no exclusivo, libre de regalías, mundial, perpetuo y totalmente sublicenciable.",
    declare_and_guarantee:
      "Usted declara y garantiza que posee todos los derechos sobre el contenido enviado o ha obtenido los permisos necesarios.",
    user_behavior: "Conducta del Usuario ",
    agreement_of_behavior:
      "Usted acepta no utilizar el Servicio para las siguientes acciones: ",
    prohibited_behaviors_string: `{
  "A": "Publicar o difundir contenido difamatorio, obsceno, pornográfico, vulgar, ofensivo o discriminatorio racialmente. ",
  "B": "Infringir los derechos de propiedad intelectual, secretos comerciales, derechos de privacidad u otros derechos de propiedad. ",
  "C": "Enviar contenido que contenga virus, troyanos, spyware u otro código destructivo. ",
  "D": "Publicar anuncios no autorizados, correo no deseado, cadenas de correo, cualquier otra forma de solicitud no autorizada o cualquier forma de lotería o juego de azar. ",
  "E": "Proporcionar información falsa o engañosa, o suplantar a cualquier persona o entidad. ",
  "F": "Violar cualquier ley o regulación aplicable, incluidas, entre otras, las leyes de derechos de autor, marcas comerciales, privacidad y anti-spam. ",
  "G": "Promover actividades ilegales, dañinas o violentas. ",
  "H": "Hostigar, amenazar, intimidar, acosar o interferir de otra manera con otros usuarios. ",
  "I": "Violar cualquier procedimiento, política o regulación del Servicio. ",
  "J": "Interferir o interrumpir el funcionamiento normal del Servicio o las redes relacionadas. ",
  "K": "Acceder al Servicio, otras cuentas o sistemas informáticos o redes conectadas sin autorización. ",
  "L": "Utilizar robots, arañas, herramientas de rastreo u otros métodos automatizados para acceder al Servicio. ",
  "M": "Descompilar, desensamblar o intentar obtener el código fuente del Servicio de otra manera. ",
  "N": "Modificar, adaptar, crackear o comprometer de otra manera la integridad del Servicio. ",
  "O": "Copiar, replicar, vender, revender o explotar cualquier parte del Servicio sin el permiso explícito por escrito de Syngents.cn."
}`,
    prohibited_behaviors: {
      A: "Publicar o difundir contenido difamatorio, obsceno, pornográfico, vulgar, ofensivo o discriminatorio racialmente. ",
      B: "Infringir los derechos de propiedad intelectual, secretos comerciales, derechos de privacidad u otros derechos de propiedad. ",
      C: "Enviar contenido que contenga virus, troyanos, spyware u otro código destructivo. ",
      D: "Publicar anuncios no autorizados, correo no deseado, cadenas de correo, cualquier otra forma de solicitud no autorizada o cualquier forma de lotería o juego de azar. ",
      E: "Proporcionar información falsa o engañosa, o suplantar a cualquier persona o entidad. ",
      F: "Violar cualquier ley o regulación aplicable, incluidas, entre otras, las leyes de derechos de autor, marcas comerciales, privacidad y anti-spam. ",
      G: "Promover actividades ilegales, dañinas o violentas. ",
      H: "Hostigar, amenazar, intimidar, acosar o interferir de otra manera con otros usuarios. ",
      I: "Violar cualquier procedimiento, política o regulación del Servicio. ",
      J: "Interferir o interrumpir el funcionamiento normal del Servicio o las redes relacionadas. ",
      K: "Acceder al Servicio, otras cuentas o sistemas informáticos o redes conectadas sin autorización. ",
      L: "Utilizar robots, arañas, herramientas de rastreo u otros métodos automatizados para acceder al Servicio. ",
      M: "Descompilar, desensamblar o intentar obtener el código fuente del Servicio de otra manera. ",
      N: "Modificar, adaptar, crackear o comprometer de otra manera la integridad del Servicio. ",
      O: "Copiar, replicar, vender, revender o explotar cualquier parte del Servicio sin el permiso explícito por escrito de Syngents.cn.",
    },
    intellectual_property: "Propiedad Intelectual ",
    protection_of_intellectual_property:
      "El contenido, software y marcas comerciales de Syngents.cn están legalmente protegidos y no pueden ser utilizados sin autorización. ",
    acknowledge_and_agree:
      "Usted reconoce y acepta no infringir los derechos de propiedad intelectual de Syngents.cn o de terceros.",
    disclaimer_and_limitation_of_liability:
      "Descargo de Responsabilidades y Limitación de Responsabilidad ",
    service_provided_as_is:
      'El Servicio se proporciona "tal cual" y Syngents.cn no asume ninguna responsabilidad por interrupciones, errores o pérdida de datos del usuario. ',
    no_liability_for_damage:
      "En ningún caso Syngents.cn será responsable de daños directos, indirectos, especiales, consecuentes o punitivos, incluidos, entre otros, beneficios, buena voluntad, pérdida de uso, pérdida de datos u otros daños intangibles.",
    changes_to_terms: "Cambios en los Términos ",
    right_to_modify_terms:
      "Syngents.cn puede modificar estos Términos en cualquier momento y le notificará a través del sitio web o correo electrónico. ",
    acceptance_of_new_terms:
      "El uso continuado del Servicio indica su aceptación de los nuevos Términos. Si no está de acuerdo con los nuevos Términos, debe dejar de utilizar el Servicio.",
    applicable_law_and_dispute_resolution:
      "Ley Aplicable y Resolución de Disputas",
    governing_law:
      "Estos Términos están regidos por las leyes de la jurisdicción donde opera Syngents.cn.",
    dispute_resolution:
      "Cualquier disputa debe resolverse primero mediante negociación y, si no se resuelve, debe someterse a arbitraje vinculante.",
    miscellaneous: "Otros",
    invalid_terms:
      "Si alguna parte de estos Términos no es ejecutable o inválida, esa parte será limitada o eliminada en la medida mínima necesaria. ",
    no_assignment:
      "No puede asignar o transferir estos Términos ni los derechos y permisos otorgados en virtud de estos Términos.",
    contact_information: "Información de Contacto ",
    contact_us:
      "Si tiene alguna pregunta o inquietud sobre estos Términos, contáctenos en <0>{{email}}</0>",
  },
  workflow: {
    fileName: "اسم الملف",
  },
  status: "الحالة",
  size: "الحجم",
  action: "العملية",
};
