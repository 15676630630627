import React from "react";

const Error = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833374 9.99999C0.833374 4.93738 4.93743 0.833328 10 0.833328C15.0627 0.833328 19.1667 4.93738 19.1667 9.99999C19.1667 15.0626 15.0627 19.1667 10 19.1667C4.93743 19.1667 0.833374 15.0626 0.833374 9.99999ZM6.43804 7.73448L8.70667 10.0031L6.43804 12.2718L7.7344 13.5681L10.003 11.2995L12.2717 13.5681L13.568 12.2718L11.2994 10.0031L13.568 7.73448L12.2717 6.43812L10.003 8.70675L7.7344 6.43812L6.43804 7.73448Z"
        fill="#F53F3F"
      />
    </svg>
  );
};

export default Error;
