export default {
  "common.welcome": "Connexion",
  "common.password": "Mot de passe :",
  "common.password.confirm": "Confirmer :",
  "common.userName": "Nom d'utilisateur :",
  "common.login": "Se connecter",
  "common.login.success": "Connexion réussie",
  "common.register": "Inscription",
  "common.register.success": "Inscription réussie",
  "common.register.title": "Inscription",
  "common.product.name": "Nouvelle Connaissance",
  "common.user.placeHolder": "Nom d'utilisateur",
  "common.password.placeHolder": "Mot de passe",
  "common.seeking": "Exploration",
  "common.chat": "Chat",
  "common.canvas": "Toile",
  "common.community": "Communauté",
  "common.create": "Création",
  "common.myAgent": "Mes Agents",
  "common.publish": "Publication",
  "common.submit": "Soumettre",
  "common.send": "Envoyer",
  "common.tags": "Étiquettes",
  "common.publish.time": "Date de publication :",
  "common.update.time": "Dernière mise à jour :",
  "common.join.chat": "Rejoindre la conversation",
  "common.create.same": "Créer identique",
  "common.agent.singal": "Agent unique",
  "common.back": "Retour",
  "common.chat.default.greetings": "Comment puis-je vous aider ?",
  "common.you": "Vous",
  "common.edit": "Modifier",
  "common.category": "Catégorie",
  "common.yes": "Oui",
  "common.no": "Non",
  "common.category.recent": "Récent",
  "common.category.work": "Travail",
  "common.category.learning": "Apprentissage",
  "common.category.lifestyle": "Style de vie",

  "create.dialog.agent.desc": "Commencez à créer votre Agent.",
  "create.normalmode": "Normal",
  "create.professionalmode": "Professionnel",
  "create.name": "Nom de l'Agent",
  "create.name.placeholder": "Nom de l'Agent",
  "create.introduce": "Présentation de l'Agent",
  "create.introduce.placeholder": "Présentation de l'Agent",
  "create.provider": "Fournisseur de modèle",
  "create.provider.placeholder": "Fournisseur de modèle",
  "create.clue.edit": "Éditer les indices",

  "create.clue.placeholder": "Éditer les indices",
  "create.clue.sender": "Expéditeur",
  "create.humanInput.placeholder": "Format de l'expéditeur",
  "create.clue.receiver": "Destinataire",
  "create.agent.type": "Type d'Agent",
  "create.repository.upload": "Téléchargement de la base de connaissances",
  "create.repository.greetings": "Salutations",
  "create.agent.tools": "Outils de l'Agent",
  "create.reply.count": "Max. réponses automatiques :",
  "create.input.format": "Format d'entrée",
  "create.input.prompts": "Indices d'entrée système",
  "create.emotional.temperature": "Température:",
  "create.image.create": "Génération d'images",
  "create.info.search": "Recherche d'informations",
  "create.image.desc": "Générez des images à partir de descriptions.",
  "create.preview.title": "Débogage et prévisualisation",
  "create.singal.agent": "Agent unique",
  "create.muti.agent": "Agents multiples",
  "create.to.detail": "Voir mes Agents créés",
  "create.conversation": "Catégorie de conversation",
  "create.image.cover": "Image de fond",
  "create.upload": "Téléchargement de fichier",
  "create.greeting.placeholder": "Salutation",
  "create.upload.tips":
    "Formats de fichiers pris en charge : 'txt', 'json', 'csv', etc.",
  "create.last.updatetime": "Dernière mise à jour :",
  "create.category.placeholder": "Catégorie",

  "publish.setting.title": "Paramètres de publication",
  "publish.market.title": "Publication sur le marché",
  "publish.market.desc": "Partagez vos Agents sur le marché.",
  "publish.api.title": "Génération d'API",
  "publish.api.desc": "Générez des API pour le développement.",
  "publish.link.title": "Génération de lien de publication",
  "publish.link.desc": "Interagissez avec vos Agents via une page web.",
  "publish.third.title": "Lier des applications tierces",
  "publish.third.desc": "Liez des applications tierces pour une utilisation.",
  "publish.dialog.title": "Paramètres de publication",
  "publish.dialog.style": "Style de publication",
  "publish.dialog.stylePlaceHolder": "Style de publication",
  "publish.dialog.permission": "Autorisation de publication",
  "publish.dialog.toPlace": "Publier sur la Place de l'Agent",
  "publish.dialog.toDashBoard": "Publier uniquement dans le Centre personnel",
  "publish.dialog.edit.permission": "Autorisation de modification",
  "publish.dialog.viewOnly": "Consultation uniquement",
  "publish.dialog.editView": "Modification et consultation",
  "publish.dialog.Agreement":
    "Lisez l'accord de création de la plateforme de l'Agent",
  "publish.copy.success": "Copie réussie",
  "publish.recommand.tags": "Étiquettes recommandées",
  "publish.question.example": "Exemple de question",
  "publish.editable": "Modifiable",
  "publish.tags.placeholder": "Étiquettes",
  "publish.btntext": "Publier sur le marché",
  "publish.agreement": "La publication implique votre accord",
  "publish.policy": "à la politique de contenu de Sygents.ai",
  "publish.last.time": "Dernière publication :",
  chat: {
    withModal: "Chat avec un grand modèle",
    withAgent: "Liste des agents intelligents",
    input: {
      placeholder: "Entrez le contenu que vous souhaitez connaître",
    },
    delete: {
      title: "Supprimer la conversation ?",
      content: 'Êtes-vous sûr de vouloir supprimer "<0>{{name}}</0>" ?',
    },
  },
  "community.explore": "Explorer",
  "community.frontier": "Frontière de l'IA",
  "community.cameby": "Visiteurs",
  "community.member": "Membres",
  "community.online": "En ligne",
  "community.watched": "Vues",
  "community.share": "Partages",

  myagent: {
    app: "Application",
    repository: "Base de connaissances",
    tools: "Outils",
    newagent: "Créer un nouvel Agent",
    subtotal: {
      left: "Total des Agents créés :",
      right: "agents ont été créés",
    },
    delete: {
      title: "Supprimer l'agent",
      content:
        'Êtes-vous sûr de vouloir supprimer cet agent ? "<0>{{name}}</0>"',
      success: "Supprimé avec succès",
      successText:
        '"<0>{{name}}</0>" a été supprimé de ma liste d\'agents avec succès !',
      fail: "Échec de la suppression",
      failText:
        "Échec de la suppression. Veuillez réessayer ultérieurement ou contacter l'administrateur.",
    },
  },

  "explore.tab.tool": "Outils multimodaux",
  "explore.tab.study": "Études",
  "explore.tab.analysis": "Analyse de recherche",
  "explore.tab.writing": "Rédaction professionnelle",
  "explore.tab.coding": "Codage",
  "explore.tab.prompt": "Mots d'incitation",
  "explore.tab.assistant": "Assistant",
  "explore.tab.entertainment": "Divertissement et Détente",
  privacy_policy: {
    title: "Conditions de service de Syngents.cn",
    introduction: "Introduction",
    welcome_message:
      'Bienvenue sur la plateforme Syngents.cn (ci-après dénommée "Syngents.cn" ou "nous"). Ces Conditions de Service (les "Conditions") régissent votre accès et votre utilisation des sites Web, des applications mobiles et d\'autres produits et services associés fournis par Syngents.cn (collectivement désignés par le terme "Service"). En accédant ou en utilisant notre Service, vous acceptez d\'être lié par ces Conditions et notre Politique de confidentialité.',
    account_registration_and_security: "Enregistrement et sécurité du compte",
    provide_accuate_information:
      "Vous devez fournir des informations vraies, précises et complètes pour créer un compte et mettre régulièrement à jour vos informations de compte.",
    responsibility_of_account:
      "Vous êtes responsable de toutes les activités qui se produisent sous votre compte.",
    right_of_refusal:
      "Syngents.cn se réserve le droit de refuser un service, de résilier des comptes ou de supprimer du contenu, notamment en cas de violation de ces Conditions.",
    user_content: "Contenu de l'utilisateur",
    retain_ownership:
      "Vous conservez la propriété du contenu que vous soumettez via Syngents.cn, mais vous accordez à Syngents.cn un droit non exclusif, exempt de redevances, mondial, perpétuel et entièrement cessible.",
    declare_and_guarantee:
      "Vous déclarez et garantissez que vous possédez tous les droits sur le contenu soumis ou avez obtenu les autorisations nécessaires.",
    user_behavior: "Comportement de l'utilisateur",
    agreement_of_behavior:
      "Vous acceptez de ne pas utiliser le Service pour les actions suivantes :",
    prohibited_behaviors_string: `{
  "A": "Poster ou diffuser du contenu diffamatoire, obscène, pornographique, vulgaire, offensant ou discriminatoire sur le plan racial.",
  "B": "Porter atteinte aux droits de propriété intellectuelle, aux secrets commerciaux, aux droits à la vie privée ou à d'autres droits de propriété.",
  "C": "Soumettre du contenu contenant des virus, des chevaux de Troie, des logiciels espions ou tout autre code destructeur.",
  "D": "Poster des publicités non autorisées, du spam, des chaînes de lettres, toute autre forme de sollicitation non autorisée ou toute forme de loterie ou de jeu de hasard.",
  "E": "Fournir des informations fausses ou trompeuses, ou se faire passer pour une personne ou une entité.",
  "F": "Violent les lois ou réglementations applicables, y compris, mais sans s'y limiter, la loi sur le droit d'auteur, la loi sur les marques de commerce, la loi sur la confidentialité et les lois anti-spam.",
  "G": "Promouvoir des activités illégales, nocives ou violentes.",
  "H": "Harceler, menacer, intimider, tromper ou interférer de quelque manière que ce soit avec d'autres utilisateurs.",
  "I": "Violent les procédures, politiques ou réglementations du Service.",
  "J": "Interférer ou perturber le fonctionnement normal du Service ou des réseaux associés.",
  "K": "Accéder au Service, à d'autres comptes ou à des systèmes informatiques ou réseaux connectés sans autorisation.",
  "L": "Utiliser des robots, des araignées, des outils de grattage ou d'autres méthodes automatisées pour accéder au Service.",
  "M": "Ingénierie inverse, décompilation, désassemblage ou toute autre tentative d'obtenir le code source du Service.",
  "N": "Modifier, adapter, craquer ou compromettre autrement l'intégrité du Service.",
  "O": "Copier, reproduire, vendre, revendre ou exploiter toute partie du Service sans la permission écrite expresse de Syngents.cn."
}`,
    prohibited_behaviors: {
      A: "Poster ou diffuser du contenu diffamatoire, obscène, pornographique, vulgaire, offensant ou discriminatoire sur le plan racial.",
      B: "Porter atteinte aux droits de propriété intellectuelle, aux secrets commerciaux, aux droits à la vie privée ou à d'autres droits de propriété.",
      C: "Soumettre du contenu contenant des virus, des chevaux de Troie, des logiciels espions ou tout autre code destructeur.",
      D: "Poster des publicités non autorisées, du spam, des chaînes de lettres, toute autre forme de sollicitation non autorisée ou toute forme de loterie ou de jeu de hasard.",
      E: "Fournir des informations fausses ou trompeuses, ou se faire passer pour une personne ou une entité.",
      F: "Violent les lois ou réglementations applicables, y compris, mais sans s'y limiter, la loi sur le droit d'auteur, la loi sur les marques de commerce, la loi sur la confidentialité et les lois anti-spam.",
      G: "Promouvoir des activités illégales, nocives ou violentes.",
      H: "Harceler, menacer, intimider, tromper ou interférer de quelque manière que ce soit avec d'autres utilisateurs.",
      I: "Violent les procédures, politiques ou réglementations du Service.",
      J: "Interférer ou perturber le fonctionnement normal du Service ou des réseaux associés.",
      K: "Accéder au Service, à d'autres comptes ou à des systèmes informatiques ou réseaux connectés sans autorisation.",
      L: "Utiliser des robots, des araignées, des outils de grattage ou d'autres méthodes automatisées pour accéder au Service.",
      M: "Ingénierie inverse, décompilation, désassemblage ou toute autre tentative d'obtenir le code source du Service.",
      N: "Modifier, adapter, craquer ou compromettre autrement l'intégrité du Service.",
      O: "Copier, reproduire, vendre, revendre ou exploiter toute partie du Service sans la permission écrite expresse de Syngents.cn.",
    },
    intellectual_property: "Propriété intellectuelle",
    protection_of_intellectual_property:
      "Le contenu, les logiciels et les marques de commerce de Syngents.cn sont légalement protégés et ne peuvent pas être utilisés sans autorisation.",
    acknowledge_and_agree:
      "Vous reconnaissez et acceptez de ne pas porter atteinte aux droits de propriété intellectuelle de Syngents.cn ou de tout tiers.",
    disclaimer_and_limitation_of_liability:
      "Clause de non-responsabilité et limitation de responsabilité",
    service_provided_as_is:
      'Le Service est fourni "tel quel" et Syngents.cn n\'assume aucune responsabilité pour les interruptions, erreurs ou pertes de données utilisateur.',
    no_liability_for_damage:
      "En aucun cas, Syngents.cn ne sera responsable des dommages directs, indirects, spéciaux, consécutifs ou punitifs, y compris, mais sans s'y limiter, les pertes de profits, la perte de clientèle, la perte d'utilisation, la perte de données ou autres pertes immatérielles.",
    changes_to_terms: "Changements de conditions",
    right_to_modify_terms:
      "Syngents.cn peut modifier ces Conditions à tout moment et vous en informera via le site Web ou par e-mail.",
    acceptance_of_new_terms:
      "La poursuite de l'utilisation du Service indique votre acceptation des nouvelles Conditions. Si vous n'acceptez pas les nouvelles Conditions, vous devez cesser d'utiliser le Service.",
    applicable_law_and_dispute_resolution:
      "Droit applicable et résolution des litiges",
    governing_law:
      "Ces Conditions sont régies par les lois de la juridiction où Syngents.cn opère.",
    dispute_resolution:
      "Tout différend doit d'abord être résolu par la négociation, et en l'absence de résolution, doit être soumis à un arbitrage contraignant.",
    miscellaneous: "Divers",
    invalid_terms:
      "Si une partie de ces Conditions est inapplicable ou invalide, cette partie sera limitée ou éliminée dans la mesure minimale nécessaire.",
    no_assignment:
      "Vous ne pouvez pas céder ou transférer ces Conditions ou les droits et autorisations accordés en vertu de ces Conditions.",
    contact_information: "Coordonnées",
    contact_us:
      "Si vous avez des questions ou des préoccupations concernant ces Conditions, veuillez nous contacter à l'adresse <0>{{email}}</0>",
  },
  workflow: {
    fileName: "Nom du fichier",
  },
  status: "Statut",
  size: "Taille",
  action: "Action",
};
