import styles from "./index.module.scss";
import { getUrlParams, deleteAllQuery } from "@/utils/url";
import { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Cate from "./components/Cate";
import { ConfigProvider } from "antd";
// import FolderList from "@/components/Knowledge/component/FolderList";
import { ListType } from "@/components/KnowledgeContent/component/FolderList/type";
import KnowledgeContent from "@/components/KnowledgeContent";
import { getKnowledgeFolderTags } from "@/apis/common";
import useCompanyInfoHooks from "@/hooks/useCompanyHooks";

const Knowledge = () => {
  const [pageType, setPageType] = useState<string>("knowledge");
  const location = useLocation();
  const listRef = useRef<any>(null);
  const [isTabFolder, setIsTabFolder] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("全部");
  const [tags, setTags] = useState([]);
  const { company_id } = useCompanyInfoHooks();

  const [params] = useSearchParams();
  let addFolder = params.getAll("addFolder")[0];

  const category = [
    {
      id: "knowledge",
      name: "我的知识库",
      icon: "icon-a-myknowledge_fill",
    },
  ];

  // 个人版不展示企业共享tab
  if (company_id !== -1) {
    category.push({
      id: "company",
      name: "企业共享",
      icon: "icon-tool_fill",
    });
  }

  const handelAdd = (event?: any) => {
    listRef.current?.handleAddFolderClick(event);
  };

  const handleFilter = (item: string) => {
    setSelectedFilter(item);
  };

  const changeShowCateBtn = (value: string) => {
    setIsTabFolder(value === "folder" ? true : false);
  };

  // 当在知识库列表而非文件夹列表时 点击tab后切换回文件夹列表
  const handleChangePageType = (value: string) => {
    setPageType(value);
    if (!listRef.current.showFolders()) {
      listRef.current.setShowFolders(true);
    }
  };

  useEffect(() => {
    if (addFolder === "1") {
      handelAdd();
      deleteAllQuery();
    }
  }, [addFolder]);

  useEffect(() => {
    const params: any = getUrlParams(window.location.href);
    if (params.pageType) {
      setPageType(params.pageType);
    }
    getKnowledgeFolderTags({ keys: ["knowledge_folder_search_tags"] }).then(
      (res) => {
        const data = res.data?.data?.knowledge_folder_search_tags;
        setTags(data);
      }
    );
  }, [location]);

  return (
    <div className={styles.page}>
      <div className={styles.cate}>
        <Cate
          pageType={pageType}
          setPageType={handleChangePageType}
          category={category}
          handelAdd={handelAdd}
          handleFilter={handleFilter}
          selectedFilter={selectedFilter}
          isTabFolder={isTabFolder}
          tags={tags}
        />
      </div>
      <div className={styles.content}>
        <KnowledgeContent
          type={ListType.page}
          ref={listRef}
          pageType={pageType}
          selectedFilter={selectedFilter}
          changeType={changeShowCateBtn}
        />
      </div>
    </div>
  );
};

export default Knowledge;
