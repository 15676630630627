import { useEffect, useState } from "react";
import { getSpaceList } from "@/apis/common";
import Styles from "./index.module.scss";
import Skeleton from "react-loading-skeleton";
import { Pagination } from "antd";
import "react-loading-skeleton/dist/skeleton.css";
import SpaceItem from "./SpaceItem/index";

const FrontPosition = () => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  const [spaceList, setSpaceList] = useState(new Array(12).fill(undefined));

  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);

  const getSpaceListInfo = () => {
    setLoading(true);
    getSpaceList({
      page_number: pageNo,
      page_size: pageSize,
    }).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        setSpaceList(res.data.data);
        setTotal(res.data.total_items || []);
      }
    });
  };

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  useEffect(() => {
    getSpaceListInfo();
  }, [pageNo]);

  const SkeletonItem = () => {
    return (
      <div className={Styles.skeleton}>
        <Skeleton width="100%" height={300} />
        <div className={Styles.skeleton_info}>
          <div>
            <Skeleton width={30} height={30} borderRadius="50%" />
          </div>
          <div className={Styles.skeleton_info_name}>
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.space}>
      {spaceList.map((item, index) => {
        return (
          <div className={Styles.space_item} key={index}>
            {!loading && item ? (
              <SpaceItem spaceItemData={item} />
            ) : (
              <SkeletonItem />
            )}
          </div>
        );
      })}

      <div className={Styles.pagination}>
        <Pagination
          pageSize={pageSize}
          current={pageNo}
          total={total}
          showSizeChanger={false}
          // hideOnSinglePage
          onChange={changePagination}
        />
      </div>
    </div>
  );
};

export default FrontPosition;
