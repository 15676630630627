import { useEffect, useState, useRef } from "react";
import Styles from "./index.module.scss";
import { toGetSeekingList, toGetTagData, likeStatus } from "@/apis/common";
import Skeleton from "react-loading-skeleton";
import { Pagination} from "antd";
import "react-loading-skeleton/dist/skeleton.css";
import ToolItem from "@/components/ToolItems";
import { unique } from "@/utils/common";
import {
  toGetUserNames,
  pubAgentSearch,
} from "@/apis/common";
import NoContent from "@/components/NoContent";
import AiListSearch from "@/components/AiListSearch";

const SkeletonItem = () => {
  return (
    <div className={Styles.skeleton}>
      <Skeleton width="100%" height={200} />
      <div className={Styles.skeleton_info}>
        <div>
          <Skeleton width={30} height={30} borderRadius="50%" />
        </div>
        <div className={Styles.skeleton_info_name}>
          <Skeleton width={100} />
          <Skeleton width={50} />
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [data, setData] = useState(new Array(15).fill(undefined));
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState<any>({
    id: -1,
  });
  const [activeSubCategory, setActiveSubCategory] = useState<any>([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [tagNameObj, setTagNameObj] = useState({});
  const [userNameObj, setUserNameObj] = useState({});
  const [likeStatusObj, setLikeStatusObj] = useState({});
  const [total, setTotal] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const activeSub = useRef(activeSubCategory);
  const show_mode = 1 // AI助理

  const getSeekingList = () => {
    setLoading(true);
    const param: any = {
      page_number: pageNo,
      page_size: pageSize,
      show_mode,
      tag: activeSub.current,
    };
    if (!!activeCategory && activeCategory.id !== -1) {
      param.category1 = activeCategory.id;
    }
    setData(new Array(15).fill(undefined));
    toGetSeekingList(param).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data;
        setData(data);
        setTotal(res.data.total_items || []);
        getAgentOrUserNames(data);
      }
    });
  };

  const getAgentOrUserNames = (data: any) => {
    let tagList: Array<number> = [];

    data.forEach((item: any, index: number) => {
      if (item.tags && item.tags.length) {
        tagList = [...tagList, ...item.tags];
      }
    });
    let userIdsList: Array<number> = [];
    let agentIdList: Array<number> = [];

    data.forEach((item: any, index: number) => {
      if (item.author) {
        userIdsList = [...userIdsList, item.author];
      }

      if (item && item.agent_id) {
        agentIdList = [...agentIdList, item.id];
      }
    });
    let newUserNameObj: any = {};
    let newLikeStatusObj: any = { ...likeStatusObj };

    if (agentIdList.length) {
      likeStatus({
        pub_agent_ids: agentIdList,
      }).then((res: any) => {
        if (res.data && res.data.status === 200) {
          res.data.data.forEach((item: any, index: number) => {
            newLikeStatusObj[item.pub_agent_id] = item;
          });
        }
      });
      setTimeout(() => {
        setLikeStatusObj(newLikeStatusObj);
      });
    }

    if (userIdsList.length) {
      toGetUserNames({ ids: unique(userIdsList) }).then((res: any) => {
        if (res.data && res.data.status === 200 && res.data.data.length) {
          res.data.data.forEach((item: any, index: number) => {
            newUserNameObj[item.id] = item.username;
          });

          setUserNameObj(newUserNameObj);
        }
      });
    }

    if (tagList.length) {
      toGetTagData({ ids: unique(tagList) }).then((res: any) => {
        if (res.data && res.data.status === 200 && res.data.data.length) {
          const tagObj: any = {};

          res.data.data.forEach((item: any, index: number) => {
            tagObj[item.id] = item.name;
          });
          setTagNameObj(tagObj);
        }
      });
    }
  };

  const keysSearchList = (name?: string) => {
    setPageNo(1);
    setLoading(true);
    pubAgentSearch({
      search: name,
      page_number: pageNo,
      page_size: pageSize,
      show_mode
    }).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data;
        setData(data);
        setTotal(res.data.total_items || []);
        getAgentOrUserNames(data);
      }
    });
  };

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  const getSearchList = (item?: any, data?: any) => {
    setPageNo(1);
    setActiveSubCategory([]);
    setActiveCategory(item);
    if (data){
      setActiveSubCategory(data);
      setTimeout(() => {
        getSeekingList();
      });
    }
  };

  const setAiSearch = (flag: boolean = false) => {
    setIsSearch(flag);
  };

  useEffect(() => {
    activeSub.current = activeSubCategory;
  });

  useEffect(() => {
    if (isSearch) {
      keysSearchList();
    } else {
      getSeekingList();
    }
  }, [activeCategory?.id, pageNo]);

  return (
    <div className={Styles.page}>
      <AiListSearch
        keysSearchList={keysSearchList}
        getSearchList={getSearchList}
        setAiSearch={setAiSearch}
        showMode={show_mode}
        total={total}
      />
      <div className={Styles.page_recommand}>
        {data && data.length > 0 ? (
          data?.map((item, index) => {
            return (
              <div className={Styles.page_recommand_item} key={index}>
                {!loading && item ? (
                  <ToolItem
                    agentInfo={item}
                    tagNameObj={tagNameObj}
                    userNameObj={userNameObj}
                    likeStatusObj={likeStatusObj}
                    setLikeStatusObj={setLikeStatusObj}
                    collectSwitch={true}
                  />
                ) : (
                  <SkeletonItem />
                )}
              </div>
            );
          })
        ) : (
          <NoContent />
        )}
      </div>
      <div className={Styles.pagination}>
        <Pagination
          pageSize={pageSize}
          current={pageNo}
          total={total}
          showSizeChanger={false}
          hideOnSinglePage
          onChange={changePagination}
        />
      </div>
    </div>
  );
};

export default Home;
