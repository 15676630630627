import React, { useEffect, useRef, useState, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Styles from "../../../index.module.scss";
import BgUpload from "@/components/Create/components/BgUpload";
import { useTranslation } from "react-i18next";
import cusStyle from "./index.module.scss";
import { Carousel, Spin } from "antd";
import arrow from "./arrow.svg";
import arrowDark from "./arrow-dark.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import { LoadingOutlined } from "@ant-design/icons";
import classnames from "classnames";

interface CardProps {
  id: any;
  column: number;
  onUploadSuccess: (url: string, column: number) => void;
  onClose: () => void;
}

const ItemType = "CARD";

const Card: React.FC<CardProps> = React.memo(
  ({ id, column, onUploadSuccess, onClose }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemType,
      item: { id, column },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    return (
      <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <div className={Styles.upload_box}>
          <BgUpload
            onUploadSuccess={(url: string) => {
              onUploadSuccess(url, column);
            }}
            handleCloseClick={onClose}
            defaultImageUrl={id}
            type='card'
          />
        </div>
      </div>
    );
  }
);

const DropTarget: React.FC<any> = React.memo(
  ({ rowIndex, columnIndex, onDrop, children }) => {
    const [, drop] = useDrop({
      accept: ItemType,
      drop: (item: any) =>
        onDrop && onDrop(item.row, item.column, rowIndex, columnIndex),
    });

    return <div ref={drop}>{children}</div>;
  }
);

const Board: React.FC<any> = ({
  cards,
  theme,
  carouselRef,
  onUploadSuccess,
  onCardClose,
}) => {
  const [hidePrev, setHidePrev] = useState(true);
  const [hideNext, setHideNext] = useState(false);
  const [init, setInit] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInit(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      {cards.length > 4 ? (
        <div
          style={{
            position: "relative",
            width: 624,
            margin: "0 auto",
            visibility: init ? "visible" : "hidden",
          }}
        >
          <div
            className={`${cusStyle.onPre} ${
              hidePrev ? cusStyle.hide : cusStyle.show
            }`}
          >
            <img
              src={theme === "dark" ? arrowDark : arrow}
              className={cusStyle.onPreIcon}
              onClick={() => carouselRef?.current?.prev()}
              alt=''
            />
          </div>
          <Carousel
            slidesToShow={4}
            dots={false}
            ref={carouselRef}
            infinite={false}
            afterChange={(currentSlide) => {
              if (currentSlide === 0) {
                setHidePrev(true);
              } else {
                setHidePrev(false);
              }
              if (currentSlide === cards.length - 4) {
                setHideNext(true);
              } else {
                setHideNext(false);
              }
            }}
          >
            {cards.map((card: any, columnIndex: any) => (
              <DropTarget
                key={card}
                columnIndex={columnIndex}
                onDrop={() => {}}
              >
                <Card
                  key={card}
                  id={card}
                  column={columnIndex}
                  onUploadSuccess={onUploadSuccess}
                  onClose={() => onCardClose(columnIndex)}
                />
              </DropTarget>
            ))}
          </Carousel>
          <div
            className={`${cusStyle.onNext} ${
              hideNext ? cusStyle.hide : cusStyle.show
            }`}
          >
            <img
              src={theme === "dark" ? arrowDark : arrow}
              className={cusStyle.onPreIcon}
              onClick={() => carouselRef?.current?.next()}
              alt=''
            />
          </div>
        </div>
      ) : (
        <div
          className='row'
          style={{ display: "flex", width: 650, flexWrap: "wrap", gap: 8 }}
        >
          {cards.map((card: any, columnIndex: any) => (
            <DropTarget key={card} columnIndex={columnIndex}>
              <Card
                key={card}
                id={card}
                column={columnIndex}
                onUploadSuccess={onUploadSuccess}
                onClose={() => onCardClose(columnIndex)}
              />
            </DropTarget>
          ))}
        </div>
      )}
    </div>
  );
};

const DragAndDropSort = ({
  createInfo,
  changeCreateInfo,
  needLoading,
}: any) => {
  const [cards, setCards] = useState<any>([]);
  const { t } = useTranslation();
  const carouselRef = useRef<any>(null);
  const theme = useThemeHooks();

  const onUploadSuccess = useCallback(
    (url: string, column: number) => {
      const newCards = [...cards];
      newCards[column] = url;
      setCards(newCards);
      const card_images = newCards.filter((item: string) => item !== "");
      changeCreateInfo(
        ["flow_config.card_images", "avatar_url"],
        [card_images, card_images?.[0] || ""]
      );
    },
    [cards, changeCreateInfo]
  );

  const onCardClose = useCallback(
    (column: number) => {
      const newCards = [...cards];
      newCards.splice(column, 1);
      setCards(newCards);
      const card_images = newCards.filter((item: string) => item !== "");
      changeCreateInfo(
        ["flow_config.card_images", "avatar_url"],
        [card_images, card_images?.[0] || ""]
      );
    },
    [cards, changeCreateInfo]
  );

  useEffect(() => {
    const images =
      (createInfo?.flow_config && createInfo?.flow_config?.card_images) || [];
    if (images.length < 1 && images.every((item: any) => !!item))
      images.push("");
    setCards([...images]);
  }, [createInfo]);

  return (
    <>
      <div className={cusStyle.header}>
        <div>
          <span className={cusStyle.cardTextWrap}>
            {t("create.card.cardContent")}
          </span>
          <span className={cusStyle.cardLimitTip}>
            {t("create.card.maxCardNumber")}
          </span>
        </div>
        {cards.length < 9 && cards?.length > 0 && cards?.[0] ? (
          <div>
            <BgUpload
              isCustomType
              onUploadSuccess={(url: string) => {
                onUploadSuccess(url, cards.length);
              }}
            >
              <div className={cusStyle.uploadText}>
                <span
                  className={classnames(
                    cusStyle.img,
                    "iconfont",
                    "icon-increase_line"
                  )}
                ></span>
                {t("create.card.uploadMore")}
              </div>
            </BgUpload>
          </div>
        ) : null}
      </div>
      <DndProvider backend={HTML5Backend}>
        {needLoading ? (
          <div className={Styles.spin}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <Board
            cards={cards}
            theme={theme}
            carouselRef={carouselRef}
            onUploadSuccess={onUploadSuccess}
            onCardClose={onCardClose}
          />
        )}
      </DndProvider>
    </>
  );
};

export default DragAndDropSort;
