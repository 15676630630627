import instance from "../request/interceptor";
import blogInstance from "../request/blogInterceptor";
import {
  Ilogin,
  IRegister,
  IAgentInfo,
  Ipage,
  IMsg,
  IPublishInfo,
  IUserInfo,
  ITagData,
  ICreateModalChat,
  ICreateMyAgentChat,
  ICreatePubAgentChat,
  ITopicHistory,
  ITabModalList,
  ITabAgentList,
  ITabPubAgentList,
  ISendLLm,
  IUserIdList,
  ISeeking,
  Ilike,
  Ilikes,
  IlikeStatus,
  IquestionList,
  ICompany,
  IWokeList,
} from "@/types/common";
import hostArr from "@/proxy";
const mode: string = process.env.REACT_APP_ENV || "prod";
let baseWs: any = hostArr[mode].wsHttp;

// 登录
export const toLogin = (data: Ilogin) => {
  return instance.post("/users/login/", data);
};

// 注册
export const toRegister = (data: IRegister) => {
  return instance.post("/users/create/", data);
};

// userInfo
export const toUsersIslogin = () => {
  return instance.post("/users/info/");
};

// 创建Agent
export const toCreateAgent = (data: IAgentInfo) => {
  return instance.post("/aichat/agent/edit/add/", data);
};

// 更新Agent信息
export const toUpdateAgent = (data: IAgentInfo) => {
  return instance.post("/aichat/agent/edit/update/", data);
};

// 获取私有agent 信息
export const toGetAgentInfo = (data: { id?: number }) => {
  return instance.post("/aichat/agent/edit/get/", data);
};

// 获取公有agent 信息
export const toGetAgentPublicInfo = (data: { id?: number }) => {
  return instance.post("/aichat/pub_agent/get/", data);
};

// 获取我的agent 列表
export const toGetAgentList = (data: Ipage) => {
  return instance.post("/aichat/agent/edit/list/", data);
};

// 喜欢 我喜欢的公共Agent 列表
export const toGetLikeAgentList = (data: Ipage) => {
  return instance.post("/aichat/like/pub_agent/list/", data);
};

// 收藏 我收藏的公共Agent 列表
export const toGetCollectAgentList = (data: Ipage) => {
  return instance.post("/aichat/collect/pub_agent/list/", data);
};

// agent 预览聊天
export const previewSendMsg = (data: IMsg) => {
  return instance.post("/aichat/agent/edit/chat/", data);
};

// agent 发布
export const toPublish = (data: IPublishInfo) => {
  return instance.post("/aichat/agent/edit/publish/", data);
};

// agent 删除
export const toDeleteAgent = (data: { id?: number }) => {
  return instance.post("/aichat/agent/edit/delete/", data);
};

// agent 回退
export const toRollbackAgent = (data: { agent_id?: number }) => {
  return instance.post("/aichat/agent/edit/rollback/", data);
};

// agent 分类下拉列表
export const getAgentCategory = (data: { show_mode?: number }) => {
  return instance.post("/aichat/category/list/", data);
};

// 社区列表
export const getSpaceList = (data: Ipage) => {
  return instance.post("/aichat/community/list/", data);
};

// 前沿信息列表
export const getArticleList = (data: Ipage) => {
  return instance.post("/aichat/article/list/", data);
};

// 探索
export const toGetSeekingList = (data: any) => {
  return instance.post("/aichat/pub_agent/list/", data);
};

// tag字典
export const toGetTagData = (data: ITagData) => {
  return instance.post("/aichat/tag/dict/", data);
};

// 我对话过的Agent列表
export const toGetTalkedPubAgentList = (data: Ipage) => {
  return instance.post("/aichat/pub_agent/relation/list/", data);
};

// 删除我对话过的Agent列表
export const toDeleteTalkedPubAgent = (data: { pub_agent_id?: number }) => {
  return instance.post("/aichat/pub_agent/relation/delete/", data);
};

// 大模型对话列表
export const toGetModalList = (data: ITabModalList) => {
  return instance.post("/aichat/llm/topic/list/", data);
};

// 我的Agent对话列表
export const toGetMyAgentList = (data: ITabAgentList) => {
  return instance.post("/aichat/agent/topic/list/", data);
};

// 公共Agent对话列表
export const toGetPubAgentList = (data: ITabPubAgentList) => {
  return instance.post("/aichat/pub_agent/topic/list/", data);
};

// 创建大模型对话
export const toCreateModal = (data: ICreateModalChat) => {
  return instance.post("/aichat/llm/topic/add/", data);
};

// 创建我的Agent对话
export const toCreateMyAgent = (data: ICreateMyAgentChat) => {
  return instance.post("/aichat/agent/topic/add/", data);
};

// 创建公共Agent对话
export const toCreatePubAgent = (data: ICreatePubAgentChat) => {
  return instance.post("/aichat/pub_agent/topic/add/", data);
};

// 大模型对话历史
export const toGetModalHistory = (data: ITopicHistory) => {
  return instance.post("/aichat/llm/message/list/", data);
};

// Agent对话历史
export const toGetMyAgentHistory = (data: ITopicHistory) => {
  return instance.post("/aichat/agent/message/list/", data);
};

// 公有Agent对话历史
export const toGetPubAgentHistory = (data: ITopicHistory) => {
  return instance.post("/aichat/pub_agent/message/list/", data);
};

// 与大模型对话
export const toChatWithLLm = (data: ISendLLm) => {
  return instance.post("/aichat/llm/send/", data);
};

// 通过uid批量获取用户名
export const toGetUserNames = (data: IUserIdList) => {
  return instance.post("/users/dict/", data);
};

// 获取登陆验证码
export const toGetLoginCode = (data: {
  destination?: string;
  code_type: string;
}) => {
  return instance.post("/users/login/getcode/", data);
};

// 获取手机绑定验证码
export const toGetBindingCode = (data: {
  destination?: string;
  code_type: string;
  login_type: string;
  third_login_unique_id: string;
}) => {
  return instance.post("/users/third_login/binding_get_code/", data);
};

// 邮箱登录 校验code
export const toCheckLoginCode = (data: {
  code_type: string;
  code: string;
  destination: string;
}) => {
  return instance.post("/users/login/checkcode/", data);
};

// 手机绑定 校验code
export const toCheckBindCode = (data: {
  login_type: string;
  code: string;
  destination: string;
  third_login_unique_id: string;
}) => {
  return instance.post("/users/third_login/binding_check_code/", data);
};

// 三方登录回调接口
export const loginCallBack = (data: { login_type: string; token: string }) => {
  return instance.post("/users/third_login/login_call_back_b/", data);
};

// 点击喜欢
export const like = (data: Ilike) => {
  return instance.post("/aichat/like/pub_agent/add/", data);
};

// 取消喜欢
export const disLike = (data: Ilikes) => {
  return instance.post("/aichat/like/pub_agent/delete/", data);
};

// 获取对应agent_id 的喜欢人数列表
export const likeStatus = (data: IlikeStatus) => {
  return instance.post("/aichat/pub_agent/expend/dict/", data);
};

// 获取首页问题列表
export const mainQuestionList = (data: IquestionList) => {
  return instance.post("/aichat/common_problem/list/", data);
};

// 获取知识库列表
export const toGetKnowledgeList = (data: Ipage) => {
  return instance.post("/aichat/knowledge/list/", data);
};

// 知识库删除
export const deleteKnowledge = (data: { ids?: Array<number> }) => {
  return instance.post("/aichat/knowledge/delete/", data);
};

// 获取用户当前企业，也就是上次登陆企业
export const getUserCurrentCompany = () => {
  return instance.post("/users/company/current/");
};

// 用户切换企业
export const userCompanySwitch = (company_id: number) => {
  return instance.post("/users/company/switch/", { company_id });
};

// 获取当前登陆用户的所有企业;
export const getUserCompanyList = () => {
  return instance.post("/users/company/list/");
};

// 公共配置接口
export const getConfig = (data: { keys: Array<string> }) => {
  return instance.post("/users/config/dict/", data);
};

// 收藏 公共Agent 添加
export const addPubAgent = (data: Ilike) => {
  return instance.post("/aichat/collect/pub_agent/add/", data);
};

// 收藏 公共Agent 删除
export const deletePubAgent = (data: Ilikes) => {
  return instance.post("/aichat/collect/pub_agent/delete/", data);
};

// 企业用户列表
export const getMemberList = (data: ICompany) => {
  return instance.post("/users/companys/member/list/", data);
};

// 获取企业信息
export const getMemberInfo = (data: ICompany) => {
  return instance.post("/users/companys/info/", data);
};

// 邀请成员
export const inviteMember = (data: ICompany) => {
  return instance.post("/users/companys/member/invite/", data);
};

// 删除成员
export const delMember = (data: ICompany) => {
  return instance.post("/users/companys/member/del/", data);
};

// 获取分类文章列表
export const getCategoryArticleList = (data: any) => {
  return blogInstance.get("/api/public/article", {
    params: data,
  });
};

// 获取文章详情
export const getCategoryArticle = (data: any) => {
  return blogInstance.get(`/api/public/article/${data.id}`, {});
};

// 获取tags
export const getTags = () => {
  return blogInstance.get("/api/public/tag", {});
};

export const getJsonConfig = (data: any) => {
  return instance.post("/users/config/json/", data);
};

// 获取配置
export const getAdvanceConfig = (data: any) => {
  return instance.post("/aichat/learning/config/get/", data);
};

// 保存配置
export const saveAdvanceConfig = (data: any) => {
  return instance.post("/aichat/learning/config/save/", data);
};

// AI助理搜索
export const pubAgentSearch = (data: any) => {
  return instance.post("/aichat/pub_agent/search/", data);
};

// 知识库文件夹新增
export const createKnowledgeFolder = (data: {
  name: string;
  desc?: string;
  pub_scope: number;
  tags?: string;
  img_url?: string;
}) => {
  return instance.post("/aichat/knowledge/folder/add/", data);
};

// 知识库文件夹列表
export const getKnowledgeFolderList = (data: Ipage) => {
  return instance.post("/aichat/knowledge/folder/list/", data);
};

// 知识库文件夹列表
export const getKnowledgeFolderTags = (data: { keys: string[] }) => {
  return instance.post("/users/config/json/", data);
};

// 知识库文件夹删除
export const deleteKnowledgeFolder = (data: { id: number }) => {
  return instance.post("/aichat/knowledge/folder/delete/", data);
};

// 知识库文件夹修改
export const updateKnowledgeFolder = (data: {
  id: number;
  name: string;
  pub_scope: number;
  tags?: string;
  img_url?: string;
}) => {
  return instance.post("/aichat/knowledge/folder/update/", data);
};

// 知识库文件夹详情
export const getKnowledgeFolderDetail = (data: { id: number }) => {
  return instance.post("/aichat/knowledge/folder/get/", data);
};

export const addKnowledgeContent = (data: {
  file_name: string;
  file: string;
  folder_id: number;
  file_size?: number;
  file_type?: string;
  file_source: number;
  file_category: number;
}) => {
  return instance.post("/aichat/knowledge/upload/", data);
};

export const addKnowledgeContentList = (
  data: [
    {
      file_name: string;
      file: string;
      folder_id: number;
      file_size?: number;
      file_type?: string;
      file_source: number;
      file_category: number;
    }
  ]
) => {
  return instance.post("/aichat/knowledge/add/", data);
};

// 工作流列表
export const getWorkflowList = (data: IWokeList) => {
  return instance.post("/aichat/workflow/list/", data);
};

// 工作流动态表单
export const getWorkflowInputForm = (data: IWokeList) => {
  return instance.get(
    `${baseWs}/api/workflow/${data?.workflow_id}/input_form`,
    {}
  );
};

// 获取微信二维码(导航栏微信加群功能)
export const getWechatQRCodeApi = (data: any) => {
  return instance.post("/users/config/dict/", data);
};

// 获取对话中分享聊天的唯一uuid
export const getShareUuid = (data: any) => {
  return instance.post("/aichat/chat/share/generate_info/", data);
};

// 根据uuid获取分享的聊天内容
export const getHistoryByUuid = (data: any) => {
  return instance.post("/aichat/chat/share/list/", data);
};

// 超级助理预设问题
export const getPresetQuestion = (data: any) => {
  return instance.post(`/aichat/preset_question/list/`, data);
};
