import React from "react";

const Tick = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833374 10C0.833374 4.93739 4.93743 0.833332 10 0.833332C15.0627 0.833332 19.1667 4.93739 19.1667 10C19.1667 15.0626 15.0627 19.1667 10 19.1667C4.93743 19.1667 0.833374 15.0626 0.833374 10ZM9.08339 13.588L15.0024 7.66903L13.706 6.37266L9.08339 10.9953L6.52323 8.43516L5.22687 9.73153L9.08339 13.588Z"
        fill="url(#paint0_linear_3566_524)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3566_524"
          x1="0.833374"
          y1="0.833332"
          x2="19.1667"
          y2="0.833332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C7FF03" />
          <stop offset="1" stopColor="#03FFF0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Tick;
