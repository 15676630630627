import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ConfigProvider, Modal, Button } from "antd";
import KnowledgeContentFolderList from "@/components/KnowledgeContent/component/FolderList";
import { ListType } from "@/components/KnowledgeContent/component/FolderList/type";
import useCompanyInfoHooks from "@/hooks/useCompanyHooks";
import Cate from "@/pages/Knowledge/components/Cate";
import style from "./index.module.scss";
import { isMobile } from "@/utils/common";

interface RelateKnowledgeBaseModalProps {
  setCurrentFolderList: (list: any) => void;
  curFolderList: any;
}

export interface RelateKnowledgeBaseModalRef {
  show: () => void;
  hide: () => void;
}

const RelateKnowledgeBaseModal = forwardRef<
  RelateKnowledgeBaseModalRef,
  RelateKnowledgeBaseModalProps
>((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [folderList, setFolderList] = useState<any>([]);
  const { company_id } = useCompanyInfoHooks();
  const [pageType, setPageType] = useState<string>("knowledge");
  const listRef = useRef<any>(null);
  const category = [
    {
      id: "knowledge",
      name: "我的知识库",
    },
    ...(company_id !== -1
      ? [
          {
            id: "company",
            name: "企业共享",
          },
        ]
      : []),
  ];

  const handleOkClick = () => {
    console.log(folderList, "------f-----");
    props.setCurrentFolderList(folderList);
    setVisible(false);
  };

  const requiredData = useMemo(
    () => ({
      tag: "全部",
      pub_scope: pageType === "company" ? 2 : 1,
    }),
    [pageType]
  );

  const handelAdd = (event: any) => {
    window.open("/knowledge?addFolder=1");
  };

  useImperativeHandle(ref, () => ({
    show: () => setVisible(true),
    hide: () => setVisible(false),
  }));

  useEffect(() => {
    setFolderList(props.curFolderList);
  }, [props.curFolderList]);

  const handleFolderClick = (
    value: number,
    name?: string,
    user_id?: number
  ) => {
    if (isMobile) {
      setFolderList([{ id: value, name, user_id }]);
    } else {
      if (folderList.some((item: any) => item.id === value)) {
        setFolderList(folderList.filter((item: any) => item.id !== value));
      } else {
        setFolderList([
          ...folderList,
          {
            id: value,
            name,
            user_id,
          },
        ]);
      }
    }
  };

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          colorPrimary: "#007890",
        },
        components: {
          Button: {
            defaultBorderColor: "#007890",
            defaultColor: "#007890",
            primaryShadow: "none",
          },
          Input: {
            activeShadow: "none",
          },
        },
      }}
    >
      <Modal
        title='选择关联知识库文件'
        open={visible}
        onOk={handleOkClick}
        onCancel={() => setVisible(false)}
        width={920}
        style={{ height: 700 }}
        className={style.page}
        footer={[
          <div>
            {/* {isMobile && (
              <>
                已选择
                <p className={style.footerCount}>{folderList.length}</p>
                个文件夹
              </>
            )} */}
          </div>,
          <div className={style.footerBtns}>
            <Button key='back' onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button key='submit' type='primary' onClick={handleOkClick}>
              确定
            </Button>
          </div>,
        ]}
      >
        <Cate
          pageType={pageType}
          category={category}
          handelAdd={handelAdd}
          setPageType={setPageType}
          needFilterBtn={false}
          isTabFolder={true}
        />
        <div
          style={{
            paddingTop: "16px",
            maxHeight: "55vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <KnowledgeContentFolderList
            ref={listRef}
            onFolderClick={handleFolderClick}
            requiredData={requiredData}
            selectedFolderList={folderList}
            isModal={true}
            type={ListType.insert}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
});

export default RelateKnowledgeBaseModal;
