export const formatFileSize = (fileSizeInBytes: number) => {
  const KB = 1024;
  const MB = 1024 * 1024;

  if (fileSizeInBytes < KB) {
    return `${fileSizeInBytes} B`;
  } else if (fileSizeInBytes < MB) {
    const fileSizeInKB = (fileSizeInBytes / KB).toFixed(2);
    return `${fileSizeInKB} KB`;
  } else {
    const fileSizeInMB = (fileSizeInBytes / MB).toFixed(2);
    return `${fileSizeInMB} MB`;
  }
};
