import _ from "lodash";
import classnames from "classnames";
import Styles from "../../index.module.scss";
import {
  Form,
  Input,
  Select,
  Card,
  Slider,
  Switch,
  Button,
  Spin,
  Tooltip,
  message,
  Modal,
} from "antd";
import { createFieldType } from "@/types/common";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import {
  modalList,
  agentTypeList,
  humanInputModeList,
  skillsAll,
} from "../../list";
import SelectArrowPic from "@/assets/common/select-arrow.svg";
import { createImageUrl, getImageUrl } from "@/utils/common";
import BgUpload from "@/components/Create/components/BgUpload";
import FileUpload from "../../components/FileUpload";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import type { UploadFile } from "antd/es/upload/interface";
import ProgressForm from "../NormalMode/components/Progress";
import UploadIcon from "@/assets/workflow/upload.svg";
import curStyle from "./index.module.scss";
import QuestionPic from "@/assets/common/question.svg";
import AddAgentIc from "@/assets/workflow/add-agent.svg";
import { useSelector } from "react-redux";
import RelateKnowledgeBaseModal from "@/components/relateKnowledgeBaseModal";
import FolderListTable from "../../components/FolderListTable";
import { getKnowledgeFolderList } from "@/apis/common";

const AgentIcon1 = createImageUrl('workflow/agent01.png');
const AgentIcon2 = createImageUrl('workflow/agent02.png');
const AgentIcon3 = createImageUrl('workflow/agent03.png');
const AgentIcon4 = createImageUrl('workflow/agent04.png');
const AgentIcon5 = createImageUrl('workflow/agent05.png');

const agentList = [AgentIcon1, AgentIcon2, AgentIcon3, AgentIcon4, AgentIcon5];

const { Option } = Select;
const { TextArea } = Input;
const ProfessionalMode = forwardRef(
  (
    {
      createInfo,
      changeCreateInfo,
      switchSkills,
      updateAgent,
      saveStatus,
    }: any,
    ref
  ) => {
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation();
    const [curFileLists, setCurFileLists] = useState<UploadFile[]>([]);
    const [needLoading, setNeedLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);
    const [hideIdList, setHideIdList] = useState<number[]>([]);

    const onUploadSuccess = (url: string) => {
      changeCreateInfo("avatar_url", url);
    };

    const [uploadAgentIcon, setUploadAgentIcon] = useState<any>({});

    const relateKnowledgeBaseModalRef = useRef<any>();
    const [curFolderList, setCurFolderList] = useState<any>([]);

    const [hasGetFolderList, setHasGetFolderList] = useState(false);

    const getKnowledgeFolderListIds = async () => {
      if (
        !createInfo?.flow_config?.knowledge_folder ||
        !createInfo?.flow_config?.knowledge_folder.length
      ) {
        return;
      }
      let ids = createInfo?.flow_config?.knowledge_folder.map(
        (item: any) => item.id
      );
      getKnowledgeFolderList({
        ids
      } as any).then((res: any) => {
        if (res && res.data && res.data.status === 200) {
          console.log(res.data, "res.data.data");
          setCurFolderList(
            res.data.data.map((item: any) => {
              return {
                id: item.id,
                name: item.name,
                user_id: item?.user_id,
              };
            })
          );
          setHasGetFolderList(true);
        }
      });
    };

    const getFileLists = (data: any) => {
      setCurFileLists(
        data.filter(
          (item: any) =>
            // 把status为done的状态给去掉
            item?.status !== "done"
        )
      );
    };

    const deleteAgent = (index: number) => {
      const newReceiver = createInfo?.flow_config?.receiver || [];
      newReceiver.splice(index, 1);
      changeCreateInfo("flow_config.receiver", newReceiver);
    };

    useImperativeHandle(ref, () => ({
      validateFn,
    }));

    const agentRef = useRef<any>();
    const systemMessageRef = useRef<any>();
    const receiverRef = useRef<any>();

    const [agentTop, setAgentTop] = useState(0);
    const [systemTop, setSystemTop] = useState(0);
    const [receiverTop, setReceiverTop] = useState(0);

    useEffect(() => {
      const { top: agentRefTop } = agentRef?.current?.getBoundingClientRect();
      const { top: systemMessageRefTop } =
        systemMessageRef?.current?.getBoundingClientRect();
      const { top: receiverRefTop } =
        receiverRef?.current?.getBoundingClientRect();
      setAgentTop(agentRefTop);
      setSystemTop(systemMessageRefTop);
      setReceiverTop(receiverRefTop);
    }, []);

    const validateFn = (fn: any, ref: any) => {
      form
        .validateFields()
        .then((res) => {
          if (hasDuplicateNames(createInfo.flow_config.receiver)) {
            ref?.scrollTo({ top: receiverTop - 100, behavior: "smooth" });
            message.error(t("create.receiverNameRepeat"));
            return;
          }
          fn(true, res);
        })
        .catch((error: any) => {
          const { errorFields } = error;

          if (errorFields.length) {
            const errorTop =
              errorFields[0].name[0] === "agentName" ? agentTop : systemTop;
            ref?.scrollTo({ top: errorTop - 100, behavior: "smooth" });
          }

          fn(false, error);
        });
    };

    const addAgent = () => {
      const newReceiver = createInfo?.flow_config?.receiver || [];
      newReceiver.push({
        human_input_mode: "NEVER", //人工输入模式
        max_consecutive_auto_reply: 5, //最大回复次数
        system_message: "", //系统输入提示词
        model: "gpt-4-1106-preview", //模型供应商
        temperature: 0.2, //情感温度
        name: `助理智能体${newReceiver.length + 1}`, //昵称
        avatar_url: "",
        id: newReceiver.length + 1,
      });

      changeCreateInfo("flow_config.receiver", newReceiver);
    };
    useEffect(() => {
      setNeedLoading(saveStatus === "Loading");
    }, [saveStatus]);

    const formRef = useRef<any>();
    const hasDuplicateNames = (array: any) => {
      const names = array.map((item: any) => item.name);
      const uniqueNames = new Set(names);
      return names.length !== uniqueNames.size;
    };
    useEffect(() => {
      // 当表单字段值改变时，手动调用 setFieldsValue 来更新表单字段的值
      formRef?.current.setFieldsValue({
        agentName: createInfo.name,
        systemMessage: createInfo.flow_config.sender.system_message,
      });
      if (!hasGetFolderList) {
        getKnowledgeFolderListIds();
      }
    }, [createInfo]);

    const handleLinkClick = () => {
      relateKnowledgeBaseModalRef?.current?.show();
    };
    const knowledgeFiles = useSelector(
      (state: any) => state.knowledgeFiles.knowledgeFiles
    );
    const from = useSelector((state: any) => state.knowledgeFiles.from);

    const updateHideIdList = () => {
      if (from === "knowledge") {
        const newHideIdList = [...hideIdList];
        knowledgeFiles.forEach((file: any) => {
          const index = newHideIdList.indexOf(file.id);
          if (index !== -1) {
            newHideIdList.splice(index, 1);
          }
        });
        setHideIdList(newHideIdList);
      }
    };

    useEffect(() => {
      updateHideIdList();
    }, [knowledgeFiles]);

    useEffect(() => {
      changeCreateInfo(
        "flow_config.knowledge_folder",
        curFolderList.map((item: any) => {
          return {
            id: item?.id,
            user_id: item?.user_id,
          };
        })
      );
    }, [curFolderList]);

    return (
      <div
        className={classnames(
          Styles.create_setting_inner,
          Styles.professional_mode,
          "scroll-hide"
        )}
      >
        <Form
          layout="horizontal"
          form={form}
          scrollToFirstError
          ref={formRef}
          autoComplete={"off"}
        >
          <div className={Styles.upload_box}>
            <div className={Styles.form_item_title}>
              {t("create.image.cover")}
            </div>
            {needLoading ? (
              <div className={Styles.spin}>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </div>
            ) : (
              <BgUpload
                onUploadSuccess={onUploadSuccess}
                defaultImageUrl={getImageUrl(createInfo.avatar_url)}
              />
            )}
          </div>
          <div className={Styles.form_item_title} ref={agentRef}>
            {t("create.name.agent")}
          </div>
          <Form.Item<createFieldType>
            label=""
            name="agentName"
            className={Styles.form_item}
            rules={[{ required: true, message: t("create.name.placeholder") }]}
          >
            <Input
              value={createInfo.name}
              placeholder={t("create.name.placeholder")}
              className={Styles.input}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                changeCreateInfo("name", e.target.value);
              }}
            ></Input>
          </Form.Item>
          <div className={Styles.form_item_title}>
            {t("create.introduce.agent")}
          </div>
          <Form.Item<createFieldType>
            label=""
            className={Styles.form_item}
            rules={[
              { required: false, message: t("create.introduce.placeholder") },
            ]}
          >
            {/* <Input
              value={createInfo.intro}
              placeholder={t("create.introduce.placeholder")}
              className={Styles.input}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                changeCreateInfo("intro", e.target.value);
              }}
            ></Input> */}
            <TextArea
              rows={4}
              placeholder={t("create.introduce.placeholder")}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                changeCreateInfo("intro", e.target.value);
              }}
              value={createInfo.intro}
              className={Styles.textarea}
            />
          </Form.Item>
          {/* <div className={Styles.form_item_title}>{t("create.agent.type")}</div>
        <Form.Item<createFieldType>
          label=""
          className={Styles.form_item}
          rules={[
            { required: true, message: t("create.provider.placeholder") },
          ]}
        >
          <Select
            value={createInfo.agent_type}
            placeholder={t("create.provider.placeholder")}
            className={Styles.select}
            onChange={(value: string) => {
              changeCreateInfo("agent_type", value);
            }}
          >
            {agentTypeList.map((item, index) => {
              return (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item> */}
          <div className={`${Styles.form_item_title} ${curStyle.questionWrap}`}>
            {t("create.provider.model")}
            <Tooltip title={t("create.provider.modelDesc")}>
              <img
                src={QuestionPic}
                className={Styles.question}
                loading="lazy"
              ></img>
            </Tooltip>
          </div>
          <Form.Item<createFieldType>
            label=""
            className={Styles.form_item}
            rules={[
              { required: true, message: t("create.provider.placeholder") },
            ]}
          >
            <Select
              value={
                (createInfo.flow_config.receiver[0].model === "gpt-3.5-turbo"
                  ? modalList[0].modalId
                  : createInfo.flow_config.receiver[0].model) +
                (createInfo?.ai_framework || 101)
              }
              placeholder={t("create.provider.placeholder")}
              className={Styles.select}
              onChange={(value: string, option: any) => {
                changeCreateInfo(
                  ["ai_framework", "flow_config.receiver[0].model"],
                  [option.frameId, option.modalId]
                );
                // changeCreateInfo("flow_config.receiver[0].model", value);
              }}
            >
              {modalList.map((item, index) => {
                return (
                  <Option
                    key={item.value}
                    value={item.value}
                    frameId={item.frameId}
                    modalId={item.modalId}
                  >
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className={Styles.form_item_title}>{t("create.clue.edit")}</div>
          <div className={`${Styles.form_item_title} ${curStyle.questionWrap}`}>
            {t("create.clue.sender")}
            <Tooltip title={t("create.clue.senderTip")}>
              <img
                src={QuestionPic}
                className={Styles.question}
                loading="lazy"
              ></img>
            </Tooltip>
          </div>
          {/* <Card className={Styles.sender}> */}
          <div className={Styles.form_group}>
            <div className={Styles.form_group_item}>
              <div
                className={`${Styles.form_item_title} ${curStyle.questionWrap}`}
              >
                {t("create.reply.count")}
                <span className={Styles.number}>
                  {createInfo.flow_config.sender.max_consecutive_auto_reply}
                </span>
                <Tooltip title={t("create.reply.countTip")}>
                  <img
                    src={QuestionPic}
                    className={Styles.question}
                    loading="lazy"
                  ></img>
                </Tooltip>
              </div>
              <Form.Item<createFieldType> label="" className={Styles.form_item}>
                <Slider
                  min={1}
                  max={10}
                  onChange={(value) => {
                    changeCreateInfo(
                      "flow_config.sender.max_consecutive_auto_reply",
                      value
                    );
                  }}
                  className={Styles.slider}
                  value={
                    createInfo.flow_config.sender.max_consecutive_auto_reply
                  }
                />
              </Form.Item>
            </div>
            <div className={Styles.form_group_item}>
              <div
                className={classnames(
                  Styles.form_item_title,
                  Styles.form_item_title_inline,
                  curStyle.questionWrap
                )}
              >
                {t("create.input.format")}
                <Select
                  value={createInfo.flow_config.sender.human_input_mode}
                  placeholder={t("create.humanInput.placeholder")}
                  className={Styles.select}
                  onChange={(value) => {
                    changeCreateInfo(
                      "flow_config.sender.human_input_mode",
                      value
                    );
                  }}
                  suffixIcon={<img src={SelectArrowPic} loading="lazy"></img>}
                >
                  {humanInputModeList.map((item, index) => {
                    return (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
                <Tooltip title={t("create.input.formatTip")}>
                  <img
                    src={QuestionPic}
                    className={Styles.question}
                    loading="lazy"
                  ></img>
                </Tooltip>
              </div>
              {/* <Form.Item<createFieldType>
              label=""
              className={Styles.form_item}
            ></Form.Item> */}
            </div>
            <div className={Styles.form_group_item}>
              <div className={Styles.form_item_title} ref={systemMessageRef}>
                {t("create.input.prompts")}
              </div>
              <Form.Item<createFieldType>
                label=""
                name="systemMessage"
                className={Styles.form_item}
                rules={[
                  { required: true, message: t("create.systemMessageTip") },
                ]}
              >
                <TextArea
                  rows={4}
                  onChange={(e) => {
                    changeCreateInfo(
                      "flow_config.sender.system_message",
                      e.target.value
                    );
                  }}
                  value={createInfo.flow_config.sender.system_message}
                  className={Styles.textarea}
                />
              </Form.Item>
            </div>
            {/* <div className={Styles.scrollbar}></div> */}
            {/* </Card> */}
          </div>
          <div
            className={classnames(
              Styles.form_item_title,
              curStyle.questionWrap
            )}
            ref={receiverRef}
          >
            {t("create.clue.receiver")}
            <Tooltip title={t("create.clue.receiverTip")}>
              <img
                src={QuestionPic}
                className={Styles.question}
                loading="lazy"
              ></img>
            </Tooltip>
          </div>
          {/* <Card> */}
          {createInfo.flow_config.receiver.map((item: any, index: number) => {
            return (
              <div className={Styles.form_group} key={item?.id}>
                <div className={Styles.form_group_item}>
                  <div className={Styles.form_item_title}>
                    {t("create.receiverCode") + (index + 1)}
                  </div>
                </div>
                <div className={Styles.form_group_item}>
                  <div className={Styles.form_item_title}>
                    {t("create.portrait")}
                  </div>
                  <div className={curStyle.uploadWrap}>
                    <img
                      src={
                        item?.avatar_url ||
                        uploadAgentIcon?.[index] ||
                        agentList[index]
                      }
                      alt=""
                      className={`${Styles.form_item} ${curStyle.iconWrap}`}
                    />
                    <BgUpload
                      isCustomType
                      onUploadSuccess={(url: string) => {
                        changeCreateInfo(
                          `flow_config.receiver[${index}].avatar_url`,
                          url
                        );
                        setUploadAgentIcon((item: any) => {
                          const newItem = _.cloneDeep(item);
                          newItem[index] = url;
                          return newItem;
                        });
                      }}
                    >
                      <div className={curStyle.uploadBtn}>
                        <img
                          src={UploadIcon}
                          alt=""
                          className={curStyle.uploadIcon}
                        />
                        <div>{t("create.changePortrait")}</div>
                      </div>
                    </BgUpload>
                  </div>

                  <div className={Styles.form_item_title}>
                    {t("create.nickName")}
                  </div>

                  <Form.Item<createFieldType>
                    label=""
                    className={Styles.form_item}
                    rules={[
                      {
                        required: true,
                        message: t("create.receiverNamePlaceholder"),
                      },
                    ]}
                  >
                    <Input
                      value={item.name}
                      defaultValue={"助理智能体" + (index + 1)}
                      placeholder={t("create.receiverNamePlaceholder")}
                      className={Styles.input}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        changeCreateInfo(
                          `flow_config.receiver[${index}].name`,
                          e.target.value
                        );
                      }}
                    ></Input>
                  </Form.Item>
                </div>

                {/* <div className={Styles.form_group_item}>
                <div className={Styles.form_item_title}>
                  {t("create.reply.count")}
                  <span className={Styles.number}>
                    {item.max_consecutive_auto_reply}
                  </span>
                </div>
                <Form.Item<createFieldType>
                  label=""
                  className={Styles.form_item}
                >
                  <Slider
                    min={1}
                    max={10}
                    onChange={(value) => {
                      changeCreateInfo(
                        `flow_config.receiver[${index}].max_consecutive_auto_reply`,
                        value
                      );
                    }}
                    className={Styles.slider}
                    value={item.max_consecutive_auto_reply}
                  />
                </Form.Item>
              </div> */}
                {/* <div className={Styles.form_group_item}>
                <div className={Styles.form_item_title}>{t("")}</div>
                {/* <Form.Item<createFieldType>
                  label=""
                  className={Styles.form_item}
                >
                  <Select
                    value={item.human_input_mode}
                    placeholder={t("create.humanInput.placeholder")}
                    onChange={(value) => {
                      changeCreateInfo(
                        `flow_config.receiver[${index}].human_input_mode`,
                        value
                      );
                    }}
                    className={Styles.select}
                  >
                    {humanInputModeList.map((item, index) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item> */}
                {/* <div
                  className={classnames(
                    Styles.form_item_title,
                    Styles.form_item_title_inline
                  )}
                >
                  {t("create.input.format")}
                  <Select
                    value={item.human_input_mode}
                    placeholder={t("create.humanInput.placeholder")}
                    className={Styles.select}
                    onChange={(value) => {
                      changeCreateInfo(
                        `flow_config.receiver[${index}].human_input_mode`,
                        value
                      );
                    }}
                    suffixIcon={<img src={SelectArrowPic} loading="lazy"></img>}
                  >
                    {humanInputModeList.map((item, index) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div> */}
                <div className={Styles.form_group_item}>
                  <div className={Styles.form_item_title}>
                    {t("create.input.prompts")}
                  </div>
                  <Form.Item<createFieldType>
                    label=""
                    className={Styles.form_item}
                  >
                    <TextArea
                      rows={4}
                      value={item.system_message}
                      onChange={(e) => {
                        changeCreateInfo(
                          `flow_config.receiver[${index}].system_message`,
                          e.target.value
                        );
                      }}
                      className={Styles.textarea}
                    />
                  </Form.Item>
                </div>
                <div className={Styles.form_group_item}>
                  <div className={Styles.form_item_title}>
                    {t("create.emotional.temperature")}
                    <span className={Styles.number}>{item.temperature}</span>
                  </div>
                  <Form.Item<createFieldType>
                    label=""
                    className={Styles.form_item}
                  >
                    <Slider
                      min={0}
                      max={1}
                      step={0.1}
                      onChange={(value) => {
                        changeCreateInfo(
                          `flow_config.receiver[${index}].temperature`,
                          value
                        );
                      }}
                      className={Styles.slider}
                      value={item.temperature}
                    />
                  </Form.Item>
                </div>
                {createInfo.flow_config.receiver.length > 1 && (
                  <div
                    className={`${Styles.form_group_item} ${curStyle.deleteAgent}`}
                  >
                    <Button
                      className={curStyle.deleteAgentBtn}
                      onClick={() => {
                        deleteAgent(index);
                      }}
                    >
                      {t("create.deleteReceiver")}
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
          {/* </Card> */}
          <Button
            className={curStyle.addAgent}
            disabled={createInfo.flow_config.receiver.length >= 5}
            onClick={() => {
              addAgent();
            }}
          >
            <img src={AddAgentIc} />
            {t("create.addReceiver")}
          </Button>

          <div className={`${Styles.form_item_title} ${Styles.flexContainer}`}>
            {t("知识库关联")}
            <div className={Styles.form_item_actions}>
              <div className={Styles.actions_link} onClick={handleLinkClick}>
                关联已有知识库
              </div>
            </div>
          </div>

          <RelateKnowledgeBaseModal
            ref={relateKnowledgeBaseModalRef}
            setCurrentFolderList={(list) => {
              setHasGetFolderList(true);
              setCurFolderList(list);
            }}
            curFolderList={curFolderList}
          ></RelateKnowledgeBaseModal>

          <FolderListTable
            data={curFolderList}
            unRelate={(id: number) => {
              setCurFolderList(
                curFolderList.filter((item: any) => item.id !== id)
              );
            }}
          />

          <Form.Item<createFieldType>
            label=""
            className={Styles.form_item}
            rules={[
              { required: true, message: t("create.provider.placeholder") },
            ]}
          >
            {/* <FileUpload
              onUploadSuccess={onFileUploadSuccess}
              fileList={createInfo?.flow_config?.knowledge_base || []}
              getFileLists={getFileLists}
            /> */}
            <ProgressForm
              data={{
                knowledge_base: createInfo?.flow_config?.knowledge_base || [],
                curFileLists,
              }}
              changeCreateInfo={changeCreateInfo}
              updateAgent={updateAgent}
              hideIdList={hideIdList}
              handleHideIdList={(deleteIdList: typeof hideIdList) => {
                setHideIdList(deleteIdList);
              }}
            />
          </Form.Item>
        </Form>

        <div className={Styles.form_item_title}>
          {t("create.repository.greetings")}
        </div>

        <Form.Item<createFieldType> label="" className={Styles.form_item}>
          <Input
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              changeCreateInfo("greeting", e.target.value);
            }}
            className={Styles.input}
            placeholder={t("create.greeting.placeholder")}
            value={createInfo.greeting}
          />
        </Form.Item>

        <div className={Styles.form_item_title}>{t("create.agent.tools")}</div>

        {skillsAll.map((item: any, index) => {
          return (
            <Card
              key={item.skill}
              title={
                <div className={Styles.skill_title}>
                  <div>{item.title}</div>
                  <Switch
                    checked={
                      !!createInfo.flow_config.skills.includes(item.skill)
                    }
                    onChange={(value) => {
                      switchSkills(item.skill, value);
                    }}
                    style={{
                      backgroundColor: "var(--theme-bg10)",
                      borderColor: "green",
                    }}
                    className={Styles.switch}
                  />
                </div>
              }
              className={classnames(
                Styles.skill,
                !item?.desc ? Styles.skills_nodata : "",
                Styles.card,
                !item?.desc ? Styles.card_nodata : ""
              )}
            >
              {item?.desc || <></>}
            </Card>
          );
        })}
      </div>
    );
  }
);

export default ProfessionalMode;
