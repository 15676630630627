import React from "react";
import style from "./index.module.scss";
import { createImageUrl } from "@/utils/common";

const LoadingIcon = createImageUrl('common/loading.png');

interface CardProps {
  imgSrc: string;
  file_url: string;
  file_name: string;
  file_type: string;
  status?: string;
  deleteCardFn: () => void;
  showDeleteBtn?: boolean;
  isChat?: boolean;
  wrapStyle?: object
}

const Card: React.FC<CardProps> = ({
  imgSrc,
  file_url,
  file_name,
  file_type,
  status,
  deleteCardFn,
  showDeleteBtn,
  isChat,
  wrapStyle
}) => {
  return (
    <div className={style.cardWrap} style={{...wrapStyle}}>
      <img src={imgSrc} alt="" className={style.img} />
      <div className={style.cardDetail}>
        <p className={style.cardTitle}>{file_name}</p>
        {status === "uploading" ? (
          <div className={style.uploading}>
            <img src={LoadingIcon} className={style.icon} />
            上传中
          </div>
        ) : status === "error" ? (
          <div className={style.listItemError}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99992 0.333252C10.6819 0.333252 13.6666 3.31792 13.6666 6.99992C13.6666 10.6819 10.6819 13.6666 6.99992 13.6666C3.31792 13.6666 0.333252 10.6819 0.333252 6.99992C0.333252 3.31792 3.31792 0.333252 6.99992 0.333252ZM5.58592 4.64258C5.46622 4.52175 5.30487 4.45123 5.13489 4.44546C4.9649 4.4397 4.79914 4.49912 4.67153 4.61156C4.54392 4.72401 4.46412 4.88098 4.44845 5.05033C4.43278 5.21969 4.48244 5.38864 4.58725 5.52259L4.64325 5.58592L6.05658 6.99925L4.64325 8.41392C4.52242 8.53361 4.4519 8.69496 4.44613 8.86495C4.44036 9.03493 4.49978 9.2007 4.61223 9.32831C4.72467 9.45591 4.88164 9.53572 5.051 9.55139C5.22036 9.56705 5.3893 9.5174 5.52325 9.41259L5.58592 9.35725L6.99992 7.94258L8.41392 9.35725C8.53361 9.47809 8.69496 9.54861 8.86495 9.55437C9.03493 9.56014 9.2007 9.50072 9.32831 9.38827C9.45591 9.27583 9.53572 9.11886 9.55139 8.9495C9.56705 8.78014 9.5174 8.6112 9.41259 8.47725L9.35725 8.41392L7.94258 6.99992L9.35725 5.58592C9.47809 5.46622 9.54861 5.30487 9.55437 5.13489C9.56014 4.9649 9.50072 4.79914 9.38827 4.67153C9.27583 4.54392 9.11886 4.46412 8.9495 4.44845C8.78014 4.43278 8.6112 4.48244 8.47725 4.58725L8.41392 4.64258L6.99992 6.05725L5.58592 4.64258Z"
                fill="#F00"
              />
            </svg>
            上传失败
          </div>
        ) : (
          <p className={style.cardType}>{file_type.substring(1)}</p>
        )}
      </div>
      {showDeleteBtn && (
        <div
          className={style.close}
          onClick={() => {
            deleteCardFn && deleteCardFn();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_4286_7309)">
              <path
                d="M7.99992 1.33325C11.6819 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.6819 14.6666 7.99992 14.6666C4.31792 14.6666 1.33325 11.6819 1.33325 7.99992C1.33325 4.31792 4.31792 1.33325 7.99992 1.33325ZM6.58592 5.64258C6.46622 5.52175 6.30487 5.45123 6.13489 5.44546C5.9649 5.4397 5.79914 5.49912 5.67153 5.61156C5.54392 5.72401 5.46412 5.88098 5.44845 6.05033C5.43278 6.21969 5.48244 6.38864 5.58725 6.52259L5.64325 6.58592L7.05658 7.99925L5.64325 9.41392C5.52242 9.53361 5.4519 9.69496 5.44613 9.86495C5.44036 10.0349 5.49978 10.2007 5.61223 10.3283C5.72467 10.4559 5.88164 10.5357 6.051 10.5514C6.22036 10.5671 6.3893 10.5174 6.52325 10.4126L6.58592 10.3573L7.99992 8.94258L9.41392 10.3573C9.53361 10.4781 9.69496 10.5486 9.86495 10.5544C10.0349 10.5601 10.2007 10.5007 10.3283 10.3883C10.4559 10.2758 10.5357 10.1189 10.5514 9.9495C10.5671 9.78014 10.5174 9.6112 10.4126 9.47725L10.3573 9.41392L8.94258 7.99992L10.3573 6.58592C10.4781 6.46622 10.5486 6.30487 10.5544 6.13489C10.5601 5.9649 10.5007 5.79914 10.3883 5.67153C10.2758 5.54392 10.1189 5.46412 9.9495 5.44845C9.78014 5.43278 9.6112 5.48244 9.47725 5.58725L9.41392 5.64258L7.99992 7.05725L6.58592 5.64258Z"
                fill="#272B30"
              />
            </g>
            <defs>
              <clipPath id="clip0_4286_7309">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

export default Card;
