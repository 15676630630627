export default {
  "common.welcome": "登入 AI Agent",
  "common.password": "密碼:",
  "common.password.confirm": "確認密碼:",
  "common.userName": "用戶名:",
  "common.login": "登入",
  "common.login.success": "登入成功",
  "common.register": "註冊",
  "common.register.success": "註冊成功",
  "common.register.title": "註冊帳戶",
  "common.product.name": "新智體",
  "common.user.placeHolder": "請輸入用戶名!",
  "common.password.placeHolder": "請輸入密碼!",
  "common.seeking": "探索",
  "common.chat": "對話",
  "common.canvas": "畫布",
  "common.community": "实践社區",
  "common.create": "智能體創建",
  "common.myAgent": "我的智能體",
  "common.publish": "智能體發布",
  "common.submit": "提交",
  "common.send": "發送",
  "common.tags": "標籤",
  "common.publish.time": "發布時間:",
  "common.update.time": "最近更新:",
  "common.join.chat": "加入對話",
  "common.create.same": "創建同款",
  "common.agent.singal": "單智能體",
  "common.back": "返回",
  "common.chat.default.greetings": "今天我有什麼可以幫你的？",
  "common.you": "你",
  "common.edit": "編輯",
  "common.category": "類別",
  "common.yes": "是",
  "common.no": "否",
  "common.category.recent": "最近",
  "common.category.work": "工作",
  "common.category.learning": "學習",
  "common.category.lifestyle": "生活",
  "common.or": "或",
  "common.like": "喜歡",
  "common.agentCard": "智能體卡片",
  "common.agent": "智能體",

  create: {
    dialog: {
      agent: {
        desc: "開始創建您的Agent，你可以直接創建，也可以進入你Agent主頁",
      },
    },
    normalmode: "普通模式",
    professionalmode: "專家模式",
    name: {
      agent: "智能體名稱",
      placeholder: "請輸入 智能體名稱",
    },
    introduce: {
      agent: "智能體介紹",
      placeholder: "請輸入 智能體介紹",
    },
    provider: {
      model: "模型提供商",
      placeholder: "請選擇模型提供商",
    },
    clue: {
      edit: "提示詞編輯",
      placeholder: "請選擇提示詞編輯類型",
      sender: "發送方",
      receiver: "接收方",
    },
    humanInput: {
      placeholder: "請選擇發送方格式",
    },
    agent: {
      type: "Agent 類型",
      tools: "Agent 工具",
    },
    repository: {
      upload: "知識庫上傳",
      greetings: "問候語",
    },
    reply: {
      count: "最大自動連續回覆數：",
    },
    input: {
      format: "輸入格式",
      prompts: "系統輸入提示詞",
    },
    emotional: {
      temperature: "情感溫度：",
    },
    image: {
      create: "圖像生成",
      desc: "可以輸入一段描述，比如“一只穿著太空服的貓在月球上散步”，人工智能就會根據這個描述生成一幅相應的圖片。這項技術通常使用複雜的算法和大量的圖片數據來訓練，使得它能理解和轉化文字信息為視覺內容。這種功能可以用於各種創意領域，比如藝術創作、產品設計、娛樂等",
      cover: "背景圖片",
    },
    info: {
      search: "信息檢索",
    },
    preview: {
      title: "調試與預覽",
    },
    singal: {
      agent: "單 Agent",
    },
    muti: {
      agent: "多 Agent",
    },
    to: {
      detail: "查看我創建的Agent",
    },
    conversation: "對話類",
    greeting: {
      placeholder: "請輸入問候語",
    },
    upload: {
      files: "上傳文件",
      tips: "已支援'txt', 'json', 'csv', 'tsv', 'md', 'html', 'htm', 'rtf', 'rst', 'jsonl', 'log', 'xml', 'yaml', 'yml', 'pdf',每個文件不超過10MB",
    },
    last: {
      updatetime: "上次保存時間:",
    },
    category: {
      placeholder: "請選擇類別",
    },
    card: {
      title: "卡片標題",
      titlePlaceholder: "請輸入卡片標題",
      cardContent: "圖文知識",
      maxCardNumber: "（最多可上傳9張）",
      uploadMore: "上傳更多",
    },
  },

  "publish.setting.title": "發布設置",
  "publish.market.title": "發布到市場",
  "publish.market.desc":
    "用戶可以通過Agent市場找到發布的Agent，並將Agent公開給其他用戶使用",
  "publish.api.title": "生成API",
  "publish.api.desc": "用戶可以通過生成API進行後續開發",
  "publish.link.title": "生成發布鏈接",
  "publish.link.desc": "用戶可以通過網頁進入Agent直接進行交互",
  "publish.third.title": "綁定第三方",
  "publish.third.desc": "用戶可以綁定第三方App，完成配置後，可在App中使用",
  "publish.dialog.title": "發布信息設置",
  "publish.dialog.style": "發布風格選擇",
  "publish.dialog.stylePlaceHolder": "請選擇發布風格",
  "publish.dialog.permission": "發布權限設置",
  "publish.dialog.toPlace": "發布到Agent廣場",
  "publish.dialog.toDashBoard": "僅發布到個人中心",
  "publish.dialog.edit.permission": "編輯權限設置",
  "publish.dialog.viewOnly": "僅查看",
  "publish.dialog.editView": "編輯和查看",
  "publish.dialog.Agreement": "點擊閱讀《Agent智能體平台創建協議》",
  "publish.copy.success": "複製成功",
  "publish.recommand.tags": "推薦標籤",
  "publish.question.example": "提問示例",
  "publish.editable": "是否可編輯",
  "publish.tags.placeholder": "請選擇標籤",
  "publish.btntext": "發布到市場",
  "publish.agreement": "發布即表示您同意遵守",
  "publish.policy": "《Syngents.cn的內容政策》",
  "publish.last.time": "上次發布時間:",
  "publish.rollback": "回滾",
  "publish.rollbackTip": '您確定要回滾"<0>{{name}}</0>"嗎？',

  chat: {
    withModal: "與大型模型對話",
    withAgent: "智能體列表",
    input: {
      placeholder: "輸入您想了解的內容",
    },
    delete: {
      title: "刪除對話？",
      content: "確定刪除“<0>{{name}}</0>”？",
    },
    source: "來源",
    answer: "答復",
    related: "相關內容",
  },

  "community.explore": "發現",
  "community.frontier": "AI 前沿陣地",
  "community.cameby": "人來過",
  "community.member": "成員",
  "community.online": "人在線",
  "community.watched": "人觀看",
  "community.share": "人分享",
  login: {
    title: "登录到Syngents",
    inputEmail: "请输入你的邮箱",
    goLogin: "使用邮箱继续",
    google: "Google登录",
    wechat: "微信登录",
    continue: "继续即表示您接受我们的 <0>{{policy}}</0>",
    inputCode: "请输入验证码",
    getCode: "获取验证码",
    getCodeSuccess: "获取验证码成功",
    secondsLeft: "<0>{{second}}</0> 秒后重新获取",
  },
  myagent: {
    app: "應用",
    repository: "知識庫",
    tools: "工具",
    newagent: "創建智能體",
    subtotal: {
      left: "一共創建",
      right: "個智能體",
    },
    delete: {
      title: "刪除代理",
      content: '您確定要刪除"<0>{{name}}</0>"嗎？',
      success: "成功刪除",
      successText: '"<0>{{name}}</0>" 已成功從我的代理列表中刪除！',
      fail: "刪除失敗",
      failText: "刪除失敗，請稍後重試或聯繫管理員。",
    },
    newCardAgent: "創建知识卡片",
    uploadImage: "上傳圖片",
    empty: "暫無數據",
  },

  "explore.tab.tool": "多模態工具",
  "explore.tab.study": "學習",
  "explore.tab.analysis": "研究分析",
  "explore.tab.writing": "專業寫作",
  "explore.tab.coding": "程式碼",
  "explore.tab.prompt": "提示詞",
  "explore.tab.assistant": "小助手",
  "explore.tab.entertainment": "娛樂休閒",
  privacy_policy: {
    title: "Syngents.cn 服務條款",
    introduction: "簡介",
    welcome_message:
      '歡迎使用 Syngents.cn 平台（以下簡稱為 "Syngents.cn" 或 "我們"）。這些服務條款（以下簡稱為 "條款"）規定了您對 Syngents.cn 提供的網站、移動應用程序和其他相關產品和服務（統稱為 "服務"）的訪問和使用。通過訪問或使用我們的服務，您同意遵守這些條款和我們的隱私政策。',
    account_registration_and_security: "帳戶註冊與安全",
    provide_accuate_information:
      "您必須提供真實、準確、完整的信息來創建帳戶並定期更新您的帳戶信息。",
    responsibility_of_account: "您負責對帳戶下的所有活動負責。",
    right_of_refusal:
      "Syngents.cn 有權拒絕服務、終止帳戶或刪除內容，特別是在違反這些條款的情況下。",
    user_content: "用戶內容",
    retain_ownership:
      "您通過 Syngents.cn 提交的內容，您保留所有權利，但授予 Syngents.cn 非獨家、免版稅、全球性、永久且完全可轉授權的權利。",
    declare_and_guarantee:
      "您聲明並保證擁有提交內容的所有權利，或已獲得必要的許可。",
    user_behavior: "用戶行為",
    agreement_of_behavior: "您同意不使用服務進行以下行為：",
    prohibited_behaviors_string: `{
  "A": "發布或傳播誹謗、淫穢、色情、粗俗、冒犯性或種族歧視的內容。",
  "B": "侵犯知識產權、商業秘密、隱私權或其他專有權利。",
  "C": "提交含有病毒、木馬、間諜軟件或其他破壞性代碼的內容。",
  "D": "發布未經授權的廣告、垃圾郵件、鏈接郵件、任何其他形式的未經授權的招攬或任何形式的彩票或賭博。",
  "E": "提供虛假或誤導性信息，或偽裝任何個人或實體。",
  "F": "違反任何適用的法律或法規，包括但不限於版權法、商標法、隱私法和反垃圾郵件法。",
  "G": "宣揚非法、有害或暴力活動。",
  "H": "騷擾、威脅、欺凌、欺騙或以其他方式干擾其他用戶。",
  "I": "違反服務的任何程序、政策或規定。",
  "J": "干擾、破壞服務或相關網絡的正常運行。",
  "K": "未經授權訪問服務、其他帳戶或與服務連接的計算機系統或網絡。",
  "L": "使用任何機器人、蜘蛛、爬取工具或其他自動化方式訪問服務。",
  "M": "逆向工程、反編譯、反彙編或以其他方式嘗試獲取服務的源代碼。",
  "N": "修改、適應、破解或以其他方式破壞服務的完整性。",
  "O": "未經 Syngents.cn 明確書面許可，複製、拷貝、出售、轉售或利用服務的任何部分。"
}`,
    prohibited_behaviors: {
      A: "發布或傳播誹謗、淫穢、色情、粗俗、冒犯性或種族歧視的內容。",
      B: "侵犯知識產權、商業秘密、隱私權或其他專有權利。",
      C: "提交含有病毒、木馬、間諜軟件或其他破壞性代碼的內容。",
      D: "發布未經授權的廣告、垃圾郵件、鏈接郵件、任何其他形式的未經授權的招攬或任何形式的彩票或賭博。",
      E: "提供虛假或誤導性信息，或偽裝任何個人或實體。",
      F: "違反任何適用的法律或法規，包括但不限於版權法、商標法、隱私法和反垃圾郵件法。",
      G: "宣揚非法、有害或暴力活動。",
      H: "騷擾、威脅、欺凌、欺騙或以其他方式干擾其他用戶。",
      I: "違反服務的任何程序、政策或規定。",
      J: "干擾、破壞服務或相關網絡的正常運行。",
      K: "未經授權訪問服務、其他帳戶或與服務連接的計算機系統或網絡。",
      L: "使用任何機器人、蜘蛛、爬取工具或其他自動化方式訪問服務。",
      M: "逆向工程、反編譯、反彙編或以其他方式嘗試獲取服務的源代碼。",
      N: "修改、適應、破解或以其他方式破壞服務的完整性。",
      O: "未經 Syngents.cn 明確書面許可，複製、拷貝、出售、轉售或利用服務的任何部分。",
    },
    intellectual_property: "知識產權",
    protection_of_intellectual_property:
      "Syngents.cn 的內容、軟件和商標受到法律保護，未經授權不得使用。",
    acknowledge_and_agree:
      "您承認並同意不侵犯 Syngents.cn 或任何第三方的知識產權。",
    disclaimer_and_limitation_of_liability: "免責聲明和責任限制",
    service_provided_as_is:
      '服務按 "現狀" 提供，Syngents.cn 不對服務的中斷、錯誤或用戶數據的損失承擔責任。',
    no_liability_for_damage:
      "在任何情況下，Syngents.cn 對任何直接、間接、特殊、衍生或懲罰性損害不承擔任何責任，包括但不限於利潤、商譽、使用損失、數據損失或其他無形損失。",
    changes_to_terms: "條款變更",
    right_to_modify_terms:
      "Syngents.cn 可隨時修改這些條款，並通過網站或電子郵件通知您。",
    acceptance_of_new_terms:
      "繼續使用服務即表示您接受新條款。如果您不同意新條款，您必須停止使用服務。",
    applicable_law_and_dispute_resolution: "適用法律和爭議解決",
    governing_law: "這些條款受到 Syngents.cn 營運所在地的法律管轄。",
    dispute_resolution:
      "任何爭議應首先通過協商解決，協商不成時，應提交具有約束力的仲裁。",
    miscellaneous: "其他",
    invalid_terms:
      "如果條款的任何部分無法執行或無效，該部分將在最小範圍內受到限制或消除。",
    no_assignment:
      "您不得轉讓或轉讓這些條款或根據這些條款授予的任何權利和許可。",
    contact_information: "聯繫方式",
    contact_us:
      "如果您對這些條款有任何問題或疑慮，請通過 <0>{{email}}</0> 聯繫我們。",
  },
  workflow: {
    fileName: "檔案名稱",
  },
  status: "狀態",
  size: "大小",
  action: "操作",
};
