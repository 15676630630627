import ChatBox from "@/components/ChatBox";
import Styles from "./index.module.scss";
import { getImageUrl } from "@/utils/common";

const Preview = ({ createInfo, id, onSaveClick }: any) => {

  return (
    <div
      className={Styles.preview}
      onClick={() => {
        onSaveClick(true);
      }}
    >
      <div
        className={Styles.chat_box_counter}
        style={
          id && createInfo.name
            ? {}
            : {
                opacity: 0.3,
              }
        }
      >
        <ChatBox
          mode="preview"
          boxStyle="preview"
          agentId={id}
          history={[
            {
              id: -1,
              agent_id: id,
              role: 2,
              content: createInfo?.greeting || "",
              create_time: new Date(),
              update_time: new Date(),
            },
          ]}
          botAvator={getImageUrl(createInfo.avatar_url)}
          botName={createInfo.name || "bot"}
          currentInfo={createInfo}
        />
      </div>
      {id && createInfo.name ? null : (
        <div className={Styles.saveTip}>保存后再进行调试</div>
      )}
    </div>
  );
};

export default Preview;
