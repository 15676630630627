import React, { useEffect, useState } from "react";
import type { UploadProps } from "antd";
import { Upload, message, ConfigProvider } from "antd";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import baseURL from "@/request/getBaseURL";
import Styles from "./index.module.scss";
import UploadPic from "@/assets/common/upload.svg";
import UploadLightPic from "@/assets/common/upload-light.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { createImageUrl } from "@/utils/common";

const loadingIcon = createImageUrl('common/loading.png');

type IFileUpload = {
  onUploadSuccess: any;
  folderId?: number;
  acceptFileTypeList?: string[];
  folderPath?: string;
  isCustom?: boolean;
  children?: any;
  isMultiple?: boolean;
  getFilesList?: any;
  keys?: any;
  maxSize?: number;
};

const AcceptFileTypeList = [
  ".pdf",
  ".docx",
  ".pptx",
  ".xlsx",
  ".md",
  ".txt",
  ".csv",
];

const extraFileTypeList = [".jpg", ".jpeg", ".png", ".mp3", ".wav", ".webp"];

const FileUpload = ({
  onUploadSuccess,
  folderId,
  acceptFileTypeList,
  folderPath,
  isCustom,
  isMultiple,
  children,
  getFilesList,
  keys,
  maxSize,
}: IFileUpload) => {
  const [fileLists, setFileList] = useState<UploadFile[]>([]);
  const theme = useThemeHooks();

  const fileTypeList =
    acceptFileTypeList || folderPath === "knowledge"
      ? AcceptFileTypeList
      : [...AcceptFileTypeList, ...extraFileTypeList];

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    const fileList = newFileList.map((file: any) => {
      const type = file.name.substring(file.name.lastIndexOf("."));
      const isAcceptable = fileTypeList.indexOf(type) > -1;
      const isLt100M = file.size / 1024 / 1024 < 100;
      return {
        ...file,
        status: isAcceptable && isLt100M ? file.status || "done" : "error",
      };
    });

    if (getFilesList) {
      getFilesList(fileList);
    }

    setFileList(fileList);
  };

  const beforeUpload = (file: RcFile) => {
    const type = file.name.substring(file.name.lastIndexOf("."));
    const isAcceptable = fileTypeList.indexOf(type) > -1;

    if (!isAcceptable) {
      message.error("请检查文件格式, 再重新上传");
      return false;
    }

    if (maxSize && file.size / 1024 / 1024 > maxSize) {
      message.error(`文件大小不得超过${maxSize}MB，请重新选择。`);
      return false;
    }

    if ([".mp3", ".wav"].includes(type)) {
      const isLt25M = file.size / 1024 / 1024 <= 25;
      if (!isLt25M) {
        message.error("文件大小不得超过25MB，请重新选择。");
      }
      return isLt25M;
    }

    const isLt100M = file.size / 1024 / 1024 <= 100;
    if (!isLt100M) {
      message.error("文件大小不得超过100MB，请重新选择。");
    }
    return isLt100M;
  };

  const customRequest = ({ file, onSuccess, onError }: any) => {
    // 在这里自定义上传请求
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_path", folderPath || "knowledge");
    const token = localStorage.getItem("token") || Cookies.get("token");

    // 添加令牌到请求头中
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    fetch(`${baseURL}/aichat/file/upload/`, {
      method: "POST",
      headers,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // 处理上传成功的响应
        onSuccess(data, file);
        if ((data.status = 200)) {
          message.success("上传成功");
          onUploadSuccess(data.data);
        } else {
          message.error("上传失败");
        }
      })
      .catch((error) => {
        console.log(error);
        // 处理上传失败的情况
        onError(error, file);
        message.error("上传失败");
      });
  };

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          // 使用品牌色
          colorPrimary: "#007890",
        },
        components: {
          Button: {
            /* 这里是你的组件 token */
            defaultBorderColor: "#007890",
            defaultColor: "#007890",
            primaryShadow: "none",
          },
          Input: {
            activeShadow: "none",
          },
        },
      }}
    >
      <Upload
        onChange={handleChange}
        beforeUpload={beforeUpload}
        multiple={isMultiple || false}
        maxCount={isMultiple ? 20 : 1}
        customRequest={customRequest}
        accept={fileTypeList.toString()}
        //   fileList={fileLists}
        showUploadList={false}
        className={Styles.upload}
        key={keys}
      >
        {isCustom ? (
          children
        ) : (
          <div className={Styles.upload_inner}>
            <div className={Styles.title}>
              <img
                src={theme === "dark" ? UploadPic : UploadLightPic}
                loading="lazy"
              ></img>
              <span>
                将文件拖到此处，或<span className={Styles.tips}>点击上传</span>
              </span>
            </div>
            <div>
              <div>
                已支持'.pdf','.docx','.pptx','.xlsx','.md','.txt','.csv',
              </div>
              <div> 每个文件不超过100MB</div>
            </div>
          </div>
        )}
      </Upload>
      {fileLists.length > 0 && !isCustom && (
        <div className={Styles.listWrap}>
          {fileLists.map((file) => {
            return (
              <div className={Styles.listItem}>
                <div className={Styles.listItemTitle}>
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "75%",
                      width: "fit-content",
                      display: "inline-block",
                    }}
                  >
                    {file.name.split(".")[file.name.split(".").length - 2]}
                  </span>
                  <span
                    style={{
                      position: "relative",
                    }}
                  >
                    .{file.name.split(".")[file.name.split(".").length - 1]}
                  </span>
                </div>
                {file.status === "uploading" ? (
                  <div className={Styles.listItemLoading}>
                    <img src={loadingIcon} alt="loading" />
                    上传中
                  </div>
                ) : file.status === "error" ? (
                  <div className={Styles.listItemError}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M6.99992 0.333252C10.6819 0.333252 13.6666 3.31792 13.6666 6.99992C13.6666 10.6819 10.6819 13.6666 6.99992 13.6666C3.31792 13.6666 0.333252 10.6819 0.333252 6.99992C0.333252 3.31792 3.31792 0.333252 6.99992 0.333252ZM5.58592 4.64258C5.46622 4.52175 5.30487 4.45123 5.13489 4.44546C4.9649 4.4397 4.79914 4.49912 4.67153 4.61156C4.54392 4.72401 4.46412 4.88098 4.44845 5.05033C4.43278 5.21969 4.48244 5.38864 4.58725 5.52259L4.64325 5.58592L6.05658 6.99925L4.64325 8.41392C4.52242 8.53361 4.4519 8.69496 4.44613 8.86495C4.44036 9.03493 4.49978 9.2007 4.61223 9.32831C4.72467 9.45591 4.88164 9.53572 5.051 9.55139C5.22036 9.56705 5.3893 9.5174 5.52325 9.41259L5.58592 9.35725L6.99992 7.94258L8.41392 9.35725C8.53361 9.47809 8.69496 9.54861 8.86495 9.55437C9.03493 9.56014 9.2007 9.50072 9.32831 9.38827C9.45591 9.27583 9.53572 9.11886 9.55139 8.9495C9.56705 8.78014 9.5174 8.6112 9.41259 8.47725L9.35725 8.41392L7.94258 6.99992L9.35725 5.58592C9.47809 5.46622 9.54861 5.30487 9.55437 5.13489C9.56014 4.9649 9.50072 4.79914 9.38827 4.67153C9.27583 4.54392 9.11886 4.46412 8.9495 4.44845C8.78014 4.43278 8.6112 4.48244 8.47725 4.58725L8.41392 4.64258L6.99992 6.05725L5.58592 4.64258Z"
                        fill="#F00"
                      />
                    </svg>
                    上传失败
                  </div>
                ) : (
                  <div className={Styles.listItemSuccess}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_4442_2736)">
                        <path
                          d="M7.99992 1.33331C11.6819 1.33331 14.6666 4.31798 14.6666 7.99998C14.6666 11.682 11.6819 14.6666 7.99992 14.6666C4.31792 14.6666 1.33325 11.682 1.33325 7.99998C1.33325 4.31798 4.31792 1.33331 7.99992 1.33331ZM10.3566 5.58731L7.05658 8.88731L5.64325 7.47331C5.58136 7.41137 5.50786 7.36223 5.42698 7.32869C5.34609 7.29515 5.25939 7.27788 5.17182 7.27784C4.99497 7.27778 4.82535 7.34797 4.70025 7.47298C4.57516 7.59798 4.50485 7.76756 4.50478 7.94441C4.50472 8.12126 4.57491 8.29088 4.69992 8.41598L6.53858 10.2546C6.60669 10.3228 6.68754 10.3768 6.77654 10.4137C6.86553 10.4506 6.96092 10.4696 7.05725 10.4696C7.15358 10.4696 7.24897 10.4506 7.33797 10.4137C7.42696 10.3768 7.50782 10.3228 7.57592 10.2546L11.2999 6.53065C11.425 6.40555 11.4953 6.23589 11.4953 6.05898C11.4953 5.88207 11.425 5.71241 11.2999 5.58731C11.1748 5.46222 11.0052 5.39194 10.8283 5.39194C10.6513 5.39194 10.4817 5.46222 10.3566 5.58731Z"
                          fill="#29CC6A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4442_2736">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    上传完成
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </ConfigProvider>
  );
};

export default FileUpload;
