import { getHistoryByUuid } from "@/apis/common";
import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { createImageUrl, isMobile } from "@/utils/common";
import Content from "@/components/ChatBox/components/Content";
import UploadFilesList from "@/components/uploadFilesList";
import { Button, ConfigProvider, Divider, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const agentB_proBg = createImageUrl("chat/superAssistant.png");
const senceMap: Record<string, string> = {
  llm_chat: "超级助理",
  super_assistant: "超级助理",
};

const Share = () => {
  const [data, setData] = useState({
    chat_title: "",
    chat_messages: [],
    chat_mode: "",
    generate_time: "",
  });
  const [isAtBottom, setIsAtBottom] = useState(false);
  const boxRef: any = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const uuidParam = params.get("uuid");
    if (uuidParam) {
      getHistoryByUuid({ uuid: uuidParam }).then((res) => {
        if (res.data?.status === 200) {
          res.data.data.chat_messages = res.data.data.chat_messages.reverse();
          setData(res.data.data);
        } else {
          message.error("获取历史记录失败, 即将为您直接跳转主页~");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!boxRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 60;
      setIsAtBottom(atBottom);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const date: Date = new Date(data.generate_time);
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1; // 月份从 0 开始，所以要加 1
  const day: number = date.getDate();
  const formattedDate: string = `${year} 年 ${month} 月 ${day} 日`;
  const displayText = senceMap[data.chat_mode] || "超级助理";

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#007890",
        },
      }}
    >
      <div className={styles.chat_inner}>
        {data.chat_messages.length !== 0 ? (
          <>
            <div className={styles.chat_history} ref={boxRef}>
              <div className={styles.header}>
                <div className={styles.headerTitle}>{data.chat_title}</div>
                {!isMobile ? (
                  <div
                    className={styles.headerDesc}
                  >{`${formattedDate} • 内容由 新智体${displayText}生成`}</div>
                ) : (
                  <div className={styles.headerDesc}>
                    <div>{formattedDate}</div>
                    <div>内容由 新智体{displayText}生成</div>
                  </div>
                )}
              </div>
              <Divider style={{ margin: "24px 0" }} />
              {data.chat_messages.map((item: any, index: number) => {
                return (
                  <div
                    className={classNames(
                      styles.chat_history_item,
                      styles.chat_history_item_receive
                    )}
                    key={index}
                  >
                    {item.role === 2 ? (
                      <div className={styles.chat_history_name_box}>
                        <img
                          className={styles.chat_history_item_img}
                          alt=""
                          src={agentB_proBg}
                          loading="lazy"
                        ></img>
                        <div>{displayText}</div>
                      </div>
                    ) : null}
                    {item.content && (
                      <div className={styles.chat_history_item_msg}>
                        {item.role === 2 ? (
                          <div
                            className={styles.chat_history_item_content_wrap}
                            style={{
                              whiteSpace: "pre-wrap",
                              width: isMobile
                                ? "calc(100%)"
                                : "calc(100% - 32px)",
                            }}
                          >
                            <Content
                              curWidth={boxRef.current?.clientWidth}
                              message={item}
                              isLast={index === data.chat_messages.length - 1}
                              loading={false}
                              wrapStyle={styles.chat_history_content}
                            />
                          </div>
                        ) : (
                          <div className={styles.chat_history_item_wrapper}>
                            <div className={styles.chat_history_user_msg_item}>
                              <div className={styles.chat_history_item_inner}>
                                <div
                                  style={{
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {typeof item.content === "string" ? (
                                    item.content
                                  ) : (
                                    <>
                                      {
                                        item?.content?.find(
                                          (subItem: any) =>
                                            subItem.type === "text"
                                        )?.text
                                      }
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {Array.isArray(item?.content) && (
                              <div
                                className={styles.chat_history_user_msg_item}
                                // style={{ justifyContent: "flex-start" }}
                              >
                                {item?.content?.filter(
                                  (subItem: any) => subItem.type !== "text"
                                ).length ? (
                                  <div
                                    className={styles.filesList}
                                    style={{
                                      position: "relative",
                                      top: "16px",
                                      width: "100%",
                                    }}
                                  >
                                    <UploadFilesList
                                      fileList={item?.content?.filter(
                                        (subItem: any) =>
                                          subItem.type !== "text"
                                      )}
                                      isChat={true}
                                      cardColor="#F7F8FA"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={styles.askWrap}>
              <Button
                className={classNames(
                  styles.askBtn,
                  isAtBottom ? styles.atBottom : styles.atTop
                )}
                onClick={() => navigate("/")}
              >
                提问超级助理
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.spin}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        )}
      </div>
    </ConfigProvider>
  );
};

export default Share;
