import { Routes, Route, useSearchParams } from "react-router-dom";
import { routerMap } from "./route/index";
import Header from "./components/Header";
import Menu from "./components/Menu";
import { Layout, theme } from "antd";
import useLoginHooks from "@/hooks/useLoginHooks";
import { routerItem } from "./types/common";
import { ConfigProvider } from "antd";
import { HappyProvider } from "@ant-design/happy-work-theme";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  toUsersIslogin,
  getConfig,
  loginCallBack,
  getUserCurrentCompany,
} from "./apis/common";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginModal from "@/components/LoginModal";
import BindPhoneModal from "@/components/BindPhoneModal";
import SelectCompanyModal from "@/components/SelectCompanyModal";
import { updateUserInfo } from "@/store/reducer/userInfo";
import { updateCompanyInfo } from "@/store/reducer/companyInfo";
import { updateConfig } from "@/store/reducer/config";
import { Provider } from "react-redux";
import store, { loginOption, selectCompanyModalOption } from "@/store";
import Cookies from "js-cookie";
import PageLoading from "./components/PageLoading";
import "@/App.css";
import "@/translation/i18n";
import "normalize.css";
import "@/common.scss";
// import "@/fonts/font.css";
import "@/magic.min.css";
import i18n from "@/translation/i18n";
import { isMobile } from "./utils/common";

const { Content } = Layout;
const clientId =
  "943147563033-3f3a5pe55brll3kpe4afr4lanfubvm7e.apps.googleusercontent.com";

function App() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [params] = useSearchParams();
  const code = params.getAll("code")?.[0] || "";
  const state = params.getAll("state")?.[0] || "";
  const pageType = params.getAll("pageType")?.[0] || "";
  const { isLogin, id } = useLoginHooks();
  const hostname = window.location.hostname;
  const pathname = window.location.pathname;
  const isShareRoute = location.pathname === "/share";
  const getTopLevelAndSecondLevelDomain = (hostname: any) => {
    // 分割主机名
    const parts = hostname.split(".");
    // 确保至少有三部分（例如 sub.example.com）
    if (parts.length > 2) {
      // 提取最后两部分作为顶级域名和二级域名
      return parts.slice(-2).join(".");
    }
    // 如果主机名部分不足，返回原始主机名
    return hostname;
  };
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  const [thirdLoginUniqueId, setThirdLoginUniqueId] = useState("");

  const updateUserInfoFn = () => {
    setTimeout(() => {
      toUsersIslogin().then((res: any) => {
        if (res && res?.data?.status === 200) {
          store.dispatch({
            ...updateUserInfo(res.data.data),
            isUserInfoLoading: false,
            token: localStorage.getItem("token"),
          });

          Cookies.set("token", localStorage.getItem("token") as string, {
            domain: `${topLevelAndSecondLevelDomain}`,
          });
          const newURL = window.location.pathname;
          window.history.replaceState({}, "", newURL);
          sendRefreshSignal();
        }
      });
    }, 0);
  };

  const need_binding = localStorage.getItem("need_binding") || "";
  const third_login_unique_id =
    localStorage.getItem("third_login_unique_id") || "";

  useEffect(() => {
    if (need_binding === "1" && third_login_unique_id) {
      store.dispatch(loginOption.openBindPhoneModal());
      setThirdLoginUniqueId(third_login_unique_id);
    }
  }, [need_binding, third_login_unique_id]);

  useEffect(() => {
    if (code && state === "wechat_redirect") {
      loginCallBack({ token: code, login_type: "weixin" }).then((res: any) => {
        const data = res?.data?.data || {};
        if (data?.need_binding) {
          // 需要绑定手机号
          setThirdLoginUniqueId(data?.third_login_unique_id);
          store.dispatch(loginOption.openBindPhoneModal());

          localStorage.setItem("need_binding", "1");
          localStorage.setItem(
            "third_login_unique_id",
            data?.third_login_unique_id
          );
          let url = window.location.pathname;
          window.history.replaceState({ url: url }, "", url);
          return;
        } else {
          localStorage.setItem("token", data?.access);
        }

        if (res && res?.status === 200) {
          updateUserInfoFn();
        }
      });
    }
  }, [state, code]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const url_token = url.searchParams.get("token") || "";
    if (url_token) {
      localStorage.setItem("token", url_token);
    }
    if (isLogin) {
      getUserCurrentCompany().then((res: any) => {
        if (res?.data && res?.data?.status === 200) {
          store.dispatch(updateCompanyInfo(res?.data?.data));
          if (res?.data?.data?.company_id === 0) {
            store.dispatch(selectCompanyModalOption.openSelectCompanyModal());
          }
        }
      });
    }
  }, [isLogin]);

  // 发送信号到其他标签页或窗口
  const sendRefreshSignal = () => {
    localStorage.setItem("refreshSignal", Date.now().toString());
  };

  // 在其他标签页或窗口监听信号，并执行刷新操作
  window.addEventListener("storage", function (event) {
    if (event.key === "refreshSignal") {
      // 这里可以执行刷新操作
      window.location.reload();
    }
  });

  useEffect(() => {
    // do something when route changes
    if (location.pathname !== "/" && !location.pathname) {
      (window as any).gtag("send", "pageview", {
        path: location.pathname,
        userId: id,
      });
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      Cookies.set("token", localStorage.getItem("token") as string, {
        domain: `${topLevelAndSecondLevelDomain}`,
      });
    }

    if (Cookies.get("token")) {
      localStorage.setItem("token", Cookies.get("token") as string);
    }
  }, []);

  useEffect(() => {
    window.onload = () => {
      setIsLoading(false);
    };
  }, []);

  // 获取个人信息
  const getUserInfo = () => {
    if (localStorage.getItem("token") || Cookies.get("token")) {
      store.dispatch({
        ...updateUserInfo({
          isUserInfoLoading: true,
        }),
      });
      toUsersIslogin().then((res: any) => {
        if (res && res?.status === 200) {
          store.dispatch({
            ...updateUserInfo(res.data.data),
            isUserInfoLoading: false,
            token: localStorage.getItem("token") || Cookies.get("token"),
          });
        }
      });
    } else {
      store.dispatch({
        ...updateUserInfo({
          id: 0,
          isUserInfoLoading: false,
          token: "",
        }),
      });
    }
  };

  const getConfigInfo = () => {
    //获取配置信息;
    getConfig({
      keys: [
        "homepage_agent_id",
        "home_question_show_change",
        "login_google_trigger",
        "login_wechat_trigger",
        "support_phone_login",
      ],
    }).then((res: any) => {
      if (res.data && res.data.status === 200) {
        store.dispatch({
          ...updateConfig(res.data.data),
        });
      }
    });
  };

  useEffect(() => {
    if (!Object.hasOwn) {
      Object.defineProperty(Object, "hasOwn", {
        value: function (obj: any, prop: any) {
          return Object.prototype.hasOwnProperty.call(obj, prop);
        },
        writable: true,
        configurable: true,
      });
    }
    getUserInfo();
    getConfigInfo();
    if (!Object.hasOwn) {
      Object.defineProperty(Object, "hasOwn", {
        value: function (obj: any, prop: any) {
          return Object.prototype.hasOwnProperty.call(obj, prop);
        },
        writable: true,
        configurable: true,
      });
    }
    document
      .querySelectorAll("html")[0]
      .setAttribute("theme", localStorage.getItem("theme") || "light");
  }, []);

  useEffect(() => {
    i18n.changeLanguage("zh-cn");
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorBgMask: "var(--theme-bg-mask)",
            },
          }}
        >
          <HappyProvider>
            <GoogleOAuthProvider clientId={clientId}>
              <Layout>
                <Header />
                <Layout
                  style={{
                    paddingTop: isMobile || isShareRoute ? "unset" : "64px",
                  }}
                >
                  {pathname.toLocaleLowerCase() !== "/chat" ? (
                    <Menu />
                  ) : (
                    pageType === "superAssistant" && <Menu />
                  )}
                  <Layout>
                    <Content
                      className="content"
                      style={
                        isShareRoute
                          ? {
                              minHeight: "100vh",
                              height: "100vh",
                              maxHeight: "100vh",
                              overflow: "unset",
                            }
                          : {}
                      }
                    >
                      <Routes>
                        {routerMap.map((item: routerItem, index: number) => {
                          return (
                            <Route
                              key={index}
                              path={item.path}
                              element={item.element}
                            />
                          );
                        })}
                      </Routes>
                    </Content>
                    {/* {isLoading && <PageLoading />} */}
                  </Layout>
                </Layout>
              </Layout>
              <LoginModal />
              <BindPhoneModal third_login_unique_id={thirdLoginUniqueId} />
              <SelectCompanyModal />
            </GoogleOAuthProvider>
          </HappyProvider>
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;
