import { useEffect, useState } from "react";
import { message, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import Styles from "./index.module.scss";
import baseURL from "@/request/getBaseURL";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

type IUpload = {
  onUploadSuccess?: any;
  defaultImageUrl?: string;
  isCustomType?: boolean;
  children?: any;
  handleCloseClick?: any;
  type?: string;
};

const BgUpload = ({
  onUploadSuccess,
  defaultImageUrl,
  isCustomType,
  children,
  handleCloseClick,
  type,
}: IUpload) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (defaultImageUrl) {
      setImageUrl(defaultImageUrl);
    }
  }, [defaultImageUrl]);

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/SVG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div
      className={
        type === "card" ? Styles.upload_card_inside : Styles.upload_inside
      }
    >
      {loading ? (
        <LoadingOutlined
          style={{
            fontSize: "24px",
            color: "#66FF78",
          }}
        />
      ) : (
        <>
          {imageUrl ? (
            <>
              {handleCloseClick ? (
                <div
                  className={
                    showClose ? Styles.closeIcon : Styles.closeIcon_hide
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCloseClick?.();
                  }}
                >
                  <CloseOutlined size={16} />
                </div>
              ) : null}
              <img
                src={imageUrl}
                alt="avatar"
                className={Styles.bg_img}
                loading="lazy"
              />
            </>
          ) : (
            <div className={Styles.add}>
              <span className={classnames("iconfont","icon-increase_line",Styles.addIcon)}></span>
              <div className={Styles.addText}>{t("myagent.uploadImage")}</div>
            </div>
          )}
        </>
      )}
    </div>
  );

  const customRequest = ({ file, onSuccess, onError }: any) => {
    // 在这里自定义上传请求
    const formData = new FormData();
    formData.append("image", file);

    const token = localStorage.getItem("token") || Cookies.get("token");

    // 添加令牌到请求头中
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    fetch(`${baseURL}/aichat/agent/edit/upload_image/`, {
      method: "POST",
      headers,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // 处理上传成功的响应
        onSuccess(data, file);
        if ((data.status = 200)) {
          onUploadSuccess(data.data.image_url);
        }
        message.success("上传成功");
      })
      .catch((error) => {
        console.log(error);
        // 处理上传失败的情况
        onError(error, file);
        message.error("上传失败");
      });
  };

  return (
    <div
      className={isCustomType ? "" : Styles.upload}
      onMouseEnter={() => {
        setShowClose(true);
      }}
      onMouseLeave={() => {
        setShowClose(false);
      }}
    >
      <Upload
        // name={name}
        // listType="picture-card"
        accept="image/jpeg, image/png, image/jpg, image/svg"
        showUploadList={false}
        // action={`${baseURL}/aichat/agent/edit/upload_image/`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={customRequest}
      >
        {isCustomType ? children : uploadButton}
      </Upload>
    </div>
  );
};

export default BgUpload;
