import i18n from "@/translation/i18n";

export const publishStyleList = [
  {
    label: i18n.t("数字员工"),
    value: 10001,
  },
  {
    label: i18n.t("文案助手"),
    value: 10002,
  },
  {
    label: i18n.t("智能客服"),
    value: 10003,
  },
  {
    label: i18n.t("职业顾问"),
    value: 10004,
  },
  {
    label: i18n.t("简历修改"),
    value: 10005,
  },
];
