import React, { useState, useReducer, useEffect } from "react";
import { Layout, Menu, theme } from "antd";
import { PlusCircleOutlined, FormOutlined } from "@ant-design/icons";
import { sidebar, userSiderBar } from "@/common/sidebar";
import styles from "./index.module.scss";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import Avator from "@/assets/common/avator.jpg";
import useLoginHooks from "@/hooks/useLoginHooks";
import CreateAgent from "@/components/CreateAgent";
import classNames from "classnames";
import { Link, Navigate, useLocation } from "react-router-dom";
import useThemeHooks from "@/hooks/useThemeHooks";
import store, { loginOption } from "@/store";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useCompanyInfoHooks from "@/hooks/useCompanyHooks";
import { createImageUrl } from "@/utils/common";

const unLogin = createImageUrl("menu/unLogin.png");
const Avatar = createImageUrl("menu/avator.png");

const { Content, Sider } = Layout;

const { Option } = Select;

const MenuBar = () => {
  const isMobile = window?.innerWidth <= 600;

  const [collapsed, setCollapsed] = useState(isMobile);
  const theme = useThemeHooks();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [, forceRerender] = useReducer((x) => x + 1, 0);
  let navigate = useNavigate();
  const location = useLocation();
  const isShareRoute = location.pathname === "/share";

  function getTopLevelAndSecondLevelDomain(hostname: any) {
    // 分割主机名
    const parts = hostname.split(".");
    // 确保至少有三部分（例如 sub.example.com）
    if (parts.length > 2) {
      // 提取最后两部分作为顶级域名和二级域名
      return parts.slice(-2).join(".");
    }
    // 如果主机名部分不足，返回原始主机名
    return hostname;
  }
  const hostname = window.location.hostname;
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  useEffect(() => {
    console.log("theme", theme);
  }, [theme]);

  const { isLogin, username, isUserInfoLoading } = useLoginHooks();
  const { company_id } = useCompanyInfoHooks();

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const logout = (type: string) => {
    Cookies.set("token", "", {
      domain: `${topLevelAndSecondLevelDomain}`,
    });

    localStorage.removeItem("token");

    window.location.reload();
  };

  // 用户信息组件，根据登录状态显示不同的内容
  const UserInfoComponent = () => {
    return (
      <div className={styles.user_info}>
        <img className={styles.avator} src={isLogin ? Avatar : unLogin}></img>
        {isLogin ? (
          <span className={styles.username}>
            {" "}
            <div
              className={styles.logout}
              onClick={() => {
                logout("logout");
              }}
            >
              {/* icon用不到删除 */}
              {/* <img src={theme === "dark" ? LogoutPic : LogoutLightPic}></img> */}
              {t("退出登录")}
            </div>
            {username}
          </span>
        ) : (
          <>
            {!isUserInfoLoading && (
              <span onClick={onLoginAndRegister}>
                <span className={styles.login}>{t("common.login")}</span>
              </span>
            )}
          </>
        )}
      </div>
    );
  };

  const CreateAgentClick = () => {
    setShow(true);
  };

  const CreateAgentClose = () => {
    setShow(false);
  };

  const sideItem = (item: any, index: number) => {
    let isInPath = item.link === location.pathname;
    if (
      item.key === "seek" &&
      (location.pathname === "/" || location.pathname === "")
    ) {
      isInPath = true;
    }

    if (
      item.key === "knowledge" &&
      location.pathname + location.search === "/my_agent?pageType=knowledge"
    ) {
      isInPath = true;
    }

    if (item?.disabled) {
      return (
        <div key={index} className={styles.sider_menu_item_disabled}>
          <div className={styles.sider_menu_item_icon}>
            <img
              src={theme === "dark" ? item.icon : item.iconLight}
              className={styles.sider_menu_item_icon}
            ></img>
          </div>
          <span>{item.label}</span>
        </div>
      );
    }
    return (
      <Link to={item.link} key={index} className={styles.sider_menu_item_wrap}>
        <div
          className={classNames(
            styles.sider_menu_item,
            isInPath ? styles.sider_menu_item_active : ""
          )}
        >
          <div className={styles.sider_menu_item_icon}>
            {item.icon && (
              <img
                src={
                  isInPath
                    ? item.activeIcon
                    : theme === "dark"
                    ? item.icon
                    : item.iconLight
                }
                className={styles.sider_menu_item_icon}
              ></img>
            )}
          </div>
          <span className={collapsed ? styles.hideTextLabel : styles.textLabel}>
            {item.label}
          </span>
        </div>
        <div
          style={{
            width: 0,
            height: 0,
            overflow: "hidden",
            visibility: "hidden",
            opacity: 0,
          }}
        >
          {item?.desc}
        </div>
      </Link>
    );
  };

  if (isMobile || isShareRoute) {
    return null;
  }

  return (
    <Sider
      width={220}
      collapsedWidth={78}
      className={styles.sider}
      collapsible
      collapsed={collapsed}
      trigger={null}
    >
      <div
        className={styles.menuCollapse}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <span
          className={classNames("icon-line_line", "iconfont", "icon-size20")}
        ></span>
      </div>
      <div className={styles.top_box}>
        <div className={styles.sider_menu}>
          {sidebar.map((item: any, index) => {
            if (item?.type === "list") {
              return (
                <>
                  {/* {item.label && (
                    <div className={styles.sider_label}>{item.label}</div>
                  )} */}
                  {item.children.map((item1: any, index1: number) => {
                    const isZone =
                      item1.label !== t("common.enterprise.zone") ||
                      company_id > 0;
                    if (isZone) {
                      return sideItem(item1, index1);
                    }
                  })}
                </>
              );
            }
            return sideItem(item, index);
          })}
        </div>
      </div>

      <div className={styles.bottom_box}>
        {
          <>
            {!!isLogin && (
              <div className={styles.sider_menu}>
                {/* <div
                className={styles.sider_menu_item}
                onClick={() => {
                  CreateAgentClick();
                }}
              >
                <span className={styles.label}>{t("common.create")}</span>
              </div> */}
                {/* <Link to={"/my_agent"}>
                <div
                  className={classNames(
                    styles.sider_menu_item,
                    "/my_agent" === location.pathname
                      ? styles.sider_menu_item_active
                      : ""
                  )}
                >
                  <span className={styles.label}>{t("common.myAgent")}</span>
                </div>
              </Link> */}
                {userSiderBar.map((item, index) => {
                  let isInPath = item.link === location.pathname;
                  if (
                    item.key === "myAgent" &&
                    location.pathname + location.search ===
                      "/my_agent?pageType=knowledge"
                  ) {
                    isInPath = false;
                  }
                  return (
                    <Link
                      to={item.link}
                      key={index}
                      className={styles.sider_menu_item_wrap}
                    >
                      <div
                        className={classNames(
                          styles.sider_menu_item,
                          isInPath ? styles.sider_menu_item_active : ""
                        )}
                      >
                        <img
                          src={
                            isInPath
                              ? item.activeIcon
                              : theme === "dark"
                              ? item.icon
                              : item.iconLight
                          }
                          className={styles.sider_menu_item_icon}
                        ></img>
                        <span
                          className={
                            collapsed ? styles.hideTextLabel : styles.textLabel
                          }
                        >
                          {item.label}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
          </>
        }

        {/* <UserInfoComponent /> */}
      </div>

      <div
        onClick={() => window.open("https://beian.miit.gov.cn/", "_blank")}
        className={classNames(
          styles.ICPFiling,
          collapsed ? styles.hideTextLabel : styles.textLabel
        )}
      >
        京ICP备2023029057号
      </div>

      <CreateAgent onClose={CreateAgentClose} show={show} />
    </Sider>
  );
};

export default MenuBar;
