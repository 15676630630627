import Styles from "../index.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "@/utils/common";
import eyePic from "@/assets/community/eyegray.svg";
import sharePic from "@/assets/community/share.svg";
import timePic from "@/assets/community/time.svg";
import { numberFormat } from "@/utils/common";
import dayjs from "dayjs";

type IArticleItem = {
  articleItemData: {
    cover_link: string; // 文章图片
    description: string; // 文章摘要
    history_count: number; // 文章浏览总数
    link: string; // 文章外链
    modify_timestamp: number; //时间戳
    share_count: number; // 文章分享总数
    title: string; //  文章标题
    [property: string]: any;
  };
};

const ArticleItem = ({ articleItemData }: IArticleItem) => {
  const { t } = useTranslation();
  return (
    <Link to={articleItemData.link} target="_blank">
      <div className={Styles.article_item_context}>
        <div className={Styles.left}>
          <img
            src={getImageUrl(articleItemData.cover_link)}
            className={Styles.img}
            loading="lazy"
          ></img>
        </div>
        <div className={Styles.right}>
          <div className={Styles.title}>{articleItemData.title}</div>
          <div className={Styles.desc}>{articleItemData.description}</div>

          <div className={Styles.count}>
            <div>
              <img src={eyePic}></img>
              {numberFormat(articleItemData.history_count)}&nbsp;
              {t("community.watched")}
            </div>
            <div>
              <img src={sharePic}></img>
              {numberFormat(articleItemData.share_count)}&nbsp;
              {t("community.share")}
            </div>
            <div>
              <img src={timePic}></img>
              {dayjs(new Date().valueOf()).diff(
                dayjs(articleItemData.modify_timestamp * 1000),
                "day"
              )}
              &nbsp;
              {t("community.days.ago")}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ArticleItem;
