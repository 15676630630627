import { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { toGetModalList, toGetModalHistory } from "@/apis/common";
import useLoginHooks from "@/hooks/useLoginHooks";
import { isMobile } from "@/utils/common";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import superAssistantChatBg from "@/assets/common/superAssistantChatBg.svg";
import SuperAssistantPage from "@/components/ChatBox/superAssistant";

const Chat = () => {
  const { t } = useTranslation();

  const { isLogin } = useLoginHooks();

  const [params] = useSearchParams();
  const messageType = params.getAll("messageType")?.[0] || "";
  const chatMsg = params.getAll("chatMsg")?.[0] || "";
  const topicId = params.getAll("topicId")?.[0] || "";

  const [chatHistory, setChatHistory] = useState<any>([]);

  const [showTopic, setShowTopic] = useState(false);
  const [topicList, setTopicList] = useState<any[]>([]);
  const navigate = useNavigate();

  const getTopic = async () => {
    toGetModalList({
      page_number: 1,
      page_size: 20,
      llm_id: 1000,
    }).then((res) => {
      if (res.data && res.data.status === 200) {
        setTopicList(res.data.data);
      }
    });
  };

  useEffect(() => {
    if (topicId !== "") {
      getModalHistory({ topicId });
    }

    if (chatMsg !== "" || messageType === "workflow") {
      if (messageType === "workflow") return;
      localStorage.setItem("chatMsg", chatMsg);
    }
  }, [chatMsg, topicId, messageType]);

  const changeHistoryAction = (data: any) => {
    if (data && data.length) {
      setChatHistory(data);
    } else {
      setChatHistory([]);
    }
  };

  const getModalHistory = (item: any) => {
    toGetModalHistory({
      page_number: 1,
      page_size: 100,
      topic_id: item.topicId,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          changeHistoryAction(res.data.data.reverse());
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getTopic();
  }, []);

  return (
    <div
      className={Styles.page}
      style={{
        backgroundImage: `url(${superAssistantChatBg})`,
        backgroundSize: "cover",
      }}
    >
      <div
        onClick={() => {
          isMobile ? navigate("/") : setShowTopic(true);
        }}
      >
        <span
          className={classnames(
            Styles.dialogue,
            "iconfont",
            isMobile ? "icon-chat_line" : "icon-history_line",
            isMobile ? Styles.dialogue_m : ""
          )}
        ></span>
      </div>

      <div
        className={classnames(
          showTopic ? Styles.showTopic : Styles.hideTopic,
          Styles.topicList
        )}
      >
        <div className={Styles.topicListHeader}>
          {t("历史对话")}
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setShowTopic(false);
            }}
            className={classnames(
              "iconfont",
              "icon-size24",
              "icon-retract_line"
            )}
          ></div>
        </div>
        <div className={classnames(Styles.topicListContent, "scroll-hide")}>
          {topicList.map((item, index) => {
            let first_question = "";
            try {
              const jsonFirstQuestion = JSON.parse(item.first_question);
              first_question = jsonFirstQuestion?.filter(
                (item: any) => item?.type === "text"
              )[0]?.text;
            } catch (e) {
              first_question = item.first_question;
            }

            return (
              <div
                className={Styles.topicItem}
                key={index}
                onClick={() => {
                  localStorage.removeItem("chatMsg");
                  localStorage.removeItem("chatWorkFlowData");
                  navigate(
                    "/SuperAssistantChat?pageType=superAssistant&topicId=" +
                      item?.topic_id
                  );
                }}
              >
                <div className={Styles.title}>
                  <p style={{ whiteSpace: "pre-wrap" }}>{first_question}</p>
                </div>
                <div className={Styles.time}>
                  {item?.first_question_time &&
                    new Date(item?.first_question_time).toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={Styles.page_context}>
        <SuperAssistantPage messageType={messageType} history={chatHistory} />
      </div>
    </div>
  );
};

export default Chat;
