import React, { useEffect, useState, useRef } from "react";
import Styles from "./index.module.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation, Trans } from "react-i18next";
import { Button, Space, Table, Pagination, message } from "antd";
import type { TableProps } from "antd";
import {
  getMemberList,
  getMemberInfo,
  delMember,
} from "@/apis/common";
import ConfirmModal from "@/components/Modal";
import AddEnterprise from "./components/AddEnterprise";
import useCompanyInfoHooks from "@/hooks/useCompanyHooks";
import { useNavigate } from "react-router-dom";

interface DataType {
  status: number;
  nick_name: string;
  telephone: string;
  is_admin: number;
  end_time: string;
  create_time: string;
  user_id: number;
}

const Home = () => {
  const { company_id, is_company_admin } = useCompanyInfoHooks();
  const { t } = useTranslation();
  const [list, setList] = useState<any>([]);
  const [info, setInfo] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [deleteUserName, setDeleteUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const [showAddModel, setShowAddModel] = useState(false);
  let navigate = useNavigate();

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "姓名",
      dataIndex: "nick_name",
      key: "nick_name",
      align: "left",
      render: (text, value) => <div className={Styles.tableText}>{text}</div>,
    },
    {
      title: "手机号",
      key: "telephone",
      dataIndex: "telephone",
      align: "left",
      render: (text, value) => <div className={Styles.tableText}>{ !!text ? text : '--' }</div>,
    },
    {
      title: "角色",
      dataIndex: "is_admin",
      align: "left",
      key: "is_admin",
      render: (text, value) => (
        <div className={Styles.tableText}>{text ? "管理员" : "普通成员"}</div>
      ),
    },
    {
      title: "结束时间",
      dataIndex: "end_time",
      key: "end_time",
      align: "left",
      render: (text, value) => <div className={Styles.time}>{ !!text ? text : '--' }</div>,
    },
    {
      title: "账号状态",
      align: "left",
      key: "status",
      dataIndex: "status",
      render: (_, record, index) => (
        <div
          className={
            record.status === 1 ? Styles.activate : record.status === 0 ? Styles.none_activate : Styles.delete
          }
        >
          {record.status === 1
            ? "正常"
            : record.status === 0
            ? "未激活"
            : "已删除"}
        </div>
      ),
    },
    {
      title: "操作",
      align: "left",
      key: "id",
      dataIndex: "id",
      render: (_, record, index) => (
        <Space size="middle">
          {record.status !== 2 && !record.is_admin && (
            <div className={Styles.deleteText} onClick={() => onDelete(record)}>
              {"删除"}
            </div>
          )}
        </Space>
      ),
    },
  ];

  const ConfirmModalRef = useRef<any>(null);
  const onDelete = (info: any) => {
    setDeleteUserName(info?.nick_name);
    setUserId(info?.user_id);
    ConfirmModalRef?.current?.showModal();
  };

  const closeAddModal = () => {
    setShowAddModel(false);
  };

  const confirmFn = () => {
    delMember({
      user_id: userId,
      company_id: company_id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        message.success(t("myagent.delete.success"));
        ConfirmModalRef?.current?.hideModal();
        setPageNo(1);
        getList();
        getInfo();
      }
    });
  };

  const getInfo = () => {
    getMemberInfo({
      company_id: company_id,
    }).then((res) => {
      if (res && res.data && res.status === 200) {
        let data = res.data.data;
        setInfo(data);
      }
    });
  };

  useEffect(() => {
    if(company_id <= 0){
      navigate(`/`);
    }else{
      getInfo()
      getList();
    }
  }, []);

  useEffect(() => {
    getList();
  }, [pageNo]);

  const getList = () => {
    setLoading(true);
    getMemberList({
      page_size: pageSize,
      page_number: pageNo,
      company_id: company_id,
    })
      .then((res) => {
        setLoading(false);
        if (res && res.data && res.status === 200) {
          let data = res.data.data;
          setList(data);
          setTotal(res.data.total_items || []);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  const handleMessage = () => {
    setPageNo(1);
    getList();
    getInfo();
  };

  return (
    <div className={Styles.page}>
      <div className={Styles.page_header}>
        <div className={Styles.title}>
          <img className={Styles.logo} src={info.logo} />
          <div>{info.name}</div>
        </div>
        <div className={Styles.number_box}>
          <div className={Styles.number}>
            <div>
              现有员工数量 <span>{info.employee_num}</span>
            </div>
            <div>
              可用席位数量 <span>{info.available_seat}</span>
            </div>
          </div>
          <div>
            <Button
              className={Styles.publish_btn}
              onClick={() => {
                setShowAddModel(true);
              }}
            >
              {t("common.invite.member")}
            </Button>
          </div>
        </div>
      </div>
      <div className={Styles.table_box}>
        {!loading && list.length >= 0 ? (
          <div className={Styles.wrap}>
            <Table
              columns={columns}
              dataSource={list}
              pagination={false}
              rowKey={(record) => "" + record.create_time + record.user_id}
            />
          </div>
        ) : null}
        <div className={Styles.pagination}>
          <Pagination
            pageSize={pageSize}
            current={pageNo}
            total={total}
            showSizeChanger={false}
            hideOnSinglePage
            onChange={changePagination}
          />
        </div>
      </div>
      <ConfirmModal
        ref={ConfirmModalRef}
        confirmFn={confirmFn}
        title={t("enterprise.delete.title")}
      >
        <Trans
          i18nKey="enterprise.delete.content"
          values={{
            name: deleteUserName,
          }}
        >
          {deleteUserName}
        </Trans>
      </ConfirmModal>
      <AddEnterprise
        key={new Date().getTime()}
        showAddModel={showAddModel}
        companyId={company_id}
        handleMessage={handleMessage}
        closeAddModal={closeAddModal}
      />
    </div>
  );
};

export default Home;
