import i18n from "@/translation/i18n";

export const wsErrorMsg = [
  "系统有点小情绪，请稍后再试哦~",
  "小故障啦，我们正在处理，稍候再试吧~",
  "服务器偷了个小懒，请稍等片刻~",
  "服务器打个盹，请稍后再试~",
  "服务器正在开小差，稍等一下哦~",
  "我们正在努力恢复服务，请稍后再试~",
  "服务器有点小问题，我们正在修复中~",
];

export const getRandomError = () => {
  return wsErrorMsg[Math.floor(Math.random() * wsErrorMsg.length)];
};
