import Styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import { IAgentInfoProps } from "@/types/common";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useTranslation } from "react-i18next";
import {
  addPubAgent,
  deletePubAgent,
  toGetAgentPublicInfo,
} from "@/apis/common";
import { numberFormat } from "@/utils/common";
import _, { divide } from "lodash";
import classnames from "classnames";
import { Button, Tooltip } from "antd";
import DynamicForm from "../ChatWorkflow/components/DynamicForm";
import { useNavigate } from "react-router-dom";
import qs from "qs";

const agentBg = createImageUrl("common/agent-bg.png");

const SeekingItem = ({
  agentInfo,
  likeStatusObj,
  setLikeStatusObj,
  isSelfItem = false,
  reloadList,
  userNameObj,
  collectSwitch,
  tagNameObj,
  pageViewSwitch,
  clearWoke,
  onSend,
}: IAgentInfoProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const itemRef: any = useRef();
  const [isLiking, setIsLiking] = useState(false);
  const curId = isSelfItem ? agentInfo?.pub_agent_id : agentInfo?.id;
  const [isShowDynamicForm, setIsShowDynamicForm] = useState(false);

  const clearDynamicForm = (fileArr: any) => {
    setIsShowDynamicForm(false);
  };
  // 收藏逻辑
  const collectAction = () => {
    if (isLiking) {
      return;
    }

    if (curId && setLikeStatusObj) {
      setIsLiking(true);
      if (!likeStatusObj[curId]?.is_my_collect) {
        addPubAgent({ pub_agent_id: curId, show_mode: 1 })
          .then((res) => {
            setIsLiking(false);
            if (res && res.data && res.status === 200) {
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  ...newLikeStatusObj[curId],
                  is_my_collect: true,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      } else {
        deletePubAgent({ pub_agent_ids: [curId], show_mode: 1 })
          .then((res) => {
            if (res && res.data && res.status === 200) {
              setIsLiking(false);
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  ...newLikeStatusObj[curId],
                  is_my_collect: false,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
              reloadList && reloadList(1);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      }
    }
  };
  // 跳转逻辑
  const toChat = () => {
    if (pageViewSwitch) {
      return;
    }
    if (agentInfo?.jump_url) {
      window.open(agentInfo?.jump_url);
      return;
    }
    localStorage.setItem("toChatInfo", "");
    toGetAgentPublicInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          const toChatInfo = res.data.data;
          localStorage.setItem("toChatInfo", JSON.stringify(toChatInfo));
          window.open(
            `/Chat?${qs.stringify({
              publicId: agentInfo.id,
              type: "public",
              isDialogue: 1,
              isCollect: likeStatusObj[curId || ""]?.is_my_collect,
              email: `@${userNameObj[agentInfo?.author || ""]}`,
            })}`
          );
        }
      }
    });
  };
  return (
    <div
      className={classnames(
        Styles.agent_item,
        pageViewSwitch ? Styles.handiness : ""
      )}
      ref={itemRef}
    >
      <div
        className={Styles.agent_item_img_box}
        onClick={() => {
          toChat();
        }}
      >
        {collectSwitch && (
          <div className={Styles.agent_item_collect}>
            <Tooltip
              placement="bottom"
              title={
                likeStatusObj[curId || ""]?.is_my_collect
                  ? t("common.move.out.team")
                  : t("common.join.my.ai.team")
              }
              color={"#000"}
              zIndex={10000}
            >
              <div
                className={Styles.iconfont_collect}
                onClick={(e) => {
                  e.stopPropagation();
                  collectAction();
                }}
              >
                <span
                  className={classnames(
                    "iconfont",
                    "icon-size24",
                    likeStatusObj[curId || ""]?.is_my_collect
                      ? "icon-staractive_fill"
                      : "icon-star_line"
                  )}
                ></span>
              </div>
            </Tooltip>
          </div>
        )}
        <img
          className={Styles.agent_item_img}
          src={
            getImageUrl(agentInfo?.avatar_url || agentInfo?.img_url) || agentBg
          }
          onError={(e: any) => {
            e.target.src = agentBg;
          }}
          loading="lazy"
        ></img>
        <div className={Styles.agent_item_box}>
          <div className={Styles.agent_item_box_title}>{agentInfo?.name}</div>
          <div
            className={classnames(
              Styles.agent_item_box_synopsis,
              agentInfo?.tags && agentInfo?.tags.length > 0
                ? ""
                : Styles.agent_item_box_clamp
            )}
          >
            {agentInfo?.intro || agentInfo?.desc}
          </div>
          {agentInfo?.tags && agentInfo?.tags.length > 0 && (
            <div className={Styles.agent_item_box_tags}>
              {agentInfo?.tags.map((item: any, index: number) => {
                return (
                  <div className={Styles.agent_item_box_tag} key={index}>
                    {tagNameObj[item] || ""}
                  </div>
                );
              })}
            </div>
          )}
          <div className={Styles.agent_item_box_info}>
            <div className={Styles.agent_item_box_info_name}>
              @
              {userNameObj[agentInfo?.author || ""] ||
                agentInfo?.create_user ||
                ""}
            </div>
            <div className={Styles.agent_item_box_info_right}>
              {pageViewSwitch ? (
                <div
                  onClick={() => {
                    setIsShowDynamicForm(true);
                  }}
                  className={Styles.agent_item_box_info_btn}
                >
                  使用
                </div>
              ) : (
                <div>
                  <span
                    className={classnames(
                      Styles.img,
                      "iconfont",
                      "icon-heat_fill"
                    )}
                  ></span>
                  <span>{numberFormat(agentInfo?.hot)}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isShowDynamicForm ? (
        <DynamicForm
          isShowWorkForm={isShowDynamicForm}
          clearDynamicForm={clearDynamicForm}
          clearWoke={clearWoke}
          id={agentInfo?.id}
          onSend={onSend}
        />
      ) : null}
    </div>
  );
};

export default SeekingItem;
