import { createSlice } from "@reduxjs/toolkit";

type componyInfo = {
  company_id: number | string;
  is_company_admin: boolean;
};

export const userInfoSlice = createSlice({
  name: "companyInfo",
  initialState: {
    company_id: -1,
    is_company_admin: false,
    company_logo: "",
    company_name: "",
  },
  reducers: {
    updateCompanyInfo: (state, action) => {
      state.company_id = action.payload?.company_id;
      state.is_company_admin = action.payload?.is_company_admin;
      state.company_logo = action.payload?.company_logo;
      state.company_name = action.payload?.company_name;
    },
  },
});

export const { updateCompanyInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;
