import { useEffect, useState } from "react";

const useThemeHooks = () => {
  const [theme, setTheme] = useState("");
  let htmlDom: any = document.getElementsByTagName("html")[0];

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "theme"
        ) {
          setTheme(htmlDom.getAttribute("theme"));
        }
      });
    });

    observer.observe(htmlDom, { attributes: true });

    // 初始设置
    setTheme(htmlDom.getAttribute("theme"));

    // 清理函数
    return () => observer.disconnect();
  }, []);

  return theme;
};

export default useThemeHooks;
