import React, { useEffect, useState } from "react";
import { Progress, Space, Table, Tag } from "antd";
import type { TableProps } from "antd";
import Styles from "./index.module.scss";
import Error from "./Error";
import Tick from "./Tick";
import Delete from "./Delete";

import { formatFileSize } from "@/utils/format";
import baseURL from "@/request/getBaseURL";
import { updateFiles } from "@/store/reducer/knowledgeFiles";
import { useSelector } from "react-redux";
import store from "@/store";

interface DataType {
  status: string;
  file_name: string;
  file_size: string;
  uid: string;
  id: string;
  file: any;
}

const DynamicProgress = () => {
  const [percent, setPercent] = useState(10); // 初始值为10%

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prevPercent) => {
        const newPercent = prevPercent + 1; // 每次增加1%
        return newPercent > 99 ? 99 : newPercent; // 限制最大值为99%
      });
    }, 1000); // 每秒增加一次
    return () => clearInterval(interval); // 在组件卸载时清除定时器
  }, []);

  return (
    <Progress
      showInfo={false}
      strokeColor={{ "0%": "#C7FF03", "100%": "#03FFF0" }}
      percent={percent}
    />
  );
};

const ProgressForm = (props: any) => {
  const [list, setList] = useState<any>([]);
  const { data, changeCreateInfo, updateAgent, hideIdList, handleHideIdList } = props;
  const installFile = (url: string) => {
    window.open(
      `${/^[(http)(https)]/.test(url) ? url : baseURL + url}`,
      "_blank"
    );
  };

  const knowledgeFiles = useSelector((state: any) => state.knowledgeFiles.knowledgeFiles);

  const deleteFile = (index: number) => {
    const updateList = [...list];
    const deleteItem = updateList.splice(index, 1);
    if (deleteItem?.[0]?.id) {
      const latestDeleteIdList = Array.from(new Set([...hideIdList, deleteItem?.[0]?.id]));
      const newKnowledge = updateList.filter(item => !latestDeleteIdList.includes(item.id));
      handleHideIdList(latestDeleteIdList);
      store.dispatch(updateFiles({ knowledgeFiles: newKnowledge, from: "progress" }));
      changeCreateInfo("flow_config.knowledge_base", newKnowledge);
    }
    if (!deleteItem?.[0]?.status) {
      const newKnowledge = updateList.filter((item) => !item.status) || [];
      changeCreateInfo("flow_config.knowledge_base", newKnowledge);
    }
    setList(updateList);
  };
  const [progressBars, setProgressBars] = useState(
    Array.from({ length: 2 }, () => 0)
  );

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "file_name",
      key: "name",
      width: "40%",
      render: (text, { file }) => (
        <div className={Styles.tableText}>
          <span
            onClick={() => {
              installFile(file);
            }}
          >
            {text}
          </span>
        </div>
      ),
    },

    {
      title: "status",
      key: "status",
      dataIndex: "status",
      width: 180,
      render: (_, { status }, index) => (
        <>
          {status === "uploading" ? (
            <DynamicProgress />
          ) : status === "error" ? (
            <Error />
          ) : (
            <Tick />
          )}
        </>
      ),
    },
    {
      title: "Size",
      dataIndex: "file_size",
      key: "file_name",
      render: (text, value) => (
        <div className={Styles.tableText}>
          {formatFileSize(Number(value.file_size))}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record, index) => (
        <Space size="middle">
          {record.status === "uploading" ? (
            <></>
          ) : (
            <div className={Styles.tableText} onClick={() => deleteFile(index)}>
              <Delete />
            </div>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const { curFileLists, knowledge_base } = data;
    setList(
      [
        ...(curFileLists.filter((item: any) => item.status) || []),
        ...(knowledge_base || []),
      ].map((item: any) => {
        return {
          file_name: item?.file_name || item?.name,
          status: item?.status,
          file_size: item?.file_size || item?.size,
          id: item?.id,
          uid: item?.uid,
          file: item?.file,
        };
      })
    );
  }, [props]);

  return (
    <>
      {list.length ? (
        <div className={Styles.wrap}>
          <Table
            columns={columns}
            dataSource={list.filter((item: any) =>
              hideIdList.indexOf(item?.id || item?.uid)
            )}
            pagination={false}
            rowKey={(record) => "" + record.uid + record.id}
          />
        </div>
      ) : null}
    </>
  );
};
export default ProgressForm;
