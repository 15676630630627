import Styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Input } from "antd";
import { getAgentCategory, getJsonConfig } from "@/apis/common";

type IAiListSearch = {
  keysSearchList?: any;
  getSearchList?: any;
  setAiSearch?: any;
  showMode:number
  total:number
};

const AiListSearch = ({
  keysSearchList,
  getSearchList,
  setAiSearch,
  showMode,
  total
}: IAiListSearch) => {
  const { t } = useTranslation();
  const [onFocus, setOnFocus] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [searchMsg, setSearchMsg] = useState("");
  const [hotList, setHotList] = useState([]);
  const [slideshowList, setSlideshowList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [category, setCategory] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [activeCategory, setActiveCategory] = useState<any>({
    id: -1,
  });
  const [activeSubCategory, setActiveSubCategory] = useState<any>([]);
  const activeSub = useRef(activeSubCategory);

  const headerRender = () => {
    return (
      <div className={Styles.page_header}>
        <div className={Styles.page_header_title}>AI助理广场</div>
        <div className={classnames(Styles.category)}>
          {category &&
            category.length > 0 &&
            category.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={Styles.category_item}
                  onClick={() => {
                    changeCategory(item);
                  }}
                >
                  <div
                    className={classnames(
                      Styles.sider_menu_item,
                      activeCategory?.id === item.id
                        ? Styles.sider_menu_item_active
                        : ""
                    )}
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
        </div>
        <div>
          {activeCategory.tags && activeCategory.tags.length > 0 && (
            <div className={Styles.category}>
              {activeCategory.tags.map((item: any, index: number) => {
                return (
                  <div
                    key={item + "_" + index}
                    className={classnames(
                      Styles.category_item_two,
                      activeSub.current.includes(item)
                        ? Styles.category_item_two_active
                        : ""
                    )}
                    onClick={() => {
                      changeSubCategory(item);
                    }}
                  >
                    <div className={Styles.sub_name}>{item}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  const emptyRender = () => {
    return (
      <div className={Styles.page_header}>
        <div className={Styles.page_header_empty_box}>
          {total && total > 0
            ? `"${searchName}"的搜索结果（找到${total}个AI助理)`
            : "未找到相关AI助理"}
        </div>
      </div>
    );
  };

  const getCategory = () => {
    getAgentCategory({
      show_mode: showMode,
    }).then((res) => {
      if (res.data && res.data.status === 200 && res.data.data.categories) {
        setCategory(
          [
            {
              id: -1,
              name: "全部",
              key: "common.category.recent",
              tags: [],
            },
            ...res.data.data.categories,
          ] || []
        );
      }
    });
  };

  // 获取热门词
  const getHotList = () => {
    setLoading(true);
    getJsonConfig({ keys: ["ai_assistant_hot_words"] }).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data.ai_assistant_hot_words;
        setHotList(data);
      }
    });
  };

  // 获取轮播图
  const getSlideshowList = () => {
    setLoading(true);
    getJsonConfig({ keys: ["ai_assistant_carousel_chart"] }).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data.ai_assistant_carousel_chart;
        setSlideshowList(data);
      }
    });
  };

  const changeCategory = (item: any) => {
    setActiveCategory(item);
    setActiveSubCategory([]);
    getSearchList(item);
  };

  const changeSubCategory = (item: any) => {
    let data = activeSubCategory;
    if (data.length > 0 && activeSubCategory[0] === item) {
      data = [];
    } else {
      data = [item];
    }
    setActiveSubCategory(data);
    getSearchList("", data);
  };

  useEffect(() => {
    getCategory();
    getHotList(); // 获取热门词
  }, []);

  const searchText = () => {
    setIsSearch(true);
    setAiSearch(true);
    setSearchName(searchMsg);
    keysSearchList(searchMsg);
  };

  const clear = () => {
    setIsSearch(false);
    setAiSearch(false);
    setSearchMsg("");
    setSearchName("");
    setActiveCategory({
      id: -1,
    });
    setActiveSubCategory([]);
    getSearchList({
      id: -1,
    },[])
  };

  useEffect(() => {
    activeSub.current = activeSubCategory;
  });

  return (
    <div className={Styles.ai_list_page}>
      <div className={Styles.header_box}>
        <div className={Styles.search}>
          <div className={Styles.chat_insert}>
            <div className={Styles.chat_input_box_wrap}>
              <div
                className={classnames(
                  onFocus
                    ? Styles.chat_input_wrap_active
                    : Styles.chat_input_wrap,
                  Styles.chat_insert_bgc
                )}
              >
                <Input.TextArea
                  onFocus={() => {
                    setOnFocus(true);
                  }}
                  onBlur={() => {
                    setOnFocus(false);
                  }}
                  className={classnames(
                    Styles.chat_insert_input,
                    Styles.chat_insert_bgc
                  )}
                  value={searchMsg}
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  onChange={(e) => {
                    setSearchMsg(e.target.value);
                    if (e.target.value === "") {
                      setActiveSubCategory([]);
                      getSearchList();
                    }
                  }}
                  placeholder={t("chat.input.placeholder")}
                  onPressEnter={(e) => {
                    if (!isTyping) {
                      searchText();
                    }
                    e.preventDefault();
                  }}
                  onCompositionStart={() => {
                    setIsTyping(true);
                  }}
                  onCompositionEnd={() => {
                    setIsTyping(false);
                  }}
                ></Input.TextArea>

                {searchMsg && searchMsg.length > 0 ? (
                  <div
                    className={classnames(Styles.chat_insert_delete,'iconfont',"icon-a-cleanup_fill","icon-size24")}
                    onClick={() => {
                      clear();
                    }}
                  ></div>
                ) : (
                  <div
                    className={Styles.chat_insert_right}
                    onClick={() => {
                      searchText();
                    }}
                  >
                    <div className={classnames(Styles.img,"iconfont","icon-size15","icon-a-searchfor_line")}></div>
                    <div>搜索</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.hot_boxs}>
          <div className={Styles.hot_box}>
            <span>推荐词：</span>
            {hotList &&
              hotList.length > 0 &&
              hotList.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={Styles.hot_list}
                    onClick={() => {
                      setIsSearch(true);
                      setAiSearch(true);
                      setSearchMsg(item);
                      setSearchName(item);
                      keysSearchList(item);
                    }}
                  >
                    <div className={Styles.sider_menu_item}>{item}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {isSearch && searchMsg && searchMsg.length > 0
        ? emptyRender()
        : headerRender()}
    </div>
  );
};

export default AiListSearch;
