const ShareIcon = ({ isShareIconHover }: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={isShareIconHover ? "#272B30" : "#80868d"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6299_17162)">
        <path d="M7.99963 10.549C7.67711 10.549 7.41535 10.2872 7.41535 9.96472V1.99969L6.14236 3.27802C6.02862 3.39331 5.87827 3.45018 5.72869 3.45018C5.57989 3.45018 5.43032 3.39331 5.31658 3.27957C5.08832 3.05209 5.08676 2.68283 5.31502 2.45379L7.56622 0.192553C7.67316 0.0743115 7.82777 -1.52588e-05 7.99963 -1.52588e-05C8.1718 -1.52588e-05 8.32666 0.0745764 8.43362 0.193185L10.685 2.45379C10.9124 2.68283 10.9117 3.05209 10.6834 3.27957C10.4544 3.50705 10.0851 3.50705 9.85762 3.27802L8.58391 1.99951V9.96472C8.58391 10.2872 8.32216 10.549 7.99963 10.549Z" />
        <path d="M12.3353 16H3.66452C1.75977 16 0.209473 14.4505 0.209473 12.5449V8.7471C0.209473 6.84234 1.75977 5.29283 3.66452 5.29283H4.39137C4.71389 5.29283 4.97565 5.55458 4.97565 5.87711C4.97565 6.19963 4.71389 6.46139 4.39137 6.46139H3.66452C2.40326 6.46139 1.37804 7.48661 1.37804 8.7471V12.5449C1.37804 13.8062 2.40326 14.8314 3.66452 14.8314H12.3353C13.5958 14.8314 14.6218 13.8062 14.6218 12.5449V8.74009C14.6218 7.48349 13.5996 6.46139 12.3438 6.46139H11.6092C11.2867 6.46139 11.0249 6.19963 11.0249 5.87711C11.0249 5.55458 11.2867 5.29283 11.6092 5.29283H12.3438C14.2439 5.29283 15.7903 6.83923 15.7903 8.74009V12.5449C15.7903 14.4505 14.24 16 12.3353 16Z" />
      </g>
    </svg>
  );
};

export default ShareIcon;
