import { useEffect, useState } from "react";
import { Modal, message, Input } from "antd";
import Styles from "./index.module.scss";
import classnames from "classnames";
import { SearchOutlined } from "@ant-design/icons";
import ToolItem from "@/components/ToolItems";
import Skeleton from "react-loading-skeleton";
import { getAgentCategory, getWorkflowList } from "@/apis/common";
import NoContent from "@/components/NoContent";

type WokeProps = {
  isShowWork: boolean;
  clearWoke: (props: any) => void;
  onSend: any;
};

const Workflow = ({ isShowWork, clearWoke, onSend }: WokeProps) => {
  const [pageType, setPageType] = useState<number>(-1);
  const [tagNameObj, setTagNameObj] = useState({});
  const [userNameObj, setUserNameObj] = useState({});
  const [likeStatusObj, setLikeStatusObj] = useState({});
  const [searchCont, setSearchCont] = useState("");
  const [category, setCategory] = useState<any>([]);
  const [wokeList, setWokeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCategory = () => {
    getAgentCategory({
      show_mode: 3,
    }).then((res) => {
      if (res.data && res.data.status === 200 && res.data.data.categories) {
        setCategory(
          [
            {
              id: -1,
              name: "全部",
              key: "common.category.recent",
              tags: [],
            },
            ...res.data.data.categories,
          ] || []
        );
      }
    });
  };

  const SkeletonItem = () => {
    return (
      <div className={Styles.skeleton}>
        <Skeleton width="100%" height={200} />
        <div className={Styles.skeleton_info}>
          <div>
            <Skeleton width={30} height={30} borderRadius="50%" />
          </div>
          <div className={Styles.skeleton_info_name}>
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
        </div>
      </div>
    );
  };

  const getList = () => {
    setLoading(true);
    const param: any = {
      page_number: 1,
      page_size: 999,
      keyword: searchCont,
      category_id: pageType,
    };
    getWorkflowList(param).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data;
        setWokeList(data);
      }
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getList();
  }, [pageType, searchCont]);

  return (
    <div>
      <Modal
        title={"选择工作流"}
        width={991}
        open={isShowWork}
        onCancel={clearWoke}
        footer={null}
        style={{ height: 700 }}
        className={Styles.wokePage}
      >
        <div className={Styles.header}>
          <div className={classnames(Styles.category, "scroll-hide")}>
            {category.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={classnames(
                    Styles.category_item,
                    pageType === item.id ? Styles.category_item_active : ""
                  )}
                  onClick={() => {
                    setPageType(item.id);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <Input
            className={Styles.input}
            size="large"
            placeholder="搜索工作流"
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e: any) => {
              setSearchCont(e.currentTarget.value);
            }}
            onPressEnter={(e: any) => {
              setSearchCont(e.target.defaultValue);
              e.preventDefault();
            }}
          />
        </div>
        <div className={Styles.body}>
          <div className={Styles.page_recommand}>
            {wokeList && wokeList.length > 0 ? (
              wokeList?.map((item: any, index) => {
                return (
                  <div className={Styles.page_recommand_item} key={index}>
                    {!loading && item ? (
                      <ToolItem
                        agentInfo={item}
                        tagNameObj={tagNameObj}
                        userNameObj={userNameObj}
                        likeStatusObj={likeStatusObj}
                        setLikeStatusObj={setLikeStatusObj}
                        collectSwitch={false}
                        pageViewSwitch={true}
                        clearWoke={clearWoke}
                        onSend={onSend}
                      />
                    ) : (
                      <SkeletonItem />
                    )}
                  </div>
                );
              })
            ) : (
              <NoContent />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Workflow;
