import React, { useState } from "react";
import Card from "./card";
import PdfIcon from "@/assets/file/pdf.svg";
import CsvIcon from "@/assets/file/CSV.svg";
import JpgIcon from "@/assets/file/JPG.svg";
import mdIcon from "@/assets/file/MD.svg";
import Mp3Icon from "@/assets/file/MP3.svg";
import Mp4Icon from "@/assets/file/MP4.svg";
import TxtIcon from "@/assets/file/TXT.svg";
import XlsxIcon from "@/assets/file/xlsx.svg";
import WordIcon from "@/assets/file/word.svg";
import PptIcon from "@/assets/file/PPT.svg";

import style from "./index.module.scss";
import { isMobile } from "@/utils/common";

const getImg = (fileName: string) => {
  const fileType = fileName?.substring(fileName.lastIndexOf("."));

  switch (fileType) {
    case ".pdf":
      return PdfIcon;
    case ".csv":
      return CsvIcon;
    case ".jpg":
    case ".jpeg":
    case ".png":
    case ".webp":
      return JpgIcon;
    case ".md":
      return mdIcon;
    case ".mp3":
    case ".wav":
      return Mp3Icon;
    case ".mp4":
      return Mp4Icon;
    case ".txt":
      return TxtIcon;
    case ".xlsx":
      return XlsxIcon;
    case ".doc":
    case ".docx":
      return WordIcon;
    case ".ppt":
    case ".pptx":
      return PptIcon;
    default:
      return PdfIcon;
  }
};

const UploadFilesList = (props: any) => {
  const { fileList, onDelete, isChat, isMobileChat, cardColor } = props;
  const [showDeleteBtn, setShowDeleteBtn] = useState(!!onDelete);
  const deleteCardFn = (index: number) => {
    onDelete && onDelete(index);
  };

  return (
    <div
      className={`${isMobileChat ? style.mobileChatPage : style.page} ${
        isChat && isMobile ? style.fileOfmobileChat : ""
      }`}
      style={
        isChat
          ? {
              backgroundColor: "transparent",
              boxShadow: "none",
              paddingLeft: "0",
              maxHeight: "100%",
              overflow: "auto",
              padding: "0 0 16px 0",
              ...(fileList && fileList.length < 3 && !isMobile
                ? {
                    width: fileList.length === 1 ? "33%" : "66%",
                    overflow: "visible",
                    display: "flex",
                    justifyContent: "flex-end",
                    float: "right",
                  }
                : {}),
            }
          : {}
      }
    >
      {fileList.map((file: any, index: number) => {
        const fileName = file?.response?.data?.file_name || file?.name;
        const fileType = fileName?.substring(fileName.lastIndexOf("."));

        return (
          <Card
            key={file?.response?.data?.file_url || file?.name + index}
            imgSrc={getImg(fileName)}
            file_url={file?.response?.data?.file_url}
            file_name={file?.response?.data?.file_name || file?.name}
            file_type={file?.response?.data?.file_type || fileType}
            status={
              file.status === "uploading" ||
              isChat ||
              (file?.response?.data && file?.response?.data?.file_url)
                ? file.status
                : "error"
            }
            deleteCardFn={() => {
              deleteCardFn(index);
            }}
            isChat={isChat}
            showDeleteBtn={showDeleteBtn}
            wrapStyle={{
              maxWidth: fileList.length > 2 && isMobile ? "75%" : "100%",
              backgroundColor: cardColor,
            }}
          />
        );
      })}
    </div>
  );
};

export default UploadFilesList;
