interface IObjectKeys {
  [key: string]: string | undefined;
}

interface IhostArr {
  [key: string]: {
    http: string;
    blog_http: string;
    ws: string;
    file?: string;
    wsHttp?: string;
  };
}

const hostArr: IhostArr = {
  mock: {
    http: "http://127.0.0.1:4523/m1/3830612-0-default/",
    blog_http: "https://blog.syngents.cn",
    ws: "ws://127.0.0.1:4523/m1/3830612-0-default/",
  },
  dev: {
    http: "http://127.0.0.1:4523/m1/3830612-0-default/",
    blog_http: "https://blog.syngents.cn",
    ws: "ws://127.0.0.1:4523/m1/3830612-0-default/",
  },
  test: {
    http: "http://49.232.244.254:8001",
    blog_http: "https://blog.syngents.cn",
    ws: "ws://49.232.152.95:8002",
  },
  b_test: {
    http: "http://49.232.244.254:8002",
    blog_http: "https://blog.syngents.cn",
    file: "http://49.232.244.254:8004",
    ws: "ws://49.232.152.95:8002",
    wsHttp: "http://49.232.152.95:8002",
  },
  uat: {
    http: "http://127.0.0.1:4523/m1/3830612-0-default/",
    blog_http: "https://blog.syngents.cn",
    ws: "ws://127.0.0.1:4523/m1/3830612-0-default/",
  },
  prod: {
    http: "https://synerai.zhiji.ai",
    blog_http: "https://blog.syngents.cn",
    ws: "wss://ws.zhiji.ai",
  },
  b_prod: {
    http: "https://synerai.syngents.cn",
    blog_http: "https://blog.syngents.cn",
    file: "https://file.syngents.cn",
    ws: "wss://ws.syngents.cn",
    wsHttp: "https://ws.syngents.cn",
  },
};

export default hostArr;
