import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form, message } from "antd";
import Styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { selectCompanyModalOption } from "@/store";
import store from "@/store";
import { useTranslation } from "react-i18next";
import { getUserCompanyList, userCompanySwitch } from "@/apis/common";
import RightIcon from "@/assets/common/right-arrow.svg";
import useLoginHooks from "@/hooks/useLoginHooks";
import { createImageUrl } from "@/utils/common";

const logoIcon = createImageUrl('common/logoIcon.png');
const LogoLight = createImageUrl('common/header-logo-light.png');
const Avatar = createImageUrl('menu/avator.png');

const SelectCompanyModal = () => {
  const { t } = useTranslation();
  const modalVisible =
    useSelector(
      (state: any) => state?.selectCompanyModal?.selectCompanyModalVisible
    ) || false;

  const handleCancel = () => {
    store.dispatch(selectCompanyModalOption.closeSelectCompanyModal());
  };
  const { username, isLogin } = useLoginHooks();

  const [companyList, setCompanyList] = useState<any[]>([]);

  const switchCompany = (company_id: number) => {
    userCompanySwitch(company_id).then((res: any) => {
      if (res?.data && res?.data.status === 200) {
        message.success("切换成功");
        handleCancel();
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    if (modalVisible && isLogin) {
      getUserCompanyList().then((res: any) => {
        if (res?.data && res?.data.status === 200) {
          setCompanyList(res?.data?.data);
        }
      });
    }
  }, [isLogin, modalVisible]);
  return (
    <Modal
      title={null}
      open={modalVisible}
      className={Styles.modal}
      onCancel={handleCancel}
      maskClosable={false}
      centered
      footer={null}
      width={480}
    >
      <div className={Styles.content}>
        <img src={LogoLight} width={255} className={Styles.logo} />
        <div
          className={Styles.itemWrap}
          onClick={() => {
            switchCompany(-1);
          }}
        >
          <div className={Styles.itemContent}>
            <img src={Avatar} width={24} className={Styles.itemIcon} />
            <span>{t("company.personalVersion")}</span>
          </div>
          <img src={RightIcon} width={16} />
        </div>
        {!!companyList.length && (
          <div className={Styles.bindTips}>
            <span>{username} </span>
            <span>{t("company.hasBound")}</span>
          </div>
        )}
        {companyList.map((item: any, index: number) => {
          return (
            <div
              className={Styles.itemWrap}
              key={index}
              onClick={() => {
                switchCompany(item?.id);
              }}
            >
              <div className={Styles.itemContent}>
                <img src={item?.logo} width={24} className={Styles.itemIcon} />
                <span>{item?.name}</span>
              </div>
              <img src={RightIcon} width={16} />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default SelectCompanyModal;
