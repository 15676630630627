import { useState, useEffect } from "react";
import Styles from "./index.module.scss";
import "react-loading-skeleton/dist/skeleton.css";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import EnterpriseAi from "./components/EnterpriseAi";
import useCompanyInfoHooks from "@/hooks/useCompanyHooks";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { t } = useTranslation();
  const [category, setCategory] = useState<any>([
    {
      id: 1,
      name: t("common.ai.assistant"),
      icon: "icon-tool_fill",
    },
    {
      id: 2,
      name: t("common.ai.knowledge"),
      icon: "icon-a-myknowledge_fill",
    },
  ]);
  const { company_id } = useCompanyInfoHooks();
  const [categoryType, setCategoryType] = useState<number>(1);
  let navigate = useNavigate();

  useEffect(() => {
    if (company_id <= 0) {
      navigate(`/`);
    }
  }, []);

  return (
    <div className={Styles.page}>
      <div className={Styles.page_header}>
        <div className={classnames(Styles.category_index, "scroll-hide")}>
          {category.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={Styles.category_item}
                onClick={() => {
                  setCategoryType(item.id);
                }}
              >
                <div className={Styles.category_item_name_box}>
                  <div
                    className={classnames(
                      Styles.category_img,
                      categoryType === item.id ? Styles.category_img_active : "",
                      "iconfont",
                      "icon-size24",
                      item.icon
                    )}
                  ></div>
                  <div
                    className={classnames(
                      Styles.category_name,
                      categoryType === item.id
                        ? Styles.category_name_active
                        : ""
                    )}
                  >
                    {t(item.name)}
                  </div>
                </div>
                <div
                  className={classnames(
                    Styles.category_item_bottom_active,
                    categoryType === item.id ? Styles.category_item_bottom : ""
                  )}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
      <EnterpriseAi categoryType={categoryType} />
    </div>
  );
};

export default Home;
